export const environment = {
  production: false,
  apiUrl: 'https://api.brocadetech.com/shipperportal-tms-test',
  appApiUrl: 'https://api.brocadetech.com/shipperportal-app-test',
  docApiUrl: 'https://api.brocadetech.com/shipperportal-doc-test',
  apiTokenHeaderKey: 'brocade-customerportal-api-subscription-key',
  apiTokenHeaderValue: '71d2e7f167c94e05a05d9a6258ef781e',
  carrierId: 1,
  signinAuthority: 'https://shipperportalbrocadetest.b2clogin.com/shipperportalbrocadetest.onmicrosoft.com/B2C_1_signin',
  editProfileAuthority: 'https://shipperportalbrocadetest.b2clogin.com/shipperportalbrocadetest.onmicrosoft.com/B2C_1_profileedit',
  signupAuthority: 'https://shipperportalbrocadetest.b2clogin.com/shipperportalbrocadetest.onmicrosoft.com/B2C_1_signup',
  authRedirectUri: 'https://test.cowan.shipperportal.brocadetech.com',
  authClientId: '491e1b21-85b4-4d03-9a56-15c6d05b8378',
  authKnownAuthorities: 'shipperportalbrocadetest.b2clogin.com'
};
