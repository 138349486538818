import { createReducer, on } from '@ngrx/store';
import * as AccountProfileActions from './account-profile.actions';

export const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  previewUrl: null,
};

const _accountProfileReducer = createReducer(
  initialState,
  on(
    AccountProfileActions.saveAccountProfile,
    (state, { accountProfileData }) => ({
      ...state,
      ...accountProfileData,
    })
  ),
  on(AccountProfileActions.loadAccountProfile, (state) => state)
);

export function accountProfileReducer(state: any, action: any) {
  return _accountProfileReducer(state, action);
}
