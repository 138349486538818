<div mat-dialog-title class="modal-header">
  <h4 class="modal-title">Filters</h4>
  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<hr class="divider" />
<form [formGroup]="filtersForm" class="filters-form">
  <div class="status-container">
    <h5 class="title">Status</h5>
    <div class="checkbox-container">
      <div class="checkbox-column">
        <mat-checkbox formControlName="available">Available</mat-checkbox>
        <mat-checkbox formControlName="planned">Planned</mat-checkbox>
      </div>
      <div class="checkbox-column">
        <mat-checkbox formControlName="delivered">Delivered</mat-checkbox>
        <mat-checkbox formControlName="inTransit">In Transit</mat-checkbox>
      </div>
    </div>
  </div>

  <hr class="divider divider-with-space" />

  <div class="status-container">
    <h5 class="title">Pickup State</h5>
    <mat-form-field class="chip-list input-field" appearance="outline">
      <mat-chip-list #chipListPickup aria-label="Selected states">
        <mat-chip
          *ngFor="let state of pickupState"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(state, pickupState)"
        >
          {{ state }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          name="pickup-state-input"
          formControlName="pickupState"
          placeholder="Select State(s)..."
          appToUpperCase
          [matChipInputFor]="chipListPickup"
          [matAutocomplete]="autoPickup"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event, pickupState)"
        />
      </mat-chip-list>
      <mat-autocomplete
        #autoPickup="matAutocomplete"
        (optionSelected)="addFromAutocomplete($event, pickupState)"
      >
        <mat-option
          *ngFor="let state of filteredPickupStates | async"
          [value]="state"
        >
          {{ state }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <hr class="divider divider-with-space" />

  <div class="status-container">
    <h5 class="title">Consignee State</h5>
    <mat-form-field class="chip-list input-field" appearance="outline">
      <mat-chip-list #chipListConsignee aria-label="Selected states">
        <mat-chip
          *ngFor="let state of consigneeState"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(state, consigneeState)"
        >
          {{ state }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          formControlName="consigneeState"
          placeholder="Select State(s)..."
          appToUpperCase
          [matChipInputFor]="chipListConsignee"
          [matAutocomplete]="autoConsignee"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event, consigneeState)"
        />
      </mat-chip-list>
      <mat-autocomplete
        #autoConsignee="matAutocomplete"
        (optionSelected)="addFromAutocomplete($event, consigneeState)"
      >
        <mat-option
          *ngFor="let state of filteredConsigneeStates | async"
          [value]="state"
        >
          {{ state }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <hr class="divider divider-with-space" />

  <mat-dialog-actions class="action-buttons">
    <app-button
      mat-button
      (click)="onClearAll()"
      [isInverted]="true"
      [label]="'Clear All'"
      [icon]="'block'"
    ></app-button>
    <app-button
      mat-button
      (click)="onApply()"
      [label]="'Apply'"
      [icon]="'check_circle_outline'"
    ></app-button>
  </mat-dialog-actions>
</form>
