import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as BrandingActions from './branding.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class BrandingEffects {
  saveBrandingToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BrandingActions.saveBranding),
        tap((action) => {
          localStorage.setItem(
            'brandingData',
            JSON.stringify(action.brandingData)
          );
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
