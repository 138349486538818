<div class="documentsRectangle">
  <h2>Documents</h2>
  <div class="pdf-list" *ngIf="!isLoading">
    <button
      mat-button
      class="pdf-item"
      *ngFor="let document of documents"
      (click)="openDocument(document)"
    >
      <mat-icon class="pdf-symbol">description</mat-icon>
      {{ document.DocumentType }}
    </button>
  </div>
  <!-- No documents message -->
  <div *ngIf="documents.length === 0 && !isLoading" class="no-records-found">
    <p>No documents found.</p>
  </div>
  <!-- Spinner -->
  <div *ngIf="isLoading" class="spinner-overlay">
    <mat-progress-spinner
      mode="indeterminate"
      [diameter]="30"
      class="spinner"
    ></mat-progress-spinner>
  </div>
</div>
