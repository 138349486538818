import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '@env/environment';

export const b2cpolicies = {
  names: {
    signupsignin: "b2c_1_signup_signin",
    editprofile: "b2c_1_profile_edit",
    passwordreset: "b2c_1_password_reset"
  },
  authorities: {
    signin: {
      authority: environment.signinAuthority
    },
    editprofile: {
      authority: environment.editProfileAuthority
    },
    signup: {
      authority: environment.signupAuthority
    }
  }
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.authClientId,
    authority: b2cpolicies.authorities.signin.authority,
    knownAuthorities: [environment.authKnownAuthorities],
    redirectUri: environment.authRedirectUri,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};

export const loginRequest = {
  scopes: ['openid', 'profile']
};
