<mat-dialog-content class="modal-content">
  <button mat-icon-button class="close-button" (click)="closeModal()">
    <mat-icon>close</mat-icon>
  </button>
  <h2>Date Range</h2>
  <hr class="divider" /> <!-- Horizontal line -->
  <!-- Dropdown for Date Range Options -->
  <div class="custom-date-range-select">
    <mat-form-field>
      <mat-select [(ngModel)]="selectedDropdown" (ngModelChange)="onDropdownChange($event)">
        <mat-option value="Last 7 Days">Last 7 Days</mat-option>
        <mat-option value="Last 30 Days">Last 30 Days</mat-option>
        <mat-option value="Last 90 Days">Last 90 Days</mat-option>
        <mat-option value="Last 6 Months">Last 6 Months</mat-option>
        <mat-option value="Last 12 Months">Last 12 Months</mat-option>
        <mat-option value="Month to Date">Month to Date</mat-option>
        <mat-option value="Quarter to Date">Quarter to Date</mat-option>
        <mat-option value="Year to Date">Year to Date</mat-option>
        <mat-option value="All Dates">All Dates</mat-option>
        <mat-option value="Custom">Custom</mat-option>
      </mat-select>
      <mat-icon class="custom-dropdown-icon">keyboard_arrow_down</mat-icon>
    </mat-form-field>
  </div>
  <div class="date-range-text-boxes">
    <!-- Start Date -->
    <div class="input-container">
      <mat-form-field>
        <mat-icon matPrefix>calendar_month</mat-icon>
        <input matInput [matDatepicker]="startDatePicker"
                placeholder="mm/dd/yyyy"
                [class.hide-placeholder]="startDate || selectedDropdown !== 'custom'"
                (dateChange)="handleStartDateChange($event)"
                (focus)="startDatePicker.open()"
                [(ngModel)]="startDate">
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <!-- Separator -->
    <span class="custom-divider-for-dates">to</span>
    <!-- End Date -->
    <div class="input-container">
      <mat-form-field>
        <mat-icon matPrefix>calendar_month</mat-icon>
        <input matInput [matDatepicker]="endDatePicker"
                placeholder="mm/dd/yyyy"
                [class.hide-placeholder]="endDate || selectedDropdown !== 'custom'"
                (dateChange)="handleEndDateChange($event)"
                (focus)="endDatePicker.open()"
                [(ngModel)]="endDate">
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <hr class="divider" />
  <!-- Action Buttons -->
  <mat-dialog-actions>
    <button mat-button (click)="onClear()" mat-dialog-close style="margin-right: auto;" class="clear-button">
      <mat-icon>block</mat-icon>
      Clear All
    </button>
    <ng-container *ngTemplateOutlet="applyButton" class="applyButton"></ng-container>
    <ng-template #applyButton>
      <app-button mat-button
                  (click)="onApply()"
                  [label]="'Apply'"
                  [icon]="'check_circle_outline'"></app-button>
    </ng-template>
  </mat-dialog-actions>
