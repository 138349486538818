<div
  class="order-container"
  [ngClass]="{ expanded: isExpanded, collapsed: !isExpanded }"
>
  <div class="orderRectangle">
    <div class="headerContainer">
      <h1 class="page-title">Order# {{ orderNum }}</h1>
      <div class="buttonContainer">
        <button
          (click)="scrollToSummary()"
          class="summaryButton"
          [ngClass]="{ active: activeSection === 'summary' }"
        >
          <mat-icon>dehaze</mat-icon> Summary
        </button>
        <button
          (click)="scrollToStops()"
          class="stopsButton"
          [ngClass]="{ active: activeSection === 'stops' }"
        >
          <mat-icon> place</mat-icon> Stops
        </button>
        <button
          (click)="scrollToCheckcalls()"
          class="checkcallsButton"
          [ngClass]="{ active: activeSection === 'checkcall' }"
        >
          <mat-icon> local_shipping</mat-icon> Checkcalls
        </button>
        <button
          (click)="scrollToDocuments()"
          class="documentsButton"
          [ngClass]="{ active: activeSection === 'documents' }"
        >
          <mat-icon>folder_open</mat-icon> Documents
        </button>
      </div>
    </div>
  </div>
</div>
<div id="summary">
  <app-summary [orderNum]="orderNum" [isExpanded]="isExpanded"></app-summary>
</div>
<div id="stops">
  <app-stops [orderNum]="orderNum"></app-stops>
</div>
<div id="checkcall">
  <app-checkcall [orderNum]="orderNum"></app-checkcall>
</div>
<div id="documents">
  <app-documents [orderNum]="orderNum"></app-documents>
</div>
