import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserProfile(objectId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.appApiUrl}/GetUserProfile?objectId=${objectId}`
    );
  }

  getCarrierUsers(userDetails?: string): Observable<any> {
    let params = new HttpParams().set(
      'carrierId',
      environment.carrierId.toString()
    );

    if (userDetails) {
      params = params.set('userDetails', userDetails);
    }
    return this.http.get<any>(`${environment.appApiUrl}/GetCarrierUsers`, {
      params,
    });
  }

  upsertCarrierUser(request: any): Observable<any> {
    return this.http.post(
      `${environment.appApiUrl}/UpsertCarrierUser`,
      request
    );
  }

  deleteCarrierUser(objectId: string): Observable<any> {
    const url = `${environment.appApiUrl}/DeleteCarrierUser?objectId=${objectId}`;
    return this.http.delete(url);
  }

  createEditShipperUser(request: any): Observable<any> {
    return this.http.post(
      `${environment.appApiUrl}/UpsertShipperUser`,
      request
    );
  }
}
