import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

import { UserProfile } from '@app/models/user.model';
import { ToasterService } from '@app/services/toaster.service';
import { UserService } from '@app/services/user.service';
import { NewInternalUserModalComponent } from './new-internal-user-modal/new-internal-user-modal.component';
import { environment } from '@env/environment';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-internal-users',
  templateUrl: './internal-users.component.html',
  styleUrls: ['./internal-users.component.scss'],
})
export class InternalUsersComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  searchValue: string = '';
  columnsToDisplay: string[] = ['FirstName', 'Email'];
  columnsToDisplayWithAvatar: string[] = [
    'avatar',
    ...this.columnsToDisplay,
    'menu',
  ];
  isLoading: boolean = false;
  users: UserProfile[] = [];
  dataSource = new MatTableDataSource(this.users);

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private toasterService: ToasterService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.fetchInternalUsers();
  }

  fetchInternalUsers(userDetails?: string): void {
    this.isLoading = true;
    this.userService.getCarrierUsers(userDetails).subscribe({
      next: (res) => {
        this.users = res;
        this.dataSource.data = this.users;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      },
    });
  }

  handleSearchValueChange(value: string): void {
    this.searchValue = value;
  }

  announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  openUserModal(editMode: boolean = false, user?: UserProfile): void {
    const dialogRef = this.dialog.open(NewInternalUserModalComponent, {
      width: '660px',
      data: {
        adminUsers: true,
        editMode,
        ...user,
        carrierId: environment.carrierId,
      },
      autoFocus: '.company-name-input',
      id: 'new-user',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchInternalUsers();
    });
  }

  onDeleteUser(user?: any): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete the Internal User?',
      },
      id: 'confirm-dialog',
    });

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.userService.deleteCarrierUser(user.ObjectId).subscribe({
          next: () => {
            this.toasterService.showSuccess(
              'The Internal User was deleted successfully'
            );
            this.fetchInternalUsers();
          },
          error: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  onSubmit(): void {
    this.fetchInternalUsers(this.searchValue);
  }
}
