import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
})
export class SearchInputComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @Input() label!: string;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() searchOptions: string[] = [];
  @Input() selectedSearchOption: string = '';
  @Output() searchValue = new EventEmitter<string>();
  @Output() optionValue = new EventEmitter<string>();
  @Output() searchTriggered = new EventEmitter<void>();

  value: string = '';
  selectedOption!: string;

  @ViewChild('input') input!: ElementRef;

  onChange!: (value: string) => void;
  onTouched!: () => void;

  ngOnInit(): void {
    if (
      this.selectedSearchOption &&
      this.searchOptions.includes(this.selectedSearchOption)
    ) {
      this.selectedOption = this.selectedSearchOption;
    } else if (this.searchOptions.length) {
      this.selectedOption = this.searchOptions[0];
    }
    this.optionValue.emit(this.selectedOption);
  }

  ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'input')
      .pipe(debounceTime(700))
      .subscribe(() => {
        this.searchValue.emit(this.value);
        this.searchTriggered.emit();
      });
  }

  clearSearch(): void {
    this.value = '';
    this.onChange(this.value);
    this.searchValue.emit(this.value);
    this.searchTriggered.emit();
  }

  triggerSearch(): void {
    this.searchTriggered.emit();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  updateValue(value: string): void {
    this.value = value;
    this.onChange(value);
  }
}
