import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const targetUrl = environment.apiUrl;
    const targetAppUrl = environment.appApiUrl;
    const targetDocUrl = environment.docApiUrl;

    if (
      req.url.startsWith(targetUrl) ||
      req.url.startsWith(targetAppUrl) ||
      req.url.startsWith(targetDocUrl)
    ) {
      const clonedRequest = req.clone({
        headers: req.headers.set(
          environment.apiTokenHeaderKey,
          environment.apiTokenHeaderValue
        ),
      });
      return next.handle(clonedRequest);
    } else {
      return next.handle(req);
    }
  }
}
