import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit, OnDestroy {
  orderNum: number = 0;
  activeSection: string = 'summary';
  isHeaderFixed: boolean = false;
  isExpanded: boolean = true;

  private orderContainer!: HTMLElement;
  private destroy$ = new Subject<void>();
  private navigationSubscription!: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private store: Store<{ sidenavConfig: any }>
  ) {
    this.navigationSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe((event: any) => {
        if (!event.url.includes('/order/history')) {
          localStorage.removeItem('orderHistoryPage');
        }
      });
  }

  ngOnInit() {
    this.scrollToTop();
    this.activatedRoute.paramMap.subscribe((params) => {
      const orderNumberParam = params.get('orderNumber');
      this.orderNum = Number(orderNumberParam);
    });
    this.orderContainer =
      this.elementRef.nativeElement.querySelector('.order-container');
    this.store
      .select('sidenavConfig')
      .pipe(takeUntil(this.destroy$))
      .subscribe((sidenavConfig) => {
        this.isExpanded = sidenavConfig.isExpanded;
      });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const stickyClass = 'fixed';
    const noStickyClass = 'not-fixed';
    if (window.scrollY > 100) {
      this.orderContainer.classList.add(stickyClass);
      this.orderContainer.classList.remove(noStickyClass);
    } else {
      this.orderContainer.classList.remove(stickyClass);
      this.orderContainer.classList.add(noStickyClass);
    }
  }

  scrollToSummary() {
    this.activeSection = 'summary';
    const summaryElement = document.getElementById('summary');
    if (summaryElement) {
      summaryElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToStops() {
    this.activeSection = 'stops';
    const stopsElement = document.getElementById('stops');
    if (stopsElement) {
      stopsElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToCheckcalls() {
    this.activeSection = 'checkcall';
    const checkcallElement = document.getElementById('checkcall');
    if (checkcallElement) {
      checkcallElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToDocuments() {
    this.activeSection = 'documents';
    const documentsElement = document.getElementById('documents');
    if (documentsElement) {
      documentsElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
