import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { Store } from '@ngrx/store';

//material
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaterialModule } from './material/material.module';
import { MatMenuModule } from '@angular/material/menu';

// modules
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OrderComponent } from './components/order/order.component';
import { NgxColorsModule } from 'ngx-colors';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// services
import {
  ModeStorageService,
  MODE_STORAGE_SERVICE,
} from './services/mode-storage.service';
import { ModeToggleService } from './services/mode-toggle.service';
import { AuthService } from './services/auth.service';

// store
import * as BrandingActions from './components/branding/branding-store/branding.actions';
import { brandingReducer } from './components/branding/branding-store/branding.reducer';
import { BrandingEffects } from './components/branding/branding-store/branding.effects';
import * as AccountProfileActions from './components/navigation/header/account-profile-store/account-profile.actions';
import { accountProfileReducer } from './components/navigation/header/account-profile-store/account-profile.reducer';
import { AccountProfileEffects } from './components/navigation/header/account-profile-store/account-profile.effects';
import * as SidenavConfigActions from './components/navigation/sidenav-list/sidenav-store/sidenav.actions';
import { sidenavConfigReducer } from './components/navigation/sidenav-list/sidenav-store/sidenav.reducer';
import { SidenavConfigEffects } from './components/navigation/sidenav-list/sidenav-store/sidenav.effects';

// components
import { HomeComponent } from './components/home/home.component';
import { ShippersComponent } from './components/shippers/shippers.component';
import { UsersComponent } from './components/users/users.component';
import { BrandingComponent } from './components/branding/branding.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/navigation/header/header.component';
import { SidenavListComponent } from './components/navigation/sidenav-list/sidenav-list.component';
import { ReportsComponent } from './components/analytics/analytics.component';
import { CheckcallComponent } from './components/order/checkcall/checkcall.component';
import { StopsComponent } from './components/order/stops/stops.component';
import { DisplaySettingsDialogComponent } from './components/display-settings/display-settings-dialog/display-settings-dialog.component';
import { DocumentsComponent } from './components/order/documents/documents.component';
import { SummaryComponent } from './components/order/summary/summary.component';
import { ShipperUsersListComponent } from './components/shippers/shipper-users-list/shipper-users-list.component';
import { ButtonComponent } from './core/components/button/button.component';
import { SearchInputComponent } from './core/components/search-input/search-input.component';
import { NewShipperModalComponent } from './components/shippers/new-shipper-modal/new-shipper-modal.component';
import { ShipperDetailComponent } from './components/shippers/shipper-detail/shipper-detail.component';
import { NewUserModalComponent } from './components/shippers/new-user-modal/new-user-modal.component';
import { InternalUsersComponent } from './components/internal-users/internal-users.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { AdminSettingsComponent } from './components/admin-settings/admin-settings.component';
import { AccountProfileModalComponent } from './components/navigation/header/account-profile-modal/account-profile-modal.component';
import { AddHeadersInterceptor } from './interceptors/httpconfig.interceptor';
import { OrderHistoryFiltersModalComponent } from './components/order-history/order-history-filters-modal/order-history-filters-modal.component';
import { ConfirmDialogComponent } from './core/components/confirm-dialog/confirm-dialog.component';
import { NavigationDialogComponent } from './core/components/navigation-dialog/navigation-dialog.component';
import { OrderHistoryDaterangeModalComponent } from './components/order-history//order-history-daterange-modal/order-history-daterange-modal.component';


//directives
import { ToUpperCaseDirective } from './core/directives/to-upper-case.directive';

//msal
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MSAL_GUARD_CONFIG,
  MsalService,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_INSTANCE,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { msalConfig, loginRequest } from './auth-config';
import { NewInternalUserModalComponent } from './components/internal-users/new-internal-user-modal/new-internal-user-modal.component';
import { DeleteDialogComponent } from './core/components/delete-dialog/delete-dialog.component';

export function initializeApp(store: Store<any>) {
  return (): Promise<any> => {
    return new Promise((resolve) => {
      const storedBrandingData = localStorage.getItem('brandingData');
      if (storedBrandingData) {
        const brandingData = JSON.parse(storedBrandingData);
        store.dispatch(BrandingActions.saveBranding({ brandingData }));
      }

      const storedAccountProfileData =
        localStorage.getItem('accountProfileData');
      if (storedAccountProfileData) {
        const accountProfileData = JSON.parse(storedAccountProfileData);
        store.dispatch(
          AccountProfileActions.saveAccountProfile({ accountProfileData })
        );
      }

      const storedSidenavConfigData = localStorage.getItem('sidenavConfigData');
      if (storedSidenavConfigData) {
        const sidenavConfigData = JSON.parse(storedSidenavConfigData);
        store.dispatch(
          SidenavConfigActions.saveSidenavConfig({ sidenavConfigData })
        );
      }

      resolve(true);
    });
  };
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    OrderComponent,
    HomeComponent,
    ReportsComponent,
    ShippersComponent,
    UsersComponent,
    BrandingComponent,
    OrderHistoryComponent,
    HeaderComponent,
    SidenavListComponent,
    CheckcallComponent,
    StopsComponent,
    DisplaySettingsDialogComponent,
    DocumentsComponent,
    SummaryComponent,
    ShipperUsersListComponent,
    ButtonComponent,
    SearchInputComponent,
    NewShipperModalComponent,
    ShipperDetailComponent,
    NewUserModalComponent,
    InternalUsersComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AdminSettingsComponent,
    AccountProfileModalComponent,
    OrderHistoryFiltersModalComponent,
    ToUpperCaseDirective,
    ConfirmDialogComponent,
    NewInternalUserModalComponent,
    NavigationDialogComponent,
    OrderHistoryDaterangeModalComponent,
    DeleteDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxColorsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    MsalModule,
    MatMenuModule,
    StoreModule.forRoot({
      branding: brandingReducer,
      accountProfile: accountProfileReducer,
      sidenavConfig: sidenavConfigReducer,
    }),
    EffectsModule.forRoot([
      BrandingEffects,
      AccountProfileEffects,
      SidenavConfigEffects,
    ]),
  ],
  providers: [
    ModeToggleService,
    AuthService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000 },
    },
    {
      provide: MODE_STORAGE_SERVICE,
      useClass: ModeStorageService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeadersInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  entryComponents: [DisplaySettingsDialogComponent],
})
export class AppModule {}
