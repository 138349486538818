<div class="stopsRectangle">
  <h2>Stops</h2>
  <div class="stopsTableContainer">
    <table mat-table [dataSource]="stopsDataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="Company">
        <tr>
          <th mat-header-cell *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let element">{{ element.Company }}</td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="City/State">
        <tr>
          <th mat-header-cell *matHeaderCellDef>City/State</th>
          <td mat-cell *matCellDef="let element">
            {{ element.City }}, {{ element.State }}
          </td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="ZipCode">
        <tr>
          <th mat-header-cell *matHeaderCellDef>Zip</th>
          <td mat-cell *matCellDef="let element">{{ element.ZipCode }}</td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="Trailer">
        <tr>
          <th mat-header-cell *matHeaderCellDef>Trailer</th>
          <td mat-cell *matCellDef="let element">
            {{ getTrailerIds(element.Trailers) }}
          </td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="Trailer2">
        <tr>
          <th mat-header-cell *matHeaderCellDef>Trailer 2</th>
          <td mat-cell *matCellDef="let element">
            Trailer ID: {{ element.Trailers[1]?.TrailerId }}, Trailer Number:
            {{ element.Trailers[1]?.TrailerNumber }}
          </td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="Status">
        <tr>
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.StopStatus }}</td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="Arrival Date">
        <tr>
          <th mat-header-cell *matHeaderCellDef>Arrival Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.ArrivalDate ? convertToUSDateFormatWithTime12H(element.ArrivalDate) : '' }}
          </td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="Appointment">
        <tr>
          <th mat-header-cell *matHeaderCellDef>Appointment</th>
          <td mat-cell *matCellDef="let element">
            {{ convertToUSDateFormatWithTime12H(element.AppointmentEndDate) }}
          </td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="Departure Date">
        <tr>
          <th mat-header-cell *matHeaderCellDef>Departure Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.DepartureDate ? convertToUSDateFormatWithTime12H(element.DepartureDate) : '' }}
          </td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="Reason Late">
        <tr>
          <th mat-header-cell *matHeaderCellDef>Reason Late</th>
          <td mat-cell *matCellDef="let element">
            {{ element.ArrivalReasonLate }}
          </td>
        </tr>
      </ng-container>
      <tr mat-header-row
          *matHeaderRowDef="stopsDisplayedColumns"
          class="stopsHeaders"></tr>
      <tr mat-row *matRowDef="let row; columns: stopsDisplayedColumns"></tr>
    </table>
    <!-- No records message -->
    <div
      *ngIf="stopsDataSource.length === 0 && !isLoading"
      class="no-records-found"
    >
      <p>No records found.</p>
    </div>
    <!-- Spinner -->
    <div *ngIf="isLoading" class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
  </div>
</div>
