import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';

import { ModeToggle } from '@app/models/mode-toggle.model';
import { ModeToggleService } from '@app/services/mode-toggle.service';
import * as SidenavActions from '@app/components/navigation/sidenav-list/sidenav-store/sidenav.actions';
import { PermissionService } from '@app/services/permission.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'CustomerPortal';
  currentMode: ModeToggle = ModeToggle.LIGHT;
  isLoginPage = false;
  isExpanded = false;
  loginDisplay = false;
  isIframe = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private modeToggleService: ModeToggleService,
    private router: Router,
    private store: Store<{ sidenavConfig: any }>,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private permissionService: PermissionService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    this.modeToggleService.modeChanged$.subscribe((mode: ModeToggle) => {
      this.currentMode = mode;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginPage =
          event.urlAfterRedirects === '/login' ||
          event.urlAfterRedirects === '/forgot-password' ||
          event.urlAfterRedirects === '/reset-password';
      }
    });
  }

  ngOnInit(): void {
    this.setInitialData();
    this.isIframe = window !== window.parent && !window.opener;
    this.setSidenavConfig();
  }

  setInitialData(): void {
    this.permissionService.loadPermissions('');
    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.loginDisplay =
          this.msalService.instance.getAllAccounts().length > 0;
        if (!this.loginDisplay) {
          this.login();
        }
      });
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }

  public toggleMenu() {
    this.isExpanded = !this.isExpanded;
    this.changeToggleMenu();
  }

  changeToggleMenu(): void {
    const sidenavConfigData = {
      isExpanded: this.isExpanded,
    };
    this.store.dispatch(
      SidenavActions.saveSidenavConfig({ sidenavConfigData })
    );
  }

  setSidenavConfig(): void {
    this.store.select('sidenavConfig').subscribe((sidenavConfig) => {
      this.isExpanded = sidenavConfig.isExpanded;
    });
  }
}
