import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from '../../../services/toaster.service';
import { AppConfig } from '@app/app.config';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  hidePassword = true;
  hideConfirmPassword = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    const passwordPattern = AppConfig.passwordPattern;

    this.resetPasswordForm = this.fb.group(
      {
        password: [
          '',
          [Validators.required, Validators.pattern(passwordPattern)],
        ],
        confirmPassword: [
          '',
          [Validators.required, Validators.pattern(passwordPattern)],
        ],
      },
      { validators: this.passwordMatchValidator }
    );
  }

  passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if (password && confirmPassword) {
      let currentErrors = confirmPassword.errors || {};

      if (password.value !== confirmPassword.value) {
        currentErrors['passwordMismatch'] = true;
      } else {
        delete currentErrors['passwordMismatch'];
      }
      confirmPassword.setErrors(
        Object.keys(currentErrors).length ? currentErrors : null
      );
    }
    return null;
  }

  onSubmit(): void {
    if (this.resetPasswordForm.valid) {
      this.router.navigate(['/login']);
      this.toasterService.showSuccess('Success! You have reset your password');
    }
  }
}
