import { Component, OnInit, OnChanges, SimpleChanges, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ModeToggle } from '../../../models/mode-toggle.model';
import { ModeToggleService } from '../../../services/mode-toggle.service';

@Component({
  selector: 'app-display-settings-dialog',
  templateUrl: './display-settings-dialog.component.html',
  styleUrls: ['./display-settings-dialog.component.scss']
})
export class DisplaySettingsDialogComponent implements OnInit {
  currentMode: ModeToggle = ModeToggle.LIGHT;
  public selectedThemeVal: boolean = false;
  checked = false;
  initialMode!: ModeToggle;
  constructor(private dialogRef: MatDialogRef<DisplaySettingsDialogComponent>, private modeToggleService: ModeToggleService) {
    this.modeToggleService.modeChanged$.subscribe((mode: ModeToggle) => {
      this.currentMode = mode;
    });
    this.initialMode = this.currentMode
  }

  ngOnInit(): void {
    if (this.currentMode == 'dark') {
      this.checked = true;
    } 
  }

  /*test commeent*/

  close() {
    if (this.initialMode != this.currentMode) {
      this.modeToggleService.toggleMode();
    }
    this.dialogRef.close();
  }

  save(displaySettingsForm: NgForm) {
    console.log(displaySettingsForm.value);
    console.log(this.checked);
    console.log(this.currentMode);
    this.dialogRef.close();
  }

  toggle() {
    this.modeToggleService.toggleMode();
  }

  slideToggleChange(event: MatSlideToggleChange) {
    this.checked = event.source.checked;
  }

}
