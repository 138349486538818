export interface UserElement {
  id?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
  shipper?: string | null;
  lastLogin: string | null;
}

export interface UserProfile {
  Email?: string;
  email?: string;
  FirstName?: string;
  LastModifiedBy?: string;
  LastName?: string;
  ObjectId?: string;
  PrincipalName?: string;
  UserType?: string;
  ShipperId?: number;
}

export enum RoleType {
  USER = 'User',
  ADMIN = 'Admin',
}

export enum UserType {
  CARRIER_USER = 'carrier user',
  CARRIER_ADMIN = 'carrier admin',
  SHIPPER_USER = 'shipper user',
  SHIPPER_ADMIN = 'shipper admin',
}
