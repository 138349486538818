import { createAction, props } from '@ngrx/store';

export const saveAccountProfile = createAction(
  '[AccountProfile Component] Save',
  props<{ accountProfileData: any }>()
);

export const loadAccountProfile = createAction(
  '[AccountProfile Component] Load'
);
