<div class="table-overlay-container">
  <table
    mat-table
    matSort
    (matSortChange)="announceSortChange($event)"
    [dataSource]="dataSourceSubTable"
    multiTemplateDataRows
    class="users-table"
    matSortActive="UserType"
    matSortDirection="asc"
    [class.loading]="isLoading"
  >
    <!-- Users Columns -->
    <ng-container matColumnDef="FirstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.FirstName }} {{ element.LastName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element">
        {{ element.Email }}
      </td>
    </ng-container>
    <ng-container matColumnDef="UserType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
      <td mat-cell *matCellDef="let element">
        <div class="text-icon-align">
          <mat-icon
            class="role-icon"
            *ngIf="element.UserType === 'shipper admin'"
            >person</mat-icon
          >
          {{ element.UserType === 'shipper user' ? 'User' : 'Admin' }}
        </div>
      </td>
    </ng-container>

    <!-- Menu Column -->
    <ng-container matColumnDef="menuSubTable">
      <th
        mat-header-cell
        *matHeaderCellDef
        aria-label="row actions"
        class="menu-column"
      >
        &nbsp;
      </th>
      <td mat-cell *matCellDef="let element" class="menu-column">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="subtable-menu">
          <button
            mat-menu-item
            class="text-icon-align"
            (click)="openUserModal(true, element)"
          >
            <span class="material-symbols-outlined menu-icon">
              edit_square
            </span>
            Edit
          </button>
          <button
            mat-menu-item
            class="text-icon-align"
            (click)="changeRole(element)"
          >
            <mat-icon class="rotated-icon menu-icon">slideshow</mat-icon>
            Change to {{ getRoleUserToChange(element.UserType) }}
          </button>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            class="text-icon-align delete-button"
            (click)="onDeleteUser(element)"
          >
            <span class="material-symbols-outlined delete-button menu-icon">
              delete
            </span>
            Delete User
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplaySubTableWithMenu"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToDisplaySubTableWithMenu"
      (click)="onRowClick(shipper.Id)"
    ></tr>
  </table>
  <!-- No records message -->
  <div
    *ngIf="!isLoading && dataSourceSubTable.data.length === 0"
    class="no-records-found"
  >
    <p>No records found.</p>
  </div>
  <!-- Spinner -->
  <div *ngIf="isLoading" class="spinner-overlay">
    <mat-progress-spinner
      mode="indeterminate"
      [diameter]="30"
      class="spinner"
    ></mat-progress-spinner>
  </div>
</div>
