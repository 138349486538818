<div class="checkCallRectangle">
  <h2>Checkcall History</h2>
  <table mat-table [dataSource]="checkcallDataSource" class="mat-elevation-z0">
    <ng-container matColumnDef="Last Update">
      <tr>
        <th mat-header-cell *matHeaderCellDef>Last Update</th>
        <td mat-cell *matCellDef="let element">
          {{ convertToUSDateFormatWithTime12H(element.LastUpdate) }}
        </td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="Location">
      <tr>
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let element">{{ element.Location }}</td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="Comment">
      <tr>
        <th mat-header-cell *matHeaderCellDef>Comment</th>
        <td mat-cell *matCellDef="let element">{{ element.Comment }}</td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="Event">
      <tr>
        <th mat-header-cell *matHeaderCellDef>Event</th>
        <td mat-cell *matCellDef="let element">{{ element.EventCode }}</td>
      </tr>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="checkcallDisplayedColumns"
      class="checkcallHeaders"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: checkcallDisplayedColumns"></tr>
  </table>
  <!-- No records message -->
  <div
    *ngIf="checkcallDataSource.length === 0 && !isLoading"
    class="no-records-found"
  >
    <p>No records found.</p>
  </div>
  <!-- Spinner -->
  <div *ngIf="isLoading" class="spinner-container">
    <mat-progress-spinner
      mode="indeterminate"
      [diameter]="30"
      class="spinner"
    ></mat-progress-spinner>
  </div>
</div>
