<mat-toolbar [ngStyle]="{ 'background-color': headerColor }">
  <div>
    <ul class="carrier-branding">
      <li>
        <a class="carrier-name" routerLink="/home">
          <div class="logo-and-text">
            <img [src]="logoUrl" alt="Logo" class="logo" *ngIf="logoUrl" />
            {{ carrierName }}
          </div>
        </a>
      </li>
    </ul>
  </div>
  <div class="navigation-items-container" *ngIf="showHeaderElements">
    <div class="navigation-item">
      <mat-icon class="aligned-icon">personal_video</mat-icon
      ><button
        class="header-link aligned-text"
        (click)="openDialog()"
        [ngStyle]="{ 'background-color': headerColor }"
      >
        Display Settings
      </button>
    </div>
    <div class="navigation-item" *ngIf="canWriteShipperOrganization()">
      <mat-form-field
        class="view-as-select"
        [ngStyle]="{ 'background-color': rgaHeaderColor }"
      >
        <mat-label>View as...</mat-label>
        <mat-icon matSuffix class="mat-select-icon">expand_more</mat-icon>
        <mat-select
          [(ngModel)]="companyId"
          name="companyId"
          (ngModelChange)="onShipperChange()"
        >
          <mat-option
            *ngFor="let shipper of shippers"
            [value]="shipper.BillTos"
          >
            {{ shipper.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="navigation-item">
      <div
        [matMenuTriggerFor]="menu"
        class="circle"
        [ngStyle]="{ 'background-color': circleColor }"
      >
        <img class="circle-image" *ngIf="photoUrl" [src]="photoUrl" />
        <div *ngIf="showInitials && !photoUrl" class="initials">
          {{ initials }}
        </div>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openAccountProfileModal()">
          Profile
        </button>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
