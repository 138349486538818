<!-- Spinner -->
<div *ngIf="!loginDisplay" class="spinner-overlay">
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="30"
    class="spinner"
  ></mat-progress-spinner>
</div>

<div *ngIf="loginDisplay">
  <div class="container">
    <div class="header">
      <app-header [showHeaderElements]="!isLoginPage"></app-header>
    </div>
  </div>
  <mat-drawer-container class="sidenav-container" autosize *ngIf="!isLoginPage">
    <mat-drawer
      style="visibility: visible !important"
      class="side-nav"
      mode="side"
      opened="{{ isExpanded }}"
    >
      <app-sidenav-list
        (toggleMenu)="toggleMenu()"
        [isExpanded]="isExpanded"
      ></app-sidenav-list
    ></mat-drawer>
    <mat-drawer-content class="sidenav-content"
      ><router-outlet></router-outlet
    ></mat-drawer-content>
  </mat-drawer-container>
  <router-outlet *ngIf="isLoginPage"></router-outlet>
</div>
