import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private http: HttpClient) {}

  downloadImage(id: string, type: string): Observable<Blob> {
    return this.http.get(
      `${environment.appApiUrl}/ImageDownload?imageType=${type}&id=${id}`,
      {
        responseType: 'blob',
      }
    );
  }

  //prettier-ignore
  uploadImage(file: File, fileUrl: string | ArrayBuffer | SafeUrl | null, id: string, imageType: string): Observable<any> {
    const formData = new FormData();
    const fileBlob = this.base64ToBlob(fileUrl, file.type);
    formData.append('File', fileBlob, file.name);
    formData.append('Id', id);
    formData.append('ImageType', imageType);
    return this.http.post(`${environment.appApiUrl}/ImageUpload`, formData, {
      responseType: 'text',
    });
  }

  base64ToBlob(base64: any, mimeType: any) {
    const bytes = atob(base64.split(',')[1]);
    let m = new Uint8Array(bytes.length);
    for (let i = 0; i < bytes.length; i++) {
      m[i] = bytes.charCodeAt(i);
    }
    return new Blob([m], { type: mimeType });
  }

  deleteImage(id: string, imageType: string): Observable<any> {
    return this.http.delete(
      `${environment.appApiUrl}/ImageDelete?ImageType=${imageType}&Id=${id}`,
      {
        responseType: 'text',
      }
    );
  }
}
