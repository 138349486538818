import { Component, EventEmitter, Inject, Output, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateRange } from '@app/models/date-range.model'
import { calculateDateRange } from '../../../shared/helpers/date.helper';
import { getDateSixMonthsAgo } from '../../../shared/helpers/date.helper';

@Component({
  selector: 'app-order-history-daterange-modal',
  templateUrl: './order-history-daterange-modal.component.html',
  styleUrls: ['./order-history-daterange-modal.component.scss']
})
export class OrderHistoryDaterangeModalComponent {
  startDate: Date | null = null;
  endDate: Date | null = null;
  selectedDropdown: string = 'all';
  defaultStartDate = getDateSixMonthsAgo();
  defaultEndDate = new Date().toString();
  sortColumn: string | null = null
  sortDirection: string | null = null

  @Output() applyDateRange = new EventEmitter<{ startDate: string | null, endDate: string | null }>();
  @Output() selectedRangeChange = new EventEmitter<string>();
  @Output() clearFilters = new EventEmitter<void>();

  constructor(
    private dialogRef: MatDialogRef<OrderHistoryDaterangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.selectedDropdown = data.selectedDropdown;
  }

  ngOnInit() {
    const filterStateString = sessionStorage.getItem('orderHistoryDateFilters');
    if (filterStateString) {
      const sessionFilters = JSON.parse(filterStateString);
      this.startDate = sessionFilters.selectedStartDate ? new Date(sessionFilters.selectedStartDate) : null;
      this.endDate = sessionFilters.selectedEndDate ? new Date(sessionFilters.selectedEndDate) : null;
      this.selectedDropdown = sessionFilters.dropDownValue;
    } else {
      this.startDate = getDateSixMonthsAgo();
      this.endDate = new Date();
      this.selectedDropdown = "Last 6 Months";
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  handleStartDateChange(event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    if (this.startDate) {
      this.selectedDropdown = 'Custom';
    }
  }

  handleEndDateChange(event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    if (this.endDate) {
      this.selectedDropdown = 'Custom';
    }
  }

  onDropdownChange(dropDownValue: string) {
    this.selectedDropdown = dropDownValue;
    const dateRange: DateRange = calculateDateRange(this.selectedDropdown);

    this.startDate = dateRange.startDate ? new Date(dateRange.startDate) : null;
    this.endDate = this.selectedDropdown === 'All Dates' ? null : (dateRange.endDate ? new Date(dateRange.endDate) : null);
  }

  onApply() {
    const startDateString: string | null = this.startDate ? this.startDate.toISOString() : null;
    const endDateString: string | null = this.endDate ? this.endDate.toISOString() : null;
    const selectedDropdown = this.selectedDropdown;

    this.dialogRef.close({
      startDate: startDateString,
      endDate: endDateString,
      selectedDropdown: selectedDropdown,
    });
  }

  onClear() {
    this.startDate = null;
    this.endDate = null;
    this.selectedDropdown = 'Last 6 Months';
    
    this.dialogRef.close({
      startDate: new Date (this.defaultStartDate),
      endDate: new Date (this.defaultEndDate),
      selectedDropdown: 'Last 6 Months',
    });
  }
}
