import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label!: string;
  @Input() icon!: string;
  @Input() isIcon: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() isDelete: boolean = false;
  @Input() isInverted: boolean = false;
  @Input() buttonType: 'button' | 'stroked-button' = 'button';
  @Input() type: string = 'text';
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  onClick(): void {
    this.buttonClick.emit();
  }
}
