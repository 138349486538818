<div mat-dialog-title class="modal-header">
  <h4 class="title">Edit User</h4>
  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<hr class="divider" />

<div class="file-upload-container">
  <div class="preview-and-delete-container">
    <!-- Image Preview -->
    <div class="preview-container">
      <img
        *ngIf="previewUrl"
        [src]="previewUrl"
        alt="Image preview"
        class="image-preview"
      />
      <div *ngIf="!previewUrl && userInitials" class="initials-preview">
        {{ userInitials }}
      </div>
      <img
        *ngIf="!previewUrl && !userInitials"
        src="assets/images/avatar.png"
        class="avatar"
        alt="Avatar"
      />
    </div>
    <!-- Delete Button -->
    <button
      *ngIf="previewUrl"
      mat-icon-button
      class="text-icon-align delete-button"
      (click)="onDeleteImage()"
    >
      <span class="material-symbols-outlined delete-button menu-icon">
        delete
      </span>
      <span class="delete-text">Delete Image</span>
    </button>
  </div>

  <!-- Drag & Drop -->
  <div
    class="drag-drop-container"
    (click)="fileInput.click()"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
  >
    <div class="icon-text-container">
      <input
        type="file"
        #fileInput
        (change)="onFileSelected($event)"
        style="display: none"
        accept="image/*"
      />
      <mat-icon class="cloud-upload-icon">cloud_upload</mat-icon>
      <div class="drag-drop-text-container">
        <p class="drag-drop-main-text">Drag & Drop Image</p>
        <small class="drag-drop-secondary-text">or Select a File...</small>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="userForm" class="user-form">
  <mat-dialog-content class="form-container">
    <div class="input-row">
      <!-- First Name -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" type="text" />
        <mat-error *ngIf="userForm.get('firstName')?.hasError('required')"
          >The First Name is required</mat-error
        >
      </mat-form-field>
      <!-- Last Name -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" type="text" />
        <mat-error *ngIf="userForm.get('lastName')?.hasError('required')"
          >The Last Name is required</mat-error
        >
      </mat-form-field>
    </div>
    <div class="input-row">
      <!-- Email -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="text" />
        <mat-error *ngIf="userForm.get('email')?.hasError('required')"
          >The Email is required</mat-error
        >
        <mat-error
          *ngIf="
            userForm.get('email')?.hasError('invalidEmail') &&
            !userForm.get('email')?.hasError('required') &&
            userForm.get('email')?.touched
          "
        >
          Please enter a valid email
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="action-buttons">
    <app-button
      mat-button
      (click)="onSave()"
      [label]="'Save Changes'"
      [icon]="'check_circle_outline'"
      [isDisabled]="isOnSaveDisabled()"
    ></app-button>
  </mat-dialog-actions>
</form>
