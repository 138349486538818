import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, catchError, from, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private msalService: MsalService) {}

  getB2cObjectId(): Observable<string | null> {
    return from(this.waitForAccountData()).pipe(
      switchMap(() => {
        const accounts = this.msalService.instance.getAllAccounts();
        if (accounts.length === 0) {
          console.error('No accounts are signed in.');
          return of(null);
        }

        const activeAccount =
          this.msalService.instance.getActiveAccount() || accounts[0];
        this.msalService.instance.setActiveAccount(activeAccount);

        if (activeAccount.idTokenClaims?.oid) {
          const msalAccountKeys: any = JSON.parse(
            localStorage.getItem('msal.account.keys') || '[]'
          );
          const msalAccount = JSON.parse(
            localStorage.getItem(msalAccountKeys[0]) || '{}'
          );
          localStorage.setItem('objectId', msalAccount.idTokenClaims.oid);
          const oid = msalAccount.idTokenClaims.oid;
          return of(oid);
        } else {
          console.error('No Object ID found in token claims.');
          return of(null);
        }
      }),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  async waitForAccountData(retryCount = 0): Promise<void> {
    const maxRetries = 10;
    const delay = 1000;

    return new Promise((resolve, reject) => {
      const checkAccounts = () => {
        if (
          this.msalService.instance.getAllAccounts().length > 0 ||
          retryCount >= maxRetries
        ) {
          resolve();
        } else {
          setTimeout(() => {
            this.waitForAccountData(retryCount + 1)
              .then(resolve)
              .catch(reject);
          }, delay);
        }
      };

      checkAccounts();
    });
  }
}
