<div mat-dialog-title class="modal-header">
  <h4 class="title">{{ editMode ? 'Edit' : 'New' }} Shipper</h4>
  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<hr class="divider" />

<form [formGroup]="shipperForm" class="shipper-form">
  <mat-dialog-content class="form-container">
    <!-- Company Name -->
    <mat-form-field class="input-field" appearance="outline">
      <mat-label>Company Name</mat-label>
      <input
        class="company-name-input"
        matInput
        formControlName="companyName"
      />
      <mat-error *ngIf="shipperForm.get('companyName')?.hasError('required')"
        >The Company Name is required</mat-error
      >
    </mat-form-field>
    <!-- Bill-to Code -->
    <mat-form-field class="input-field" appearance="outline">
      <mat-label>Bill-to Code</mat-label>
      <!--<input matInput formControlName="billToCodes" (input)="toUpper($event)" />-->
      <mat-chip-list #chipList aria-label="Bill-to Codes">
        <mat-chip
          *ngFor="let billToCode of billToCodes"
          [removable]="true"
          (removed)="remove(billToCode)"
        >
          {{ billToCode }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          formControlName="billToCodes"
          (matChipInputTokenEnd)="add($event)"
          (input)="toUpper($event)"
        />
      </mat-chip-list>
      <mat-error *ngIf="shipperForm.get('billToCodes')?.hasError('required')"
        >The Bill-to Code is required</mat-error
      >
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="action-buttons">
    <app-button
      mat-button
      (click)="onDelete()"
      [label]="'Delete Shipper'"
      [icon]="'delete'"
      [isIcon]="false"
      [isDelete]="true"
      [buttonType]="'stroked-button'"
      [ngStyle]="{ visibility: editMode ? 'visible' : 'hidden' }"
    ></app-button>
    <app-button
      mat-button
      (click)="onSave()"
      [label]="'Save Changes'"
      [icon]="'check_circle_outline'"
      [isDisabled]="isOnSaveDisabled()"
    ></app-button>
  </mat-dialog-actions>
</form>
