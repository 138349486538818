import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { NewUserModalComponent } from '@app/components/shippers/new-user-modal/new-user-modal.component';
import { UserProfile } from '@app/models/user.model';
import { Actions } from '@app/models/actions.model';
import { ToasterService } from '@app/services/toaster.service';
import { SharedService } from '@app/services/shared.service';
import { ShipperService } from '@app/services/shipper.service';
import { ShipperElement } from '@app/models/shipper.model';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit {
  searchValue: string = '';
  columnsToDisplay: string[] = ['FirstName', 'Email', 'UserType'];
  columnsToDisplayWithAvatar: string[] = [
    'avatar',
    ...this.columnsToDisplay,
    'menu',
  ];
  subscription!: Subscription;
  shipper!: ShipperElement;
  users: UserProfile[] = [];
  dataSource = new MatTableDataSource(this.users);
  isLoading: boolean = false;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private dialog: MatDialog,
    private toasterService: ToasterService,
    private _liveAnnouncer: LiveAnnouncer,
    private sharedService: SharedService,
    private shipperService: ShipperService
  ) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.subscribeShipperId();
  }

  subscribeShipperId(): void {
    this.subscription = this.sharedService.shipperId$.subscribe((shipper) => {
      if (shipper) {
        this.shipper = shipper;
        this.fetchShipperUsers();
      }
    });
  }

  fetchShipperUsers(userDetails?: string): void {
    this.isLoading = true;
    this.shipperService
      .getShipperUsers(this.shipper.Id, userDetails)
      .subscribe({
        next: (res) => {
          this.users = res;
          this.dataSource.data = this.users;
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
        },
      });
  }

  handleSearchValueChange(value: string): void {
    this.searchValue = value;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openUserModal(editMode: boolean = false, user?: UserProfile): void {
    const dialogRef = this.dialog.open(NewUserModalComponent, {
      width: '660px',
      data: {
        editMode,
        ...user,
        shipper: this.shipper,
      },
      autoFocus: '.company-name-input',
      id: 'new-user',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchShipperUsers();
    });
  }

  onDeleteUser(user?: any): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete the User?',
      },
      id: 'confirm-dialog',
    });

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed && this.shipper.Id) {
        this.shipperService
          .deleteShipperUser(this.shipper.Id, user.ObjectId)
          .subscribe({
            next: () => {
              this.toasterService.showSuccess(
                'The User was deleted successfully'
              );
              this.fetchShipperUsers();
            },
            error: (error) => {
              console.log(error);
            },
          });
      }
    });
  }

  onSubmit(): void {
    this.fetchShipperUsers(this.searchValue);
  }

  getRoleUserToChange(role: string): string {
    if (role === 'shipper user') {
      return 'Admin';
    }
    return 'User';
  }

  announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  changeRole(user: any): void {
    const request: any = {
      FirstName: user.FirstName,
      LastName: user.LastName,
      Email: user.Email,
      UserType:
        user.UserType === 'shipper user' ? 'shipper admin' : 'shipper user',
      ShipperId: this.shipper.Id,
      ObjectId: user.ObjectId,
      PrincipalName: user.PrincipalName,
    };
    this.shipperService.upsertShipperUser(request).subscribe({
      next: () => {
        this.toasterService.showSuccess('The Role was changed successfully');
        this.fetchShipperUsers();
      },
      error: (error) => {
        console.log(error.error);
      },
    });
  }
}
