<div mat-dialog-title class="modal-header">
  <h4 class="title">Display Settings</h4>
  <button mat-icon-button class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<hr class="divider" />
<mat-dialog-content>
  <form class="display-settings" #displaySettingsForm="ngForm">
    <p>Theme</p>
    <span class="switcher_label_left">Light Mode</span>
    <mat-slide-toggle
      [(ngModel)]="checked"
      (change)="slideToggleChange($event)"
      name="theme"
      (click)="toggle()"
    >
    </mat-slide-toggle>
    <span class="switcher_label_right">Dark Mode</span>
    <!--<hr class="content-hr" />
    <p>Content Density</p>
    <span class="switcher_label_left">Regular View</span>
    <mat-slide-toggle> </mat-slide-toggle>
    <span class="switcher_label_right">Comfy View</span>-->
    <hr class="content-hr" />
  </form>
</mat-dialog-content>
<mat-dialog-actions class="action-buttons">
  <app-button
    mat-button
    (click)="save(displaySettingsForm)"
    [label]="'Update Settings'"
    [icon]="'check_circle_outline'"
  ></app-button>
</mat-dialog-actions>
