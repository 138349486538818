<div mat-dialog-title class="modal-header">
  <h4 class="title">Unsaved Changes</h4>
</div>

<hr class="divider" />

<mat-dialog-content>
  <p class="message">{{ message }}</p>
</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
  <button mat-stroked-button (click)="onCancel()">
    {{ cancelButtonText }}
  </button>
  <button mat-stroked-button (click)="onConfirm()">
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
