<div mat-dialog-title class="modal-header">
  <h4 class="title">{{ editMode ? 'Edit' : 'New' }} User</h4>
  <button mat-icon-button class="close-button" (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<hr class="divider" />

<div *ngIf="editMode" class="info-container">
  <div class="info-container">
    <div class="avatar-container" (click)="fileInput.click()">
      <!-- Default Avatar -->
      <img
        *ngIf="!previewUrl"
        src="assets/images/avatar.png"
        class="avatar"
        alt="Avatar"
      />
      <div class="avatar-overlay">
        <mat-icon class="upload-icon">file_upload</mat-icon>
      </div>
      <input hidden #fileInput type="file" (change)="onFileSelected($event)" />
      <!-- Selected Avatar -->
      <img
        *ngIf="previewUrl"
        [src]="previewUrl"
        alt="Preview"
        class="avatar preview-image"
      />
    </div>
  </div>
  <div class="text-container">
    <h3 class="name-info">{{ firstName }} {{ lastName }}</h3>
    <!--<span class="last-login-info">Last login: {{ lastLogin }}</span>-->
  </div>
</div>

<form [formGroup]="userForm" class="user-form">
  <mat-dialog-content class="form-container">
    <div class="input-row">
      <!-- First Name -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" type="text" />
        <mat-error *ngIf="userForm.get('firstName')?.hasError('required')">
          The First Name is required
        </mat-error>
        <mat-error *ngIf="userForm.get('firstName')?.hasError('whitespace')">
          The First Name cannot contain white space
        </mat-error>
        <mat-error *ngIf="userForm.get('firstName')?.hasError('specialChars')">
          The First Name cannot contain special characters
        </mat-error>
      </mat-form-field>
      <!-- Last Name -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" type="text" />
        <mat-error *ngIf="userForm.get('lastName')?.hasError('required')">
          The Last Name is required
        </mat-error>
        <mat-error *ngIf="userForm.get('lastName')?.hasError('whitespace')">
          The Last Name cannot cannot contain white space
        </mat-error>
        <mat-error *ngIf="userForm.get('lastName')?.hasError('specialChars')">
          The Last Name cannot contain special characters
        </mat-error>
      </mat-form-field>
    </div>

    <div class="input-row">
      <!-- Email -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" />
        <mat-error *ngIf="userForm.get('email')?.hasError('required')"
          >The Email is required</mat-error
        >
        <mat-error *ngIf="userForm.get('email')?.hasError('email')"
          >The Email has to be valid</mat-error
        >
      </mat-form-field>
      <!-- Password -->
      <!--<mat-form-field class="input-field" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password" />
        <mat-error *ngIf="userForm.get('password')?.hasError('required')"
          >The Password is required</mat-error
        >
      </mat-form-field>-->
      <!-- Role -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Role</mat-label>
        <mat-select formControlName="role" [disabled]="true">
          <mat-option *ngFor="let role of roles" [value]="role.value">
            {{ role.value }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="userForm.get('role')?.hasError('required')"
          >The Role is required</mat-error
        >
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="action-buttons">
    <app-button
      mat-button
      (click)="onDelete()"
      [label]="'Delete User'"
      [icon]="'delete'"
      [isIcon]="false"
      [isDelete]="true"
      [buttonType]="'stroked-button'"
      [ngStyle]="{ visibility: editMode ? 'visible' : 'hidden' }"
      [isDisabled]="isDisabled"
    ></app-button>
    <app-button
      mat-button
      (click)="onSave()"
      [label]="'Save Changes'"
      [icon]="'check_circle_outline'"
      [isDisabled]="isOnSaveDisabled()"
    ></app-button>
  </mat-dialog-actions>
</form>
