import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private companyIdSubject = new BehaviorSubject<string>('');
  private companyId$: Observable<string> = this.companyIdSubject.asObservable();

  private shipperIdSubject = new BehaviorSubject<any>(null);
  shipperId$ = this.shipperIdSubject.asObservable();

  setShipperId(shipperId: any) {
    this.shipperIdSubject.next(shipperId);
  }

  setCompanyId(companyId: string) {
    this.companyIdSubject.next(companyId);
  }

  getCompanyId(): string {
    return this.companyIdSubject.value;
  }

  initializeCompanyId(companyId: string) {
    this.companyIdSubject.next(companyId);
  }

  companyIdObservable(): Observable<string> {
    return this.companyId$;
  }
}
