<div class="shipper-header">
  <div class="title-container">
    <span class="material-icons title-chevron" (click)="goToShippersList()">
      chevron_left
    </span>
    <span class="title">Shippers</span>
  </div>
  <div class="search-container">
    <app-search-input
      label="Search Users..."
      [(ngModel)]="searchValue"
      name="searchValue"
      (searchTriggered)="onSubmit()"
      (searchValue)="handleSearchValueChange($event)"
    ></app-search-input>
  </div>
</div>

<div class="table-container">
  <div class="table-title-container">
    <span *ngIf="!isLoadingShipper" class="table-title">{{
      shipper?.Name
    }}</span>
    <!-- Spinner -->
    <div *ngIf="isLoadingShipper" class="shipper-spinner">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
    <!-- Edit Button -->
    <app-button
      class="action-button"
      [label]="'Edit'"
      [isIcon]="false"
      [icon]="'edit_square'"
      (buttonClick)="openShipperModal(true)"
    >
    </app-button>
    <!-- New User Button -->
    <app-button
      class="action-button"
      [label]="'New User'"
      [icon]="'person'"
      (buttonClick)="openUserModal()"
    >
    </app-button>
  </div>
  <div class="table-overlay-container">
    <table
      mat-table
      [dataSource]="users"
      matSort
      (matSortChange)="announceSortChange($event)"
      multiTemplateDataRows
      class="users-table"
      matSortActive="role"
      matSortDirection="asc"
    >
      <!-- Shippers Columns -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.FirstName }} {{ element.LastName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</th>
        <td mat-cell *matCellDef="let element">{{ element.Email }}</td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="role">Role</th>
        <td mat-cell *matCellDef="let element">
          <div class="text-icon-align">
            <mat-icon
              class="role-icon"
              *ngIf="element.UserType === 'shipper admin'"
              >person</mat-icon
            >
            {{ element.UserType === 'shipper user' ? 'User' : 'Admin' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="lastLogin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastLogin">
          Last Login
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastLogin | date : 'short' }}
        </td>
      </ng-container>

      <!-- Avatar Column -->
      <ng-container matColumnDef="avatar">
        <th
          mat-header-cell
          *matHeaderCellDef
          aria-label="row actions"
          class="menu-column avatar-column"
        >
          &nbsp;
        </th>
        <td mat-cell *matCellDef="let element" class="menu-column">
          <img src="assets/images/avatar.png" class="avatar" alt="Avatar" />
        </td>
      </ng-container>

      <!-- Menu Column -->
      <ng-container matColumnDef="menu">
        <th
          mat-header-cell
          *matHeaderCellDef
          aria-label="row actions"
          class="menu-column"
        >
          &nbsp;
        </th>
        <td mat-cell *matCellDef="let element" class="menu-column">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="table-menu">
            <button
              mat-menu-item
              class="text-icon-align"
              (click)="openUserModal(true, element)"
            >
              <span class="material-symbols-outlined menu-icon">
                edit_square
              </span>
              Edit
            </button>
            <button
              mat-menu-item
              class="text-icon-align"
              (click)="changeRole(element)"
            >
              <span class="material-icons menu-icon"> person </span>
              Change to {{ getRoleUserToChange(element.UserType) }}
            </button>
            <mat-divider></mat-divider>
            <button
              mat-menu-item
              class="text-icon-align delete-button"
              (click)="onDeleteUser(element)"
            >
              <span class="material-symbols-outlined delete-button menu-icon">
                delete
              </span>
              Delete User
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithAvatar"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplayWithAvatar"
        class="shipper-element-row"
        (click)="openUserModal(true, row)"
      ></tr>
    </table>
    <!-- No records message -->
    <div *ngIf="!isLoading && users.length === 0" class="no-records-found">
      <p>No records found.</p>
    </div>
    <!-- Spinner -->
    <div *ngIf="isLoading" class="spinner-overlay">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
  </div>
</div>
