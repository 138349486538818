<section [class.sidenav]="isExpanded">
  <div class="toggle">
    <span class="material-symbols-outlined" (click)="toggleMenu.emit(null)">
      dehaze
    </span>
  </div>
  <mat-nav-list>
      <mat-list-item *ngFor="let item of settingsMenu">
        <a mat-list-item
           *ngIf="shouldShowMenuItem(item.name)"
           [routerLink]="item.path"
           (click)="collapseSettings()"
           (mouseenter)="showFloatingMenu($event, item.icon, item.name)">
          <mat-icon [class.icon-not-selected]="!isSelected(item.path)">{{ item.icon }}</mat-icon>
          <h4 *ngIf="isExpanded">{{ item.name }}</h4>
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="!isExpanded && canWritePortalSettings()">
        <a mat-list-item
           (click)="onSettingsHeaderClick()"
           (mouseenter)="showFloatingMenu($event, 'settings', 'Settings', settingsSubMenu)">
          <mat-icon [class.icon-not-selected]="!isSelected('/settings')">settings</mat-icon>
        </a>
      </mat-list-item>
      <mat-expansion-panel *ngIf="isExpanded && canWritePortalSettings()" [expanded]="isSettingsExpanded">
        <mat-expansion-panel-header (click)="onSettingsHeaderClick()">
          <mat-icon class="toggle-menu-icon"
                    (click)="onSettingsClick($event)"
                    [class.icon-not-selected]="!isSelected('/settings', true)">settings</mat-icon>
          <h4 *ngIf="isExpanded">Settings</h4>
        </mat-expansion-panel-header>
        <mat-nav-list class="toggle-menu-items" *ngIf="isExpanded">
          <a mat-list-item
             *ngFor="let subItem of settingsSubMenu"
             [routerLink]="subItem.path"
             [class.selected]="isSelected(subItem.path, true)">
            <div class="rectangle"></div>
            <div class="toggle-menu-item-text">{{ subItem.name }}</div>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-nav-list>
</section>

<div #floatingMenu
     class="floating-menu-element"
     (mouseleave)="hideFloatingMenu()"
     (click)="navigateToCurrentPath()">
  <div class="icon-text-container">
    <mat-icon [class.icon-not-selected]="!isSelected(currentPath)">
      {{
      iconName
      }}
    </mat-icon>
    <div #menuItemText class="text-container"></div>
  </div>
  <ul #subMenuList class="floating-submenu-element"></ul>
</div>
