import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as AccountProfileActions from './account-profile.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class AccountProfileEffects {
  saveAccountProfileToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountProfileActions.saveAccountProfile),
        tap((action) => {
          const accountProfileData = { ...action.accountProfileData };

          if (
            typeof accountProfileData.previewUrl === 'object' &&
            accountProfileData.previewUrl
          ) {
            accountProfileData.previewUrl = (
              accountProfileData.previewUrl as any
            ).changingThisBreaksApplicationSecurity;
          }

          localStorage.setItem(
            'accountProfileData',
            JSON.stringify(accountProfileData)
          );
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
