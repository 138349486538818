import { Component, Input, OnInit } from '@angular/core';

import { OrderService } from '@app/services/order.service';
import { ICheckCalls } from '@app/models/order.models';
import { convertToUSDateFormatWithTime12H } from '@app/shared/helpers/date.helper';

@Component({
  selector: 'app-checkcall',
  templateUrl: './checkcall.component.html',
  styleUrls: ['./checkcall.component.scss'],
})
export class CheckcallComponent implements OnInit {
  @Input() orderNum: number = 0;

  isLoading: boolean = false;
  checkcallDisplayedColumns: string[] = [
    'Last Update',
    'Location',
    'Comment',
    'Event',
  ];
  checkcallDataSource: ICheckCalls[] = [];
  convertToUSDateFormatWithTime12H = convertToUSDateFormatWithTime12H;

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    this.fetchCheckCalls();
  }

  fetchCheckCalls(): void {
    this.isLoading = true;
    this.orderService.getCheckcalls(this.orderNum).subscribe({
      next: (data: ICheckCalls[]) => {
        this.isLoading = false;
        this.checkcallDataSource = data;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }
}
