import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  changeFavicon(base64Data: string): void {
    let link: HTMLLinkElement =
      this.getFaviconElement() || this.createFaviconElement();
    link.href = base64Data;
  }

  private getFaviconElement(): HTMLLinkElement | null {
    return document.querySelector("link[rel*='icon']") as HTMLLinkElement;
  }

  private createFaviconElement(): HTMLLinkElement {
    const link: HTMLLinkElement = this.renderer.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    this.renderer.appendChild(document.head, link);
    return link;
  }
}
