import { Component, Input, OnInit } from '@angular/core';
import { OrderService } from '@app/services/order.service';
import { IStops } from '@app/models/order.models';
import { convertToUSDateFormatWithTime12H } from '@app/shared/helpers/date.helper';

@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.scss'],
})
export class StopsComponent implements OnInit {
  @Input() orderNum: number = 0;

  stopsDisplayedColumns: string[] = [
    'Company',
    'City/State',
    'ZipCode',
    'Trailer',
    'Status',
    'Appointment',
    'Arrival Date',
    'Departure Date',
    'Reason Late',
  ];
  stopsDataSource: IStops[] = [];
  isLoading: boolean = false;
  convertToUSDateFormatWithTime12H = convertToUSDateFormatWithTime12H;

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    this.fetchStops();
  }

  fetchStops(): void {
    this.isLoading = true;
    this.orderService.getStops(this.orderNum).subscribe({
      next: (data: IStops[]) => {
        this.isLoading = false;
        this.stopsDataSource = data;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  getTrailerIds(trailers: any[]): string {
    if (!trailers) return '';

    const grouped = trailers.reduce((acc, trailer) => {
      if (!acc[trailer.TrailerId]) {
        acc[trailer.TrailerId] = [];
      }
      acc[trailer.TrailerId].push(trailer);
      return acc;
    }, {});

    const trailerStrings = Object.keys(grouped).map((trailerId) => {
      const trailersWithSameId = grouped[trailerId];
      if (
        trailersWithSameId.every(
          (trailer: { TrailerNumber: any }) =>
            trailer.TrailerNumber === trailersWithSameId[0].TrailerNumber
        )
      ) {
        return `${trailerId}`;
      }
      return trailersWithSameId
        .map(
          (trailer: { TrailerId: any; TrailerNumber: any }) =>
            `${trailer.TrailerId}`
        )
        .join(', ');
    });

    return trailerStrings.join(', ');
  }
}
