import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserPermission } from '../models/userPermission';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const requiredPermission = route.data[
      'requiredPermission'
    ] as UserPermission;

    if (
      this.permissionService.hasPermission(
        requiredPermission.PermissionType,
        requiredPermission.Resource
      )
    ) {
      return true;
    }

    this.router.navigate(['/unauthorized']);
    return false;
  }
}
