import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Store } from '@ngrx/store';

import { OrderHistoryFiltersModalComponent } from './order-history-filters-modal/order-history-filters-modal.component';
import {
  IOrderHistory,
  SearchOrdersOptions,
} from '@app/models/orderHistory.models';
import { OrderService } from '@app/services/order.service';
import { SharedService } from '@app/services/shared.service';
import { PaginationParams } from '@app/models/pagination-params';
import { formatDateToString } from '@app/shared/helpers/date.helper';
import { Observable, Subject, takeUntil } from 'rxjs';
import { OrderHistoryDaterangeModalComponent } from './order-history-daterange-modal/order-history-daterange-modal.component';
import { getDateSixMonthsAgo } from '../../shared/helpers/date.helper';
import { OrderHistoryDateFilters } from '@app/models/orderHistoryDateFilters.model';
import { OrderHistoryColumnSort } from '@app/models/orderHistoryColumnSort.model';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

interface FiltersForm {
  consigneeState: string[];
  available: boolean;
  cancelled: boolean;
  delivered: boolean;
  inTransit: boolean;
  planned: boolean;
  start: Date | null;
  end: Date | null;
  pickupState: string[];
}
@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
})
export class OrderHistoryComponent implements AfterViewInit, OnDestroy {
  companyId: string = '';
  slidersIcon = faSlidersH;
  excelIcon = faFileExcel;
  pageSizeOptions = [10, 25, 50, 100];
  defaultPageSize = 25;
  orderHistoryDisplayedColumns: string[] = [
    'orderNumber',
    'status',
    'referenceNumber',
    'pickupName',
    'pickupCity',
    'pickupState',
    'consigneeName',
    'consigneeCity',
    'consigneeState',
    'billTo',
    'startDate',
    'endDate',
  ];
  orderHistoryDataSource: MatTableDataSource<IOrderHistory>;
  pageSize!: number;
  paginationParams!: PaginationParams;
  pageEvent!: PageEvent;
  startPageIdx!: number;
  endPageIdx!: number;
  totalRecordCount!: number;
  isLoading: boolean = true;
  searchValue: string = '';
  optionValue: string = '';
  filters: string[] = [];
  timesCircleIcon = faTimesCircle;
  filtersForm!: FiltersForm;
  isExpanded: boolean = true;
  isModalOpen: boolean = false;
  dialogRef: MatDialogRef<OrderHistoryFiltersModalComponent> | null = null;
  searchOptions: string[] = [
    SearchOrdersOptions.OrderNumber,
    SearchOrdersOptions.ReferenceNumber,
    SearchOrdersOptions.PickupName,
    SearchOrdersOptions.PickupCity,
    SearchOrdersOptions.ConsigneeName,
    SearchOrdersOptions.ConsigneeCity,
  ];
  isDateRangeModalOpen: boolean = false;
  dateRangeDialogRef: MatDialogRef<OrderHistoryDaterangeModalComponent> | null =
    null;
  setNavigatingToOrders: boolean = false;
  private destroy$ = new Subject<void>();

  @ViewChild(MatSort) sort!: MatSort;

  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;
  selectedDropdownValue: string = 'Last 6 Months';
  defaultStartDate = getDateSixMonthsAgo();
  defaultEndDate = new Date();
  sortColumn: string | null = null;
  sortDirection: string | null = null;
  private routerSubscription: Subscription;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private orderService: OrderService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private store: Store<{ sidenavConfig: any }>
  ) {
    this.orderHistoryDataSource = new MatTableDataSource<IOrderHistory>();

    this.routerSubscription = this.router.events.pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart)
    ).subscribe(event => {
      this.handleNavigation(event);
    });
  }

  handleNavigation(event: NavigationStart) {
    const orderUrlPattern = /^\/orders\/\d+$/;

    // Check if the navigation URL does NOT match the order URL pattern
    if (!orderUrlPattern.test(event.url)) {
      this.sortColumn = 'endDate';
      this.sortDirection = 'desc';
      this.saveColumnSort();
    }
  }

  ngOnInit() {
    this.resetFiltersForm();
    this.setPaginatorConfig();
    this.loadFromLocalStorage();

    this.sharedService
      .companyIdObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyId: string) => {
        const trimmedCompanyId = companyId.replace(/\s+/g, '');
        if (this.companyId !== trimmedCompanyId) {
          this.companyId = trimmedCompanyId;
          const status = this.route.snapshot.queryParams['status'];
          if (status) {
            this.setSubToQueryParams(status);
          } else {
            const dateFilters = this.retrieveOrderHistoryDateFilters();
            const sortFilter = this.retrieveOrderHistoryColumnSort();
            this.fetchOrderHistory(
              dateFilters?.startDate,
              dateFilters?.endDate,
              sortFilter?.sortColumn,
              sortFilter?.sortDirection,
            );
          }
        }
      });
    this.store
      .select('sidenavConfig')
      .pipe(takeUntil(this.destroy$))
      .subscribe((sidenavConfig) => {
        this.isExpanded = sidenavConfig.isExpanded;
      });
  }

  loadFromLocalStorage(): void {
    const savedState = JSON.parse(
      localStorage.getItem('orderHistoryPage') || '{}'
    );
    if (savedState && savedState.pageIndex !== undefined) {
      this.pageEvent.pageIndex = savedState.pageIndex;
      this.pageEvent.pageSize = savedState.pageSize;
      this.pageSize = savedState.pageSize;
      this.searchValue = savedState.searchValue || '';
      this.optionValue = savedState.optionValue || '';
      savedState.filters.start &&
        (savedState.filters.start = new Date(savedState.filters.start));
      savedState.filters.end &&
        (savedState.filters.end = new Date(savedState.filters.end));
      this.filtersForm = savedState.filters || this.filtersForm;
      this.setFiltersBox({
        consigneeState: savedState.filters.consigneeState,
        pickupState: savedState.filters.pickupState,
        form: {
          available: savedState.filters.available,
          cancelled: savedState.filters.cancelled,
          delivered: savedState.filters.delivered,
          planned: savedState.filters.planned,
          inTransit: savedState.filters.inTransit,
          start: savedState.filters.start,
          end: savedState.filters.end,
        },
      });
    }
  }

  saveToLocalStorage(): void {
    const state = {
      pageIndex: this.pageEvent?.pageIndex,
      pageSize: this.pageEvent?.pageSize,
      searchValue: this.searchValue,
      optionValue: this.optionValue,
      filters: this.filtersForm,
    };
    localStorage.setItem('orderHistoryPage', JSON.stringify(state));
  }

  saveDateRangeFilter() {
    const state = {
      selectedStartDate: this.selectedStartDate,
      selectedEndDate: this.selectedEndDate,
      dropDownValue: this.selectedDropdownValue,
    };
    sessionStorage.setItem('orderHistoryDateFilters', JSON.stringify(state));
  }

  saveColumnSort() {
    const state = {
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
    }
    sessionStorage.setItem('orderHistoryColumnSort', JSON.stringify(state));
  }

  onOptionValueChange(optionValue: string): void {
    this.optionValue = optionValue;
    this.saveToLocalStorage();
  }

  resetFiltersForm(): void {
    this.setNavigatingToOrders = false;
    this.filtersForm = {
      consigneeState: [],
      available: false,
      cancelled: false,
      delivered: false,
      inTransit: false,
      planned: false,
      start: null,
      end: null,
      pickupState: [],
    };
  }

  setPaginatorConfig(): void {
    this.pageSize = this.defaultPageSize;
    this.paginationParams = {
      limit: this.pageSize,
      offset: 0,
      total: 0,
    };
    this.pageEvent = {
      length: this.paginationParams.total || 0,
      pageIndex: 0,
      pageSize: this.paginationParams.limit,
    };
    this.startPageIdx = 1;
  }

  onPageChange(event: PageEvent): void {
    this.pageEvent = event;
    this.saveToLocalStorage();
    const dateFilters = this.retrieveOrderHistoryDateFilters();
    const sortFilter = this.retrieveOrderHistoryColumnSort();
    this.fetchOrderHistory(
      dateFilters?.startDate,
      dateFilters?.endDate,
      sortFilter?.sortColumn,
      sortFilter?.sortDirection,
    );
  }

  fetchOrderHistory(
    startDate: Date | null = null,
    endDate: Date | null = null,
    column: string | null = null,
    sortOrder: string | null = null
  ) {
    if (this.companyId) {
      this.isLoading = true;
      const request = this.getRequestOrderHistory();
      const sortedColumn = column ? this.switchColumnNames(column) : null;

      if (this.selectedDropdownValue !== 'Custom') {
        endDate = null;
      }

      this.orderService
        .getOrderHistory(request, startDate, endDate, sortedColumn, sortOrder)
        .subscribe({
          next: (data: any) => {
            this.isLoading = false;
            this.orderHistoryDataSource.data = data.Orders;
            this.paginationParams.total = data.TotalRecordCount;
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error fetching order history:', error);
          },
        });
    }
  }

  getRequestOrderHistory(): any {
    const searchOptionMap: any = {
      [SearchOrdersOptions.OrderNumber]: 'orderNumber',
      [SearchOrdersOptions.ReferenceNumber]: 'referenceNumber',
      [SearchOrdersOptions.ConsigneeCity]: 'consigneeCity',
      [SearchOrdersOptions.ConsigneeName]: 'consigneeName',
      [SearchOrdersOptions.PickupCity]: 'pickupCity',
      [SearchOrdersOptions.PickupName]: 'pickupName',
    };
    const requestStatus = this.generateRequestStatus(this.filtersForm);
    const request: any = {
      companyIds: this.companyId,
      page: this.pageEvent.pageIndex + 1,
      pageSize: this.pageEvent.pageSize,
      consigneeState: this.filtersForm.consigneeState
        ? this.filtersForm.consigneeState.join(',')
        : '',
      pickupState: this.filtersForm.pickupState
        ? this.filtersForm.pickupState.join(',')
        : '',
      status: requestStatus,
    };

    if (searchOptionMap[this.optionValue]) {
      request[searchOptionMap[this.optionValue]] = this.searchValue.trim();
    }
    return request;
  }

  generateRequestStatus(filtersForm: any): string {
    const statusArray = [];
    if (filtersForm.available) statusArray.push('Available');
    if (filtersForm.cancelled) statusArray.push('Cancelled');
    if (filtersForm.delivered) statusArray.push('Delivered');
    if (filtersForm.inTransit) statusArray.push('In Transit');
    if (filtersForm.planned) statusArray.push('Planned');

    return statusArray.join(',');
  }

  setSubToQueryParams(status: string): void {
    const result = {
      form: {
        delivered: false,
        available: false,
        planned: false,
        inTransit: false,
      },
    };
    switch (status) {
      case 'delivered':
        result.form.delivered = true;
        break;
      case 'available':
        result.form.available = true;
        break;
      case 'planned':
        result.form.planned = true;
        break;
      case 'in-transit':
        result.form.inTransit = true;
        break;
    }
    this.setFiltersBox(result);
    this.setFiltersForm(result);
    const dateFilters = this.retrieveOrderHistoryDateFilters();
    const sortFilter = this.retrieveOrderHistoryColumnSort();
    this.fetchOrderHistory(
      dateFilters?.startDate,
      dateFilters?.endDate,
      sortFilter?.sortColumn,
      sortFilter?.sortDirection,
    );
  }

  ngOnDestroy() {
    if (!this.setNavigatingToOrders) {
      localStorage.removeItem('orderHistoryPage');
    }
    this.destroy$.next();
    this.destroy$.complete();

    if (this.dialogRef) {
      this.dialogRef.close();
    }

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.orderHistoryDataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce('sorted ${ sortState.direction } ending');
    } else this._liveAnnouncer.announce('sorting cleared');
  }

  onRowClick(row: IOrderHistory) {
    this.setNavigatingToOrders = true;
    const orderNumber: number = Number(row.OrderNumber);
    this.router.navigate(['/orders', orderNumber]);
  }

  openDateRangeModal(): void {
    if (!this.isDateRangeModalOpen) {
      this.isDateRangeModalOpen = true;

      const panelClass = this.isExpanded
        ? 'orders-dateRange-modal-menu-expanded'
        : 'orders-dateRange-modal-menu-no-expanded';

      this.dateRangeDialogRef = this.dialog.open(
        OrderHistoryDaterangeModalComponent,
        {
          width: '415px',
          autoFocus: '.pickup-state-input',
          data: {
            startDate: this.selectedStartDate
              ? new Date(this.selectedStartDate)
              : new Date(this.defaultStartDate),
            endDate: this.selectedEndDate
              ? new Date(this.selectedEndDate)
              : new Date(this.defaultEndDate),
            selectedDropdown: this.selectedDropdownValue,
          },
          id: 'order-history-daterange',
          hasBackdrop: true,
          disableClose: false,
          panelClass,
        }
      );

      this.dateRangeDialogRef.afterClosed().subscribe((result) => {
        this.isDateRangeModalOpen = false;
        if (result) {
          this.selectedStartDate = result.startDate;
          this.selectedEndDate = result.endDate;
          this.selectedDropdownValue = result.selectedDropdown;
          const sortFilters = this.retrieveOrderHistoryColumnSort();
          this.fetchOrderHistory(
            this.selectedStartDate,
            this.selectedEndDate,
            sortFilters.sortColumn,
            sortFilters.sortDirection,
          );
          this.saveDateRangeFilter();
        }
      });
    }
  }

  openFiltersModal(): void {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      const panelClass = this.isExpanded
        ? 'orders-filters-modal-menu-expanded'
        : 'orders-filters-modal-menu-no-expanded';
      this.dialogRef = this.dialog.open(OrderHistoryFiltersModalComponent, {
        width: '400px',
        autoFocus: '.pickup-state-input',
        data: this.filtersForm,
        id: 'orders-filters',
        hasBackdrop: false,
        panelClass,
      });

      this.dialogRef.afterClosed().subscribe((result) => {
        this.isModalOpen = false;
        if (result) {
          this.goToFirstPage();
          if (result !== 'clearAll') {
            this.clearAll();
            this.setFiltersBox(result);
            this.setFiltersForm(result);
            const dateFilters = this.retrieveOrderHistoryDateFilters();
            const sortFilter = this.retrieveOrderHistoryColumnSort();
            this.fetchOrderHistory(
              dateFilters?.startDate,
              dateFilters?.endDate,
              sortFilter?.sortColumn,
              sortFilter?.sortDirection,
            );
          } else {
            this.clearAll(true);
          }
        }
      });
    }
  }

  setFiltersForm(result: any) {
    this.filtersForm = {
      consigneeState: result.consigneeState ? result.consigneeState : '',
      pickupState: result.pickupState ? result.pickupState : '',
      available: result.form.available,
      cancelled: result.form.cancelled,
      delivered: result.form.delivered,
      inTransit: result.form.inTransit,
      planned: result.form.planned ? result.form.planned : '',
      start: result.form.start ? result.form.start : '',
      end: result.form.end ? result.form.end : '',
    };
    this.saveToLocalStorage();
  }

  addStateToFilters(stateArray: string[], label: string) {
    if (stateArray.length) {
      const joinedState = stateArray.join(', ');
      this.filters.push(`${label}: ${joinedState}`);
    }
  }

  setFiltersBox(result: any): void {
    this.filters = [];
    result.consigneeState &&
      this.addStateToFilters(result.consigneeState, 'Consignee State');
    result.pickupState &&
      this.addStateToFilters(result.pickupState, 'Pickup State');
    const startDate = result.form.start
      ? formatDateToString(result.form.start)
      : '';
    const endDate = result.form.end ? formatDateToString(result.form.end) : '';
    const separator = result.form.start && result.form.end ? '-' : '';
    if (result.form.start) {
      this.filters.push(`Date Range: ${startDate} ${separator} ${endDate}`);
    }
    const formStatuses = [
      { condition: result.form.available, label: 'Available' },
      { condition: result.form.cancelled, label: 'Cancelled' },
      { condition: result.form.delivered, label: 'Delivered' },
      { condition: result.form.inTransit, label: 'In Transit' },
      { condition: result.form.planned, label: 'Planned' },
    ];
    formStatuses.forEach((status) => {
      if (status.condition) {
        this.filters.push(status.label);
      }
    });
  }

  onSubmit(): void {
    const dateFilters = this.retrieveOrderHistoryDateFilters();
    const sortFilter = this.retrieveOrderHistoryColumnSort();
    this.fetchOrderHistory(
      dateFilters?.startDate,
      dateFilters?.endDate,
      sortFilter?.sortColumn,
      sortFilter?.sortDirection,
    );
    this.saveToLocalStorage();
    this.goToFirstPage();
  }

  goToFirstPage(): void {
    this.pageEvent.pageIndex = 0;
    this.saveToLocalStorage();
  }

  removeBox(index: number): void {
    this.goToFirstPage();
    const filterToRemove = this.filters[index];
    if (filterToRemove.startsWith('Consignee State:')) {
      this.filtersForm.consigneeState = [];
    } else if (filterToRemove.startsWith('Pickup State:')) {
      this.filtersForm.pickupState = [];
    } else if (filterToRemove.startsWith('Date Range:')) {
      this.filtersForm.start = null;
      this.filtersForm.end = null;
    } else if (filterToRemove === 'Available') {
      this.filtersForm.available = false;
      this.resetUrlFilters();
    } else if (filterToRemove === 'Delivered') {
      this.filtersForm.delivered = false;
      this.resetUrlFilters();
    } else if (filterToRemove === 'In Transit') {
      this.filtersForm.inTransit = false;
      this.resetUrlFilters();
    } else if (filterToRemove === 'Planned') {
      this.filtersForm.planned = false;
      this.resetUrlFilters();
    }
    this.filters.splice(index, 1);
    this.saveToLocalStorage();
    const dateFilters = this.retrieveOrderHistoryDateFilters();
    const sortFilter = this.retrieveOrderHistoryColumnSort();
    this.fetchOrderHistory(
      dateFilters?.startDate,
      dateFilters?.endDate,
      sortFilter?.sortColumn,
      sortFilter?.sortDirection,
    );
  }

  clearAll(fetchOrders: boolean = false): void {
    this.resetUrlFilters();
    this.goToFirstPage();
    this.filters = [];
    this.resetFiltersForm();
    if (fetchOrders) {
      const dateFilters = this.retrieveOrderHistoryDateFilters();
      this.fetchOrderHistory(
        dateFilters?.startDate,
        dateFilters?.endDate,
        this.sortColumn,
        this.sortDirection,
      );
      this.saveToLocalStorage();
    }
  }

  resetUrlFilters(): void {
    const queryParams: any = {};
    queryParams.status = null;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  downloadOrderHistoryCsv() {
    const startDate: Date | null = this.retrieveOrderHistoryDateFilters()?.startDate;
    let endDate: Date | null = this.retrieveOrderHistoryDateFilters()?.endDate;
    const column: string | null = this.retrieveOrderHistoryColumnSort().sortColumn;
    const sortOrder: string | null = this.retrieveOrderHistoryColumnSort().sortDirection;

    const request = this.getRequestOrderHistory();
    const sortedColumn = column ? this.switchColumnNames(column) : null;

    if (this.selectedDropdownValue !== 'Custom') {
      endDate = null;
    }

    this.orderService
      .getOrderHistoryCsv(request, startDate, endDate, sortedColumn, sortOrder)
      .subscribe(
        (data) => {
        this.downloadFile(data);
        },
        (error) => {
          console.error('Error downloading the file:', error);
        }
    );
  }

  private downloadFile(data: Blob) {
    const url = window.URL.createObjectURL(data);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'orderHistory.csv';
    anchor.click();
    window.URL.revokeObjectURL(url);
  }

  onSortChange(sortState: Sort) {
    console.log(
      `Sorting changed on column ${sortState.active} in ${sortState.direction} direction`
    );
    this.sortColumn = sortState.active;
    this.sortDirection = sortState.direction;
    const dateFilters = this.retrieveOrderHistoryDateFilters();
    this.fetchOrderHistory(
      dateFilters?.startDate,
      dateFilters?.endDate,
      this.sortColumn,
      this.sortDirection
    );
    this.saveColumnSort();
  }

  switchColumnNames(columnName: string): string {
    const columnMappings: { [key: string]: string } = {
      endDate: 'CompletionDate',
      billTo: 'BillToName',
      pickupState: 'ShipperState',
      pickupCity: 'ShipperCity',
      pickupName: 'ShipperName',
      referenceNumber: 'ReferenceNum',
    };

    if (columnName in columnMappings) {
      return columnMappings[columnName];
    }

    return columnName;
  }

  retrieveOrderHistoryDateFilters(): OrderHistoryDateFilters {
    const filterStateString = sessionStorage.getItem('orderHistoryDateFilters');
    if (!filterStateString) {
      const defaultOrderHistoryDateFilters: OrderHistoryDateFilters = {
        startDate: this.defaultStartDate,
        endDate: this.defaultEndDate,
        dropDownValue: 'Last 6 Months',
      };
      this.selectedStartDate = defaultOrderHistoryDateFilters.startDate;
      this.selectedEndDate = defaultOrderHistoryDateFilters.endDate;
      this.selectedDropdownValue = defaultOrderHistoryDateFilters.dropDownValue;
      this.saveDateRangeFilter();
      return defaultOrderHistoryDateFilters;
    } else {
      const sessionFilters = JSON.parse(filterStateString);
      const startDate = new Date(sessionFilters.selectedStartDate);
      const endDate = new Date(sessionFilters.selectedEndDate);
      this.selectedDropdownValue = sessionFilters.dropDownValue;

      const orderHistoryDateFilters: OrderHistoryDateFilters = {
        startDate: startDate,
        endDate: endDate,
        dropDownValue: sessionFilters.dropDownValue,
      };
      return orderHistoryDateFilters;
    }
  }

  retrieveOrderHistoryColumnSort(): OrderHistoryColumnSort {
    const columnSortStateString = sessionStorage.getItem('orderHistoryColumnSort');
    if (!columnSortStateString) {
      const defaultOrderHistoryColumnSort: OrderHistoryColumnSort = {
        sortColumn: 'endDate',
        sortDirection: 'desc',
      };
      this.sortColumn = defaultOrderHistoryColumnSort.sortColumn;
      this.sortDirection = defaultOrderHistoryColumnSort.sortDirection;
      this.saveColumnSort();
      return defaultOrderHistoryColumnSort;
    } else {
      const sessionFilters = JSON.parse(columnSortStateString);
      const sortColumn = sessionFilters.sortColumn;
      const sortDirection = sessionFilters.sortDirection;

      const orderHistoryColumnSort: OrderHistoryColumnSort = {
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      return orderHistoryColumnSort;
    }
  }
}
