import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';

import { ISelect } from '@app/models/select.model';
import { ShipperListElement } from '@app/models/shipper.model';
import { RoleType } from '@app/models/user.model';
import { ShipperService } from '@app/services/shipper.service';
import { ToasterService } from '@app/services/toaster.service';
import { environment } from '@env/environment';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidatorService } from '../../../services/form.validator.service';

@Component({
  selector: 'app-new-user-modal',
  templateUrl: './new-user-modal.component.html',
  styleUrls: ['./new-user-modal.component.scss'],
})
export class NewUserModalComponent implements OnInit {
  userForm!: FormGroup;
  editMode!: boolean;
  adminUsers!: boolean;
  firstName!: string;
  lastName!: string;
  lastLogin!: string;
  fileName: string = 'Not picture selected';
  previewUrl: string | ArrayBuffer | null = null;
  roles: ISelect[] = [
    {
      id: 1,
      value: RoleType.ADMIN,
    },
    {
      id: 2,
      value: RoleType.USER,
    },
  ];
  shippers: ShipperListElement[] = [];
  carrierId: number = environment.carrierId;
  isDisabled: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<NewUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private shipperService: ShipperService,
    private toasterService: ToasterService,
    private dialog: MatDialog,
    private formValidator: FormValidatorService
  ) {
    if (data.adminUsers) this.adminUsers = data.adminUsers;

    const formControls: Record<string, any> = {
      firstName: [data.FirstName, [Validators.required, formValidator.noSpacesValidator(), formValidator.noSpecialCharsValidator()]],
      lastName: [data.LastName, [Validators.required, formValidator.noSpacesValidator(), formValidator.noSpecialCharsValidator()]],
      email: [data.Email, [Validators.required, Validators.email]],
      //password: ['', [Validators.required]],
      avatar: [null],
    };
    if (!this.adminUsers) {
      const role =
        data.UserType === 'shipper admin' ? RoleType.ADMIN : RoleType.USER;
      formControls['shipper'] = [data?.shipper?.BillTos, [Validators.required]];
      formControls['role'] = [role, [Validators.required]];
    }
    this.userForm = this.fb.group(formControls);

    this.editMode = data.editMode;
    this.firstName = data.FirstName;
    this.lastName = data.LastName;
    this.lastLogin = data.lastLogin;
  }

  getShipperIdFromBillTos(): number {
    const item = this.shippers.find(
      (obj) => obj.BillTos === this.userForm.get('shipper')?.value
    );

    return item?.Id!;
  }

  ngOnInit(): void {
    this.userForm.get('firstName')?.valueChanges.subscribe((value) => {
      this.firstName = value;
    });
    this.userForm.get('lastName')?.valueChanges.subscribe((value) => {
      this.lastName = value;
    });
    this.fetchShippers();
  }

  fetchShippers(): void {
    this.shipperService.getShippers(this.carrierId).subscribe({
      next: (response) => {
        this.shippers = response.sort((a, b) => a.Name.localeCompare(b.Name));
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.isDisabled = true;
    const request: any = {
      FirstName: this.userForm.get('firstName')?.value,
      LastName: this.userForm.get('lastName')?.value,
      Email: this.userForm.get('email')?.value,
      UserType:
        this.userForm.get('role')?.value === RoleType.USER
          ? 'shipper user'
          : 'shipper admin',
      ShipperId: this.getShipperIdFromBillTos(),
      LastModifiedBy: localStorage.getItem('objectId'),
    };

    if (this.data?.ObjectId) {
      request.ObjectId = this.data.ObjectId;
    }
    if (this.data?.PrincipalName) {
      request.PrincipalName = this.data.PrincipalName;
    }
    this.shipperService.upsertShipperUser(request).subscribe({
      next: () => {
        this.toasterService.showSuccess(
          `The User was ${
            this.editMode === true ? 'edited' : 'created'
          } successfully`
        );
        this.shipperService.notifyFetchShippers();
        this.dialogRef.close(this.userForm.value);
      },
      error: (error) => {
        this.isDisabled = false;
        const errorMessage = error.error.error;
        this.toasterService.showError(`Error Creating User: ${errorMessage}`);
        console.log('Error object:', error);
      },
    });
  }

  onDelete(): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete the User?',
      },
      id: 'confirm-dialog',
    });

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.shipperService
          .deleteShipperUser(this.getShipperIdFromBillTos(), this.data.ObjectId)
          .subscribe({
            next: () => {
              this.toasterService.showSuccess(
                'The User was deleted successfully'
              );
              this.dialogRef.close(null);
            },
            error: (error) => {
              console.log(error);
            },
          });
      }
    });
  }

  isOnSaveDisabled() {
    return !this.userForm.valid || this.isDisabled;
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      this.fileName = file.name;
      this.userForm.patchValue({ avatar: file });
      this.userForm.get('avatar')!.updateValueAndValidity();

      const reader = new FileReader();
      reader.onload = () => {
        this.previewUrl = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  noSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;  
      const hasWhitespace = /\s/.test(control.value); 
      return hasWhitespace ? { whitespace: true } : null;
    };
  }
  noSpecialCharsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasSpecialChars = /[^a-zA-Z0-9 ]/.test(control.value || '');
      return hasSpecialChars ? { specialChars: true } : null;
    };
  }
}
