import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';

import { ToasterService } from '@app/services/toaster.service';
import { AppConfig } from '@app/app.config';
import * as AccountProfileActions from '@app/components/navigation/header/account-profile-store/account-profile.actions';
import { UserType } from '@app/models/user.model';
import { UserService } from '@app/services/user.service';
import { environment } from '@env/environment';
import { ImageService } from '@app/services/image.service';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-account-profile-modal',
  templateUrl: './account-profile-modal.component.html',
  styleUrls: ['./account-profile-modal.component.scss'],
})
export class AccountProfileModalComponent implements OnInit {
  userForm!: FormGroup;
  previewUrl: string | ArrayBuffer | null = null;
  userInitials: string | null = null;
  selectedFile!: File;
  ngUnsubscribe = new Subject();
  successMessageShown: boolean = false;

  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<AccountProfileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private store: Store<{ accountProfile: any }>,
    private userService: UserService,
    private imageService: ImageService,
    private dialog: MatDialog
  ) {
    this.userForm = this.fb.group({
      firstName: [data.FirstName, [Validators.required]],
      lastName: [data.LastName, [Validators.required]],
      email: [data.Email, [Validators.required, this.emailValidator]],
    });
  }

  ngOnInit(): void {
    this.setSubscriptionToInitials();
    this.loadSavedData();
  }

  loadSavedData(): void {
    this.store.select('accountProfile').subscribe((accountProfileData) => {
      if (accountProfileData?.firstName) {
        this.userForm.get('firstName')?.setValue(accountProfileData.firstName);
      }

      if (accountProfileData?.lastName) {
        this.userForm.get('lastName')?.setValue(accountProfileData.lastName);
      }

      if (accountProfileData?.email) {
        this.userForm.get('email')?.setValue(accountProfileData.email);
      }

      if (accountProfileData?.previewUrl) {
        this.previewUrl = accountProfileData.previewUrl;
      }
    });
  }

  setSubscriptionToInitials(): void {
    this.userForm
      .get('firstName')
      ?.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updateInitials();
      });

    this.userForm
      .get('lastName')
      ?.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updateInitials();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  updateInitials(): void {
    const firstName = this.userForm.get('firstName')?.value || '';
    const lastName = this.userForm.get('lastName')?.value || '';

    if (firstName && lastName) {
      const firstInitial = firstName[0].toUpperCase();
      const lastInitial = lastName[0].toUpperCase();
      this.userInitials = firstInitial + lastInitial;
    } else {
      this.userInitials = null;
    }
  }

  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const emailRegex = AppConfig.emailPattern;
    if (!control.value.match(emailRegex)) {
      return { invalidEmail: true };
    }
    return null;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDeleteImage(): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete the image?',
      },
      id: 'confirm-dialog',
    });

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const imageType = 'user';
        const id = this.data.ObjectId;

        this.previewUrl = null;
        this.fileInput.nativeElement.value = '';
        this.imageService.deleteImage(id, imageType).subscribe({
          next: () => {
            this.saveDataOnStore();
            this.toasterService.showSuccess(
              'The image was deleted successfully'
            );
          },
          error: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  handleFile(file: File): void {
    this.selectedFile = file;
    // File type validation
    if (
      !['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'].includes(
        file.type
      )
    ) {
      this.toasterService.showError('File type not allowed');
      return;
    }

    // Image size validation
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width < 100 || img.height < 100 || img.width !== img.height) {
        this.toasterService.showError(
          'The dimensions of the image are not valid'
        );
        return;
      }

      // image read and preview
      const reader = new FileReader();
      reader.onload = () => {
        this.previewUrl = reader.result;
      };
      reader.readAsDataURL(file);
    };
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      const file = files[0];
      this.handleFile(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      this.handleFile(file);
    }
  }

  isOnSaveDisabled() {
    return !this.userForm.valid;
  }

  onSave(): void {
    if (this.userForm.pristine) {
      this.saveUserImage(true);
      return;
    }
    const request: any = {
      ObjectId: this.data.ObjectId,
      FirstName: this.userForm.get('firstName')?.value,
      LastName: this.userForm.get('lastName')?.value,
      Email: this.userForm.get('email')?.value,
      PrincipalName: this.data.PrincipalName,
      UserType: this.data.UserType,
      LastModifiedBy: this.data.ObjectId,
    };
    this.saveUserImage(false);
    if (
      this.data.UserType === UserType.SHIPPER_USER ||
      this.data.UserType === UserType.SHIPPER_ADMIN
    ) {
      request.ShipperId = this.data.ShipperId;
    } else {
      request.CarrierId = environment.carrierId;
    }
    if (this.data.UserType === UserType.CARRIER_USER) {
      this.userService.upsertCarrierUser(request).subscribe({
        next: () => this.hasSuccess(),
        error: () => this.toasterService.showError('An error ocurred'),
      });
    } else {
      this.userService.createEditShipperUser(request).subscribe({
        next: () => this.hasSuccess(),
        error: () => this.toasterService.showError('An error ocurred'),
      });
    }
  }

  saveUserImage(onlyImage: boolean): void {
    if (this.selectedFile) {
      this.imageService
        .uploadImage(
          this.selectedFile,
          this.previewUrl,
          this.data.ObjectId,
          'user'
        )
        .subscribe({
          next: () => {
            if (onlyImage && !this.successMessageShown) {
              this.toasterService.showSuccess('Changes saved successfully');
              this.successMessageShown = true;
            }
            this.hasSuccess();
          },
          error: (error) => {
            console.log(error);
          },
        });
    }
  }

  hasSuccess(): void {
    if (!this.successMessageShown) {
      this.toasterService.showSuccess('Changes saved successfully');
      this.successMessageShown = true;
    }
    this.saveDataOnStore();
  }

  saveDataOnStore(): void {
    const accountProfileData = {
      firstName: this.userForm.get('firstName')?.value,
      lastName: this.userForm.get('lastName')?.value,
      email: this.userForm.get('email')?.value,
      previewUrl: this.previewUrl,
    };
    this.dialogRef.close(this.userForm.value);
    this.store.dispatch(
      AccountProfileActions.saveAccountProfile({ accountProfileData })
    );
  }
}
