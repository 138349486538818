<!-- Button with mat-button -->
<button
  *ngIf="buttonType === 'button'"
  mat-button
  [type]="type"
  class="custom-button"
  [class.delete-button]="isDelete"
  [class.inverted-button]="isInverted"
  (click)="onClick()"
  [disabled]="isDisabled"
>
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</button>

<!-- Button with mat-stroked-button -->
<button
  *ngIf="buttonType === 'stroked-button'"
  mat-stroked-button
  [type]="type"
  class="custom-button"
  [class.delete-button]="isDelete"
  (click)="onClick()"
  [disabled]="isDisabled"
>
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</button>

<!-- Button content -->
<ng-template #buttonContent>
  <span *ngIf="!isIcon && icon" class="material-symbols-outlined menu-icon">
    {{ icon }}
  </span>
  <mat-icon *ngIf="isIcon && icon">{{ icon }}</mat-icon>
  {{ label }}
</ng-template>
