import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  constructor() { }

  noSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const hasLeadingOrTrailingWhitespace = /^\s+|\s+$/.test(control.value);
      return hasLeadingOrTrailingWhitespace ? { whitespace: true } : null;
    };
  }


  noSpecialCharsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const hasSpecialChars = /[^a-zA-Z0-9 .'-]|^[\s.'-]|[\s.'-]$/.test(control.value);
      return hasSpecialChars ? { specialChars: true } : null;
    };
  }
}
