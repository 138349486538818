import { Component, Input, OnInit } from '@angular/core';
import { OrderService } from '@app/services/order.service';
import { Order, Trailer } from '@app/models/order.models';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { ITrimbleMap } from '@app/models/trimble-map.model';
import { CarrierOrderStatus } from '@app/models/carrierOrderStatus.model';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  @Input() orderNum: number = 0;
  @Input() isExpanded!: boolean;

  orderDetails: Order | undefined;
  statusDisplayMap: { [key: string]: string } = {};
  selectedStatus: string = '';

  masterBOL: string = '';
  scacCode: string = '';
  billofLanding: string = '';
  deliveryReferance: string = '';
  appointmentNumber: string = '';
  customerOrderID: string = '';
  masterPO: string = "";
  isMapLoading: boolean = false;
  markers: any[] = [];
  myMap!: TrimbleMaps.Map;
  route!: TrimbleMaps.Route;
  showNoRouteMessage!: boolean;
  trailers: Trailer[] = [];

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    this.loadAPIKeyAndInitializeMap();
    this.fetchOrderDetail();
    this.fetchCarrierOrderStatuses();
  }

  loadAPIKeyAndInitializeMap(): void {
    const trimbleMapsAPIKey = localStorage.getItem('TrimbleMapsAPIKey');
    if (trimbleMapsAPIKey !== null) {
      TrimbleMaps.APIKey = trimbleMapsAPIKey;
      this.initializeMap();
    } else {
      console.log('No Trimble Maps API Key Found.');
    }
  }


  fetchOrderDetail(): void {
    this.orderService.getOrderDetailsSummary(this.orderNum).subscribe(
      (data: Order) => {
        this.orderDetails = data;
        this.trailers = this.orderDetails?.Trailers || [];
        this.selectedStatus =
          this.statusDisplayMap[this.orderDetails?.Status] || 'Unknown Status';

        const references = this.orderDetails?.ReferenceNumbers;
        if (references) {
          const masterBOLReferences = references.filter(
            (ref) => ref.ReferenceType === 'BL#'
          );
          this.masterBOL =
            masterBOLReferences.length > 0
              ? masterBOLReferences.map((ref) => ref.Value).join(', ')
              : '';
          const sccReferences = references.filter(
            (ref) => ref.ReferenceType === 'SCC'
          );
          this.scacCode =
            sccReferences.length > 0
              ? sccReferences.map((ref) => ref.Value).join(', ')
              : '';
          const bmReferences = references.filter(
            (ref) => ref.ReferenceType === 'BM'
          );
          this.billofLanding =
            bmReferences.length > 0
              ? bmReferences.map((ref) => ref.Value).join(', ')
              : '';
          const coReferences = references.filter(
            (ref) => ref.ReferenceType === 'CO'
          );
          this.deliveryReferance =
            coReferences.length > 0
              ? coReferences.map((ref) => ref.Value).join(', ')
              : '';
          const aoReferences = references.filter(
            (ref) => ref.ReferenceType === 'AO'
          );
          this.appointmentNumber =
            aoReferences.length > 0
              ? aoReferences.map((ref) => ref.Value).join(', ')
              : '';
          const sidReferences = references.filter(
            (ref) => ref.ReferenceType === 'SID'
          );
          this.customerOrderID =
            sidReferences.length > 0
              ? sidReferences.map((ref) => ref.Value).join(', ')
              : '';
          const poReferences = references.filter(
            (ref) => ref.ReferenceType === 'PO'
          );
          this.masterPO =
            poReferences.length > 0
              ? poReferences.map((ref) => ref.Value).join(', ')
              : '';
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  initializeMap(): void {
    this.isMapLoading = true;
    this.showNoRouteMessage = false;
    this.orderService.getMapInfo(this.orderNum).subscribe({
      next: async (res: ITrimbleMap) => {
        this.isMapLoading = false;

        if (res.routeInfo.message) {
          this.showNoRouteMessage = true;
          this.myMap = new TrimbleMaps.Map({
            container: 'myMap',
            center: new TrimbleMaps.LngLat(-96, 37),
            zoom: 3,
            style: TrimbleMaps.Common.Style.DATADARK,
          });
        } else {
          this.loadInfoInMap(res);
        }
      },
      error: (error) => {
        this.isMapLoading = false;
        console.log(error);
      },
    });
  }

  async loadInfoInMap(res: any) {
    let lng = -96;
    let lat = 35;
    const origin = res.routeInfo.origin;
    const destination = res.routeInfo.destination;
    const truckLocation = res?.truckLocation;
    const waypoints = res?.routeInfo?.waypoints
      ? res?.routeInfo.waypoints
      : null;

    if (truckLocation) {
      if ('longitude' in truckLocation && 'latitude' in truckLocation) {
        lng = -truckLocation.longitude / 3600;
        lat = truckLocation.latitude / 3600;
        this.loadMap(lng, lat, truckLocation, origin, destination, waypoints);
      } else if ('address' in truckLocation) {
        try {
          const coordinates = await this.geocodeAddress(truckLocation.address);
          this.loadMap(
            coordinates.lng,
            coordinates.lat,
            truckLocation,
            origin,
            destination,
            waypoints
          );
        } catch (error) {
          console.error('Geocoding error:', error);
        }
      }
    }
  }

  loadMap(
    lng: number,
    lat: number,
    truckLocation: any,
    origin: any,
    destination: any,
    waypoints?: any
  ) {
    this.myMap = new TrimbleMaps.Map({
      container: 'myMap',
      center: new TrimbleMaps.LngLat(lng, lat),
      zoom: 6,
      style: TrimbleMaps.Common.Style.TRANSPORTATION,
    });

    this.myMap.on('load', async () => {
      await this.addMarkerAndPopup(this.myMap, origin);
      if (waypoints !== null) {
        for (const waypoint of waypoints) {
          await this.addMarkerAndPopup(this.myMap, waypoint);
        }
      }
      if (this.selectedStatus === 'AVL') {
        await this.addMarkerAndPopup(
          this.myMap,
          { ...truckLocation, latitude: lat, longitude: lng },
          true
        );
        await this.addMarkerAndPopup(this.myMap, destination);
      } else {
        if (truckLocation && !truckLocation.address) {
          await this.addMarkerAndPopup(
            this.myMap,
            { ...truckLocation, latitude: lat, longitude: lng },
            true,
            true
          );
        }
        await this.addMarkerAndPopup(this.myMap, destination);
        if (truckLocation && truckLocation.address) {
          await this.addMarkerAndPopup(
            this.myMap,
            { ...truckLocation, latitude: lat, longitude: lng },
            true,
            true
          );
        }
      }
      this.createRoute();
    });
  }

  geocodeAddress(address: string): Promise<TrimbleMaps.LngLat> {
    return new Promise((resolve, reject) => {
      const zipCodeRegex = /\b\d{5}(?:-\d{4})?\b$/;
      const zipCodeMatch = address.match(zipCodeRegex);
      let zipCode = '';

      if (zipCodeMatch) {
        zipCode = zipCodeMatch[0];
      } else {
        reject('error');
        return;
      }

      TrimbleMaps.Geocoder.geocode({
        address: {
          addr: address,
          zip: zipCode,
        },
        success: function (response) {
          const coordinates = new TrimbleMaps.LngLat(
            response[0].Coords.Lon,
            response[0].Coords.Lat
          );
          resolve(coordinates);
        },
        failure: function (error) {
          reject(error);
        },
      });
    });
  }

  async addMarkerAndPopup(
    map: TrimbleMaps.Map,
    locationData: any,
    withLngLat: boolean = false,
    isATruck: boolean = false
  ): Promise<void> {
    const address = locationData.address;
    const popUpInfo = locationData.popUpInfo;
    let latitude = null;
    let longitude = null;
    if (withLngLat) {
      latitude = locationData.latitude;
      longitude = locationData.longitude;
    }

    try {
      let marker: any = null;
      if (!withLngLat) {
        const coordinates = await this.geocodeAddress(address);
        marker = new TrimbleMaps.Marker().setLngLat(coordinates).addTo(map);
        this.markers.push(coordinates);
      } else {
        const iconSize = [30, 40];
        const img = document.createElement('img');
        img.src = 'assets/images/truck.png';
        img.width = iconSize[0];
        img.height = iconSize[1];

        const coordinates = new TrimbleMaps.LngLat(longitude, latitude);
        this.markers.push(coordinates);
        marker = new TrimbleMaps.Marker({
          element: img,
          anchor: 'bottom',
        })
          .setLngLat(coordinates)
          .addTo(map);
      }

      const popup = new TrimbleMaps.Popup({ offset: 20 }).setHTML(
        '<div style="width: 160px;">' +
          popUpInfo.click.text.replace(/\r/g, '<br>') +
          '</div>'
      );

      const hoverPopup = new TrimbleMaps.Popup({ offset: 20 }).setHTML(
        '<div style="width: 160px;">' +
          popUpInfo.hover.text.replace(/\r/g, '<br>') +
          '</div>'
      );

      // With also a click event, when is a truck
      if (isATruck) {
        marker.getElement().addEventListener('click', () => {
          marker.setPopup(popup);
          hoverPopup.addTo(map);
        });

        marker.getElement().addEventListener('mouseenter', () => {
          marker.setPopup(hoverPopup);
          hoverPopup.addTo(map);
          popup.remove();
        });
        marker.getElement().addEventListener('mouseleave', () => {
          hoverPopup.remove();
        });
      } else {
        // Without click event, when is destination, origin or waypoint
        marker.setPopup(popup);

        marker
          .getElement()
          .addEventListener('mouseenter', () => popup.addTo(map));
        marker
          .getElement()
          .addEventListener('mouseleave', () => popup.remove());
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  createRoute(): void {
    this.route = new TrimbleMaps.Route({
      routeId: 'myRoute',
      routeColor: '#5e5c5c',
      stops: this.markers,
      routePathOpacity: 1,
      routeWidth: 3,
      showStops: true,
      routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
    }).addTo(this.myMap);
    // add custom stop icons.
    // this.route.addStopIcon('start', 'assets/images/truck.png');
    // this.route.addStopIcon('end', 'assets/images/truck.png');
    // this.route.addStopIcon('stop', 'assets/images/truck.png');
    // style custom stop icons. For detailed information, visit https://developer.trimblemaps.com/maps-sdk/style-spec/layers/#symbol
    const stopLayerStyle = {
      layout: {
        'icon-size': [
          'case',
          ['==', ['get', 'stopType'], 'start'],
          0.2, // stopType is an internal property, it has start, end, and stop values for now.
          ['==', ['get', 'stopType'], 'end'],
          0.2,
          0.2, // stops
        ],
        'icon-offset': [0, -10],
      },
    };
    this.route.styleStopIcons(stopLayerStyle);
  }

  getStatusStyles(status: string): any {
    return status === this.selectedStatus
      ? { backgroundColor: '#006600', color: 'var(--shade9)' }
      : { backgroundColor: 'var(--shade8)', color: 'var(--shade5)' };
  }

  fetchCarrierOrderStatuses(): void {
    this.orderService.getCarrierOrderStatuses().subscribe({
      next: (data: CarrierOrderStatus[]) => {
        data.forEach((status) => {
          this.statusDisplayMap[status.StatusCode] = status.Mapping;
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getTrailerIds(): string {
    return this.trailers.map((trailer) => trailer.TrailerId).join(', ');
  }
}
