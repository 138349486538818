import { Component, OnInit, Inject } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, of, startWith, map } from 'rxjs';
import { ALL_STATES } from '@app/core/constants/states.constant';

@Component({
  selector: 'app-order-history-filters-modal',
  templateUrl: './order-history-filters-modal.component.html',
  styleUrls: ['./order-history-filters-modal.component.scss'],
})
export class OrderHistoryFiltersModalComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  pickupState: string[] = [];
  consigneeState: string[] = [];
  allStates: string[] = ALL_STATES;
  filtersForm!: FormGroup;

  filteredPickupStates!: Observable<string[]>;
  filteredConsigneeStates!: Observable<string[]>;

  constructor(
    private dialogRef: MatDialogRef<OrderHistoryFiltersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.filtersForm = this.fb.group({
      pickupState: [data.pickupState],
      consigneeState: [data.consigneeState],
      available: [data.available],
      planned: [data.planned],
      inTransit: [data.inTransit],
      delivered: [data.delivered],
      cancelled: [data.cancelled],
      start: [data.start],
      end: [data.end],
    });
    if (data.pickupState) {
      this.pickupState = [...data.pickupState];
    }
    if (data.consigneeState) {
      this.consigneeState = [...data.consigneeState];
    }
  }

  ngOnInit(): void {
    this.updateFilteredStates();
  }

  updateFilteredStates(): void {
    this.filteredPickupStates =
      this.filtersForm.get('pickupState')?.valueChanges.pipe(
        startWith(null),
        map((state: string | null) =>
          state
            ? this._filter(state, this.pickupState)
            : this.allStates.filter((s) => !this.pickupState.includes(s))
        )
      ) ?? of([]);

    this.filteredConsigneeStates =
      this.filtersForm.get('consigneeState')?.valueChanges.pipe(
        startWith(null),
        map((state: string | null) =>
          state
            ? this._filter(state, this.consigneeState)
            : this.allStates.filter((s) => !this.consigneeState.includes(s))
        )
      ) ?? of([]);
  }

  private _filter(value: string, currentStates: string[]): string[] {
    const filterValue = value.toUpperCase();
    return this.allStates.filter(
      (state) => state.includes(filterValue) && !currentStates.includes(state)
    );
  }

  addFromAutocomplete(
    event: MatAutocompleteSelectedEvent,
    targetStates: string[]
  ): void {
    const value = event.option.viewValue;
    if (
      (value || '').trim() &&
      this.allStates.includes(value.trim().toUpperCase()) &&
      !targetStates.includes(value.trim().toUpperCase())
    ) {
      targetStates.push(value.trim().toUpperCase());
      this.updateFilteredStates();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  add(event: MatChipInputEvent, targetStates: string[]): void {
    const input = event.chipInput?.inputElement;
    const value = event.value;
    if (
      (value || '').trim() &&
      this.allStates.includes(value.trim().toUpperCase()) &&
      !targetStates.includes(value.trim().toUpperCase())
    ) {
      targetStates.push(value.trim().toUpperCase());
    }

    if (input) {
      input.value = '';
    }

    this.updateFilteredStates();
  }

  remove(state: string, targetStates: string[]): void {
    const index = targetStates.indexOf(state);

    if (index >= 0) {
      targetStates.splice(index, 1);
    }
    this.updateFilteredStates();
  }

  onApply(): void {
    const result = {
      form: this.filtersForm.value,
      pickupState: this.pickupState,
      consigneeState: this.consigneeState,
    };
    this.dialogRef.close(result);
  }

  onClearAll(): void {
    this.dialogRef.close('clearAll');
  }
}
