import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrandingComponent } from './components/branding/branding.component';
import { HomeComponent } from './components/home/home.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { OrderComponent } from './components/order/order.component';
import { ReportsComponent } from './components/analytics/analytics.component';
import { ShippersComponent } from './components/shippers/shippers.component';
import { UsersComponent } from './components/users/users.component';
import { ShipperDetailComponent } from './components/shippers/shipper-detail/shipper-detail.component';
import { InternalUsersComponent } from './components/internal-users/internal-users.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { AdminSettingsComponent } from './components/admin-settings/admin-settings.component';
import { AuthGuard } from './services/auth.guard';
import { CanDeactivateGuard } from './services/guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'branding',
    component: BrandingComponent,
    canActivate: [AuthGuard],
    data: {
      requiredPermission: {
        PermissionType: 'WRITE',
        Resource: 'Portal Settings',
      },
    },
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'orders/:orderNumber',
    component: OrderComponent,
    canActivate: [AuthGuard],
    data: {
      requiredPermission: { PermissionType: 'READ', Resource: 'Orders' },
    },
  },
  {
    path: 'order/history',
    component: OrderHistoryComponent,
    canActivate: [AuthGuard],
    data: {
      requiredPermission: { PermissionType: 'READ', Resource: 'Orders' },
    },
  },
  {
    path: 'analytics',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    data: {
      requiredPermission: { PermissionType: 'READ', Resource: 'Reports' },
    },
  },
  {
    path: 'shippers',
    component: ShippersComponent,
    canActivate: [AuthGuard],
    data: {
      requiredPermission: {
        PermissionType: 'WRITE',
        Resource: 'Shipper Organization',
      },
    },
  },
  {
    path: 'internal-users',
    component: InternalUsersComponent,
    canActivate: [AuthGuard],
    data: {
      requiredPermission: {
        PermissionType: 'WRITE',
        Resource: 'Carrier Users',
      },
    },
  },
  {
    path: 'shippers/detail/:id',
    component: ShipperDetailComponent,
    canActivate: [AuthGuard],
    data: {
      requiredPermission: {
        PermissionType: 'WRITE',
        Resource: 'Shipper Organization',
      },
    },
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: {
      requiredPermission: {
        PermissionType: 'WRITE',
        Resource: 'Shipper Users',
      },
    },
  },
  {
    path: 'admin-settings',
    component: AdminSettingsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      requiredPermission: {
        PermissionType: 'WRITE',
        Resource: 'Portal Settings',
      },
    },
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
