import { DateRange } from '@app/models/date-range.model'

export function formatDateToString(
  date: Date,
  hyphen: boolean = false
): string {
  const year = date.getFullYear();
  let separator = '/';
  if (hyphen) {
    separator = '-';
  }
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${month}${separator}${day}${separator}${year}`;
}

export function convertToUSDateFormatWithTime12H(isoDate: string) {
  const date = new Date(isoDate);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  let period = 'AM';
  if (hours >= 12) {
    period = 'PM';
  }
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  return `${month}/${day}/${year} ${hours}:${minutes} ${period}`;
}

export function formatDate(date: Date): string {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

export function getDateSixMonthsAgo(): Date {
  const date = new Date();
  date.setMonth(date.getMonth() - 6);
  return date;
}

export function getQuarterDateRange(year: number, quarter: number): DateRange {
  const startMonth = quarter * 3;
  return {
    startDate: new Date(year, startMonth, 1).toISOString(),
    endDate: new Date(year, startMonth + 3, 0).toISOString()
  };
}

export function calculateDateRange(option: string): DateRange {
  const now = new Date();
  let startDate: Date;
  let endDate: Date = new Date();

  switch (option) {
    case "All Dates":
      startDate = new Date(1970, 0, 1);
      break;
    case "Last 7 Days":
      startDate = new Date(now.setDate(now.getDate() - 7));
      break;
    case "Last 30 Days":
      startDate = new Date(now.setDate(now.getDate() - 30));
      break;
    case "Last 90 Days":
      startDate = new Date(now.setDate(now.getDate() - 90));
      break;
    case "Last 6 Months":
      startDate = new Date(now.setMonth(now.getMonth() - 6));
      break;
    case "Last 12 Months":
      startDate = new Date(now.setMonth(now.getMonth() - 12));
      break;
    case "Month to Date":
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      break;
    case "Quarter to Date":
      const quarter = Math.floor((now.getMonth() / 3));
      startDate = new Date(now.getFullYear(), quarter * 3, 1);
      break;
    case "Year to Date":
      startDate = new Date(now.getFullYear(), 0, 1);
      break;
    default:
      return { startDate: null, endDate: null };
  }

  return { startDate: formatDate(startDate), endDate: formatDate(endDate) };
}

export function formatDateToDDMMYYYY(date: Date) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  let year = date.getFullYear();

  day = day.length < 2 ? '0' + day : day;
  month = month.length < 2 ? '0' + month : month;

  return `${month}-${day}-${year}`;
}
