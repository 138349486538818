import { Inject, Injectable } from "@angular/core";
import { ModeToggle } from '../models/mode-toggle.model';
import { BehaviorSubject, Observable } from "rxjs";
import { ModeStorage, MODE_STORAGE_SERVICE } from "./mode-storage.service";
import { DOCUMENT } from "@angular/common";


/**
 * Angular service that provides the mode toggle feature.
 * In summary this service adds the `class='light'` to the document.body element and
 * styles change based on the class added to the document.body
 *
 * Also provides a Observable that emits the current mode every time mode changes
 */
@Injectable({
  providedIn: 'root'
})
export class ModeToggleService {
  /**
   * contains the current active mode
   * avoid mutating it directly, instead use `updateCurrentMode`
   */
  private currentMode: ModeToggle = ModeToggle.LIGHT;

  /**
   * BehaviorSubject that detects the mode changes
   */
  private modeChangedSubject = new BehaviorSubject(this.currentMode);

  /**
   * Observable that emits the current mode when mode changes.
   * Exposed publicly so that other components can use this feature
   */
  modeChanged$: Observable<ModeToggle>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MODE_STORAGE_SERVICE) private modeStorage: ModeStorage
  ) {
    this.modeChanged$ = this.modeChangedSubject.asObservable();
    this.init();
  }

  public updateCurrentMode(mode: ModeToggle) {
    this.currentMode = mode;
    this.modeChangedSubject.next(this.currentMode);
    this.modeStorage.save(this.currentMode);
  }

  /**
   * Init function that update the application based on the initial mode value
   * Flow as below
   * 1 - If there is a saved mode in the browser - use this as the initial value
   * 2 - If there is no saved mode, Check for the preferred device theme
   * 3 - If device theme is dark, set the init value to `dark` 
   * 4 - Else set the default value to `light`
   */

  private init() {
    const deviceMode = window.matchMedia("(prefers-color-scheme: dark)");
    let initMode = this.modeStorage.get();
    if (!initMode) {
      deviceMode.matches ? (initMode = ModeToggle.DARK) : (initMode = ModeToggle.LIGHT);
    }
    this.updateCurrentMode(initMode);
    this.document.body.classList.add(this.currentMode);
  }

  /**
   * Function that toggles the mode
   * Exposed publicly
   */
  toggleMode() {
    this.document.body.classList.toggle(ModeToggle.LIGHT);
    this.document.body.classList.toggle(ModeToggle.DARK);
    if (this.currentMode === ModeToggle.LIGHT) {
      this.updateCurrentMode(ModeToggle.DARK);
    } else {
      this.updateCurrentMode(ModeToggle.LIGHT);
    }
  }

}
