import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as SidenavConfigActions from './sidenav.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class SidenavConfigEffects {
  saveSidenavConfigToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SidenavConfigActions.saveSidenavConfig),
        tap((action) => {
          localStorage.setItem(
            'sidenavConfigData',
            JSON.stringify(action.sidenavConfigData)
          );
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
