<div class="login-container">
  <mat-card class="login-card">
    <mat-card-header>
      <mat-card-title>Login to Customer Portal</mat-card-title>
      <mat-card-subtitle>Enter your login credentials below.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput type="text" />
          <mat-error
            *ngIf="
              loginForm.get('email')?.hasError('required') &&
              (loginForm.get('email')?.touched || loginForm.invalid)
            "
          >
            Email is required
          </mat-error>
          <mat-error
            *ngIf="
              loginForm.get('email')?.hasError('invalidEmail') &&
              !loginForm.get('email')?.hasError('required') &&
              loginForm.get('email')?.touched
            "
          >
            Please enter a valid email
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Password</mat-label>
          <input
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            matInput
            type="password"
          />
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
            hidePassword ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          <a [routerLink]="'/forgot-password'" class="forgot-password"
            >Forgot your password?</a
          >
          <mat-error
            *ngIf="
              loginForm.get('password')?.hasError('required') &&
              (loginForm.get('password')?.touched || loginForm.invalid)
            "
          >
            Password is required
          </mat-error>
          <mat-error
            *ngIf="
              loginForm.get('password')?.hasError('pattern') &&
              loginForm.get('password')?.touched
            "
          >
            Password must have at least 8 characters, 1 upper case, 1 lower
            case, 1 number, and 1 special character
          </mat-error>
        </mat-form-field>
        <div class="button-container">
          <app-button class="action-button" [label]="'Login'" [type]="'submit'">
          </app-button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
