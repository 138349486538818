<div class="table-header">
  <div class="title-container">
    <span class="title">Branding</span>
  </div>
</div>
<div class="company-name-container">
  <div class="info-container">
    <h5 class="container-title">Company Name</h5>
    <p class="info-text">
      Enter the company name as it should appear throughout the platform
    </p>
    <form class="branding-form" [formGroup]="brandingForm">
      <mat-dialog-content class="form-container">
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Display Name</mat-label>
          <input
            class="company-name-input"
            matInput
            type="text"
            formControlName="displayName"
            (ngModelChange)="onDisplayNameChanged()"
          />
        </mat-form-field>
        <mat-error
          *ngIf="
            brandingForm.get('displayName')?.hasError('required') &&
            brandingForm.get('displayName')?.touched
          "
        >
          This field is required
        </mat-error>
        <mat-error
          *ngIf="brandingForm.get('displayName')?.hasError('maxlength')"
        >
          Maximum 25 characters allowed
        </mat-error>
        <mat-error *ngIf="brandingForm.get('displayName')?.hasError('pattern')">
          Only supports letters, numbers, and the special characters ,'-&+:;.
        </mat-error>
      </mat-dialog-content>
    </form>
  </div>
</div>

<div class="logo-container">
  <div class="info-container">
    <h5 class="container-title">Logo</h5>
    <p class="info-text">
      Image file should be square and at least 100x100 pixels. If you set a
      brand color, make sure the logo uses the same background color as the
      Brand Color or is transparent. Acceptable file types: jpeg, gif, png, svg
    </p>
    <div class="file-upload-container">
      <div class="preview-and-delete-container">
        <!-- Image Preview -->
        <div class="preview-container">
          <img
            *ngIf="previewUrl"
            [src]="previewUrl"
            alt="Image preview"
            class="image-preview"
          />
          <mat-icon *ngIf="!previewUrl" class="block-icon">block</mat-icon>
        </div>
        <!-- Delete Button -->
        <button
          *ngIf="previewUrl"
          mat-icon-button
          class="text-icon-align delete-button"
          (click)="onDeleteImage()"
        >
          <span class="material-symbols-outlined delete-button menu-icon">
            delete
          </span>
          Delete
        </button>
      </div>

      <!-- Drag & Drop -->
      <div
        class="drag-drop-container"
        (click)="fileInput.click()"
        (drop)="onDrop($event)"
        (dragover)="onDragOver($event)"
      >
        <div class="icon-text-container">
          <input
            type="file"
            #fileInput
            (change)="onFileSelected($event)"
            style="display: none"
            accept="image/*"
          />
          <mat-icon class="cloud-upload-icon">cloud_upload</mat-icon>
          <div class="drag-drop-text-container">
            <p class="drag-drop-main-text">Drag & Drop Image</p>
            <small class="drag-drop-secondary-text">or Select a File...</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="brand-color-container">
  <div class="info-container">
    <h5 class="container-title">Brand Color</h5>
    <p class="info-text">
      Select your brand color to be used in the header of the platform
    </p>
    <div class="color-picker-container">
      <div
        class="color-circle"
        ngx-colors-trigger
        [ngStyle]="{ 'background-color': selectedColor }"
        [(ngModel)]="selectedColor"
      ></div>
      <span class="color-code-selected"
        >{{ selectedColor | uppercase }}
        {{ selectedColor === '#000000' ? '(default)' : '' }}</span
      >
    </div>
  </div>
</div>

<div class="action-buttons">
  <app-button
    mat-button
    (click)="onSave()"
    [label]="'Save Changes'"
    [icon]="'check_circle_outline'"
    [isDisabled]="isOnSaveDisabled()"
  ></app-button>
</div>
