import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';

import { SharedService } from '@app/services/shared.service';
import { PermissionService } from '@app/services/permission.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
})
export class SidenavListComponent implements OnInit {
  @Input() companyId: string = '';
  @Input() isExpanded!: boolean;
  @Output() toggleMenu = new EventEmitter();

  @ViewChild('floatingMenu', { static: false }) floatingMenu!: ElementRef;
  @ViewChild('menuItemText', { static: false }) menuItemText!: ElementRef;
  @ViewChild('subMenuList', { static: false }) subMenuList!: ElementRef;

  isSettingsExpanded = false;
  settingsRoutes = [
    '/shippers',
    '/internal-users',
    '/branding',
    'admin-settings',
  ];
  floatingMenuId = 'floatingMenu';
  settingsSubMenu = [
    { path: '/shippers', name: 'Shippers' },
    { path: '/internal-users', name: 'Internal Users' },
    { path: '/branding', name: 'Branding' },
    { path: '/admin-settings', name: 'Admin Settings' },
  ];
  settingsMenu = [
    { path: '/home', name: 'Dashboard', icon: 'home' },
    { path: '/order/history', name: 'Orders', icon: 'receipt' },
    //{ path: '/analytics', name: 'Analytics', icon: 'equalizer' },
    { path: '/users', name: 'Users', icon: 'person' },
  ];
  iconName!: string;
  currentPath: string = '';
  selectedListItem: ElementRef | null = null;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    if (
      this.settingsRoutes.some((route) =>
        window.location.pathname.includes(route)
      )
    ) {
      this.isSettingsExpanded = true;
    }

    this.sharedService.companyIdObservable().subscribe((companyId: string) => {
      this.companyId = companyId;
    });
  }

  onSettingsClick(event: Event): void {
    event.stopPropagation();
    this.isSettingsExpanded = true;
    this.toggleMenu.emit(null);
  }

  onSettingsHeaderClick(): void {
    this.isSettingsExpanded = !this.isSettingsExpanded;
    this.hideFloatingMenu();
    if (!this.isExpanded) {
      this.toggleMenu.emit(null);
    }
  }

  isSelected(route: string, isSettings: boolean = false): boolean {
    if (isSettings && this.router.url.includes(route)) {
      return true;
    }
    return this.router.url === route;
  }

  collapseSettings(): void {
    this.isSettingsExpanded = false;
  }

  showFloatingMenu(
    event: MouseEvent,
    icon: string,
    menuItem: string,
    subMenus: { path: string; name: string }[] = []
  ): void {
    if (!this.isExpanded) {
      this.hideFloatingMenu();
      this.iconName = icon;

      const floatingMenu = this.floatingMenu.nativeElement;
      const menuItemTextElement = this.menuItemText.nativeElement;
      const subMenuListElement = this.subMenuList.nativeElement;

      this.prepareMenuDisplay(
        event,
        floatingMenu,
        menuItem,
        menuItemTextElement,
        subMenuListElement,
        subMenus
      );
      this.prepareSubMenus(subMenuListElement, subMenus);
    }
  }

  prepareMenuDisplay(
    event: MouseEvent,
    floatingMenu: any,
    menuItem: string,
    menuItemTextElement: any,
    subMenuListElement: any,
    subMenus: { path: string; name: string }[]
  ): void {
    const rect = (event.target as Element).getBoundingClientRect();
    this.renderer.setProperty(menuItemTextElement, 'innerText', menuItem);
    this.renderer.setProperty(subMenuListElement, 'innerHTML', '');

    if (subMenus.length > 0) {
      this.renderer.setStyle(floatingMenu, 'height', '248px');
    } else {
      this.renderer.removeStyle(floatingMenu, 'height');
    }

    this.renderer.setStyle(floatingMenu, 'top', `${rect.top}px`);
    this.renderer.setStyle(floatingMenu, 'display', 'block');

    const menu = this.settingsMenu.find((item) => item.name === menuItem);
    if (menu) {
      this.currentPath = menu.path;
    } else {
      this.currentPath = '';
    }
  }

  prepareSubMenus(
    subMenuListElement: any,
    subMenus: { path: string; name: string }[]
  ): void {
    subMenus.forEach((subMenu) => {
      const listItem = this.renderer.createElement('li');
      const isSelected = this.router.url.includes(subMenu.path);

      if (isSelected) {
        this.renderer.addClass(listItem, 'selected');
        this.selectedListItem = listItem;
      }

      this.renderer.listen(listItem, 'click', () => {
        this.selectListItem(listItem, subMenu.path);
      });

      const subMenuText = this.renderer.createText(subMenu.name);
      this.renderer.appendChild(listItem, subMenuText);
      this.renderer.appendChild(subMenuListElement, listItem);
    });
  }

  selectListItem(listItem: any, path: string): void {
    this.currentPath = path;
    this.router.navigate([path]);

    if (this.selectedListItem) {
      this.renderer.removeClass(this.selectedListItem, 'selected');
    }

    this.renderer.addClass(listItem, 'selected');
    this.selectedListItem = listItem;
  }

  hideFloatingMenu(): void {
    if (!this.isExpanded) {
      const floatingMenu = this.floatingMenu.nativeElement;
      this.renderer.setStyle(floatingMenu, 'display', 'none');
    }
  }

  navigateToCurrentPath(): void {
    if (this.currentPath) {
      this.router.navigate([this.currentPath]);
    }
  }

  canWritePortalSettings(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Portal Settings');
  }

  canWriteShipperUsers(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Shipper Users');
  }

  shouldShowMenuItem(menuItemName: string): boolean {
    return menuItemName !== 'Users' || this.canWriteShipperUsers();
  }
}
