import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

import { UserElement } from '@app/models/user.model';
import { NewUserModalComponent } from '@app/components/shippers/new-user-modal/new-user-modal.component';
import { ToasterService } from '@app/services/toaster.service';
import { NewShipperModalComponent } from '@app/components/shippers/new-shipper-modal/new-shipper-modal.component';
import { ShipperService } from '@app/services/shipper.service';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';
import { environment } from '@env/environment';

@Component({
  selector: 'app-shipper-detail',
  templateUrl: './shipper-detail.component.html',
  styleUrls: ['./shipper-detail.component.scss'],
})
export class ShipperDetailComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;

  searchValue: string = '';
  columnsToDisplay: string[] = ['firstName', 'email', 'role', 'lastLogin'];
  columnsToDisplayWithAvatar: string[] = [
    'avatar',
    ...this.columnsToDisplay,
    'menu',
  ];
  shipper!: any;
  shipperId!: string | null;
  users: any[] = [];
  isLoading: boolean = false;
  isLoadingShipper: boolean = false;
  carrierId: number = environment.carrierId;

  constructor(
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer,
    private toasterService: ToasterService,
    private dialog: MatDialog,
    private shipperService: ShipperService,
    private route: ActivatedRoute
  ) {
    this.shipperId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getShipper();
    this.fetchUsers();
  }

  fetchUsers(userDetails?: string): void {
    this.isLoading = true;
    if (this.shipperId) {
      this.shipperService
        .getShipperUsers(this.shipperId, userDetails)
        .subscribe({
          next: (response) => {
            this.users = response;
            this.isLoading = false;
          },
          error: (error) => {
            console.log(error);
            this.isLoading = false;
          },
        });
    }
  }

  handleSearchValueChange(value: string): void {
    this.searchValue = value;
  }

  getShipper(): void {
    this.isLoadingShipper = true;
    if (this.shipperId) {
      this.shipperService.getShipper(this.shipperId).subscribe({
        next: (response) => {
          this.isLoadingShipper = false;
          this.shipper = response;
        },
        error: (error) => {
          this.isLoadingShipper = false;
          console.log(error);
        },
      });
    }
  }

  onSubmit(): void {
    this.fetchUsers(this.searchValue);
  }

  goToShippersList(): void {
    this.router.navigate(['/shippers']);
  }

  announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getRoleUserToChange(role: string): string {
    if (role === 'shipper user') {
      return 'Admin';
    }
    return 'User';
  }

  openUserModal(editMode: boolean = false, user?: UserElement): void {
    const dialogRef = this.dialog.open(NewUserModalComponent, {
      width: '660px',
      data: {
        editMode,
        ...user,
        shipper: this.shipper ? this.shipper : null,
      },
      autoFocus: '.company-name-input',
      id: 'new-user',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchUsers();
      }
    });
  }

  openShipperModal(editMode: boolean = false): void {
    const dialogRef = this.dialog.open(NewShipperModalComponent, {
      width: '400px',
      data: {
        editMode,
        companyName: this.shipper.Name,
        billToCodes: this.shipper.BillTos,
      },
      autoFocus: '.company-name-input',
      id: 'new-shipper',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.editShipper(result, this.shipper);
      } else if (result === null) {
        this.confirmDeleteShipper();
      }
    });
  }

  confirmDeleteShipper(): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete the Shipper?',
      },
      id: 'confirm-dialog',
    });

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.shipperService.deleteCarrierShipper(this.shipperId!).subscribe({
          next: () => {
            this.toasterService.showSuccess(
              'The Shipper was deleted successfully'
            );
            this.router.navigate(['/shippers']);
          },
          error: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  editShipper(modifiedShipper: any, originalShipper: any): void {
    const request = {
      CarrierId: this.carrierId,
      Id: originalShipper.Id,
      Name: modifiedShipper.companyName,
      BillTos: modifiedShipper.billToCodes,
      LastModifiedBy: 'admin',
    };
    this.shipperService.createEditShipper(request).subscribe({
      next: () => {
        this.getShipper();
        this.toasterService.showSuccess('The Shipper was edited successfully');
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  onDeleteUser(user?: any): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete the User?',
      },
      id: 'confirm-dialog',
    });

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed && this.shipperId) {
        this.shipperService
          .deleteShipperUser(this.shipperId, user.ObjectId)
          .subscribe({
            next: () => {
              this.fetchUsers();
              this.toasterService.showSuccess(
                'The User was deleted successfully'
              );
            },
            error: (error) => {
              console.log(error);
            },
          });
      }
    });
  }

  changeRole(user: any): void {
    const request: any = {
      FirstName: user.FirstName,
      LastName: user.LastName,
      Email: user.Email,
      UserType:
        user.UserType === 'shipper user' ? 'shipper admin' : 'shipper user',
      ShipperId: this.shipper.Id,
      ObjectId: user.ObjectId,
      PrincipalName: user.PrincipalName,
    };
    this.shipperService.upsertShipperUser(request).subscribe({
      next: () => {
        this.toasterService.showSuccess('The Role was changed successfully');
        this.fetchUsers();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}
