import { Injectable, InjectionToken } from '@angular/core';
import { ModeToggle } from '../models/mode-toggle.model';

export const MODE_STORAGE_SERVICE = new InjectionToken<ModeStorage>(
  "MODE_STORAGE"
);

export interface ModeStorage {
  
  save(mode: ModeToggle): void;
  get(): ModeToggle;
}

@Injectable({
  providedIn: 'root'
})
export class ModeStorageService implements ModeStorage {

  LOCAL_STORAGE_KEY = "mode";

  save(mode: ModeToggle): void {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, mode.toString());
  }
  get(): ModeToggle {
    return <ModeToggle>localStorage.getItem(this.LOCAL_STORAGE_KEY) || undefined;
  }
}
