<div class="table-header">
  <div class="title-container">
    <span class="title">Orders</span>
    <div class="title-action-date-range-action" (click)="openDateRangeModal()">
      <mat-icon>calendar_month</mat-icon>
      <span class="title-action-text">{{selectedDropdownValue}}</span>
      <span class="dropdown-arrow"><mat-icon>keyboard_arrow_down</mat-icon></span>
    </div>
    <div class="title-action" (click)="openFiltersModal()">
      <fa-icon [icon]="slidersIcon" class="small-icon"></fa-icon>
      <span class="title-action-text">Filter</span>
    </div>
    <div class="title-action" (click)="downloadOrderHistoryCsv()">
      <fa-icon [icon]="excelIcon" class="small-icon"></fa-icon>
      <span class="title-action-text">Export CSV</span>
    </div>
  </div>
  <div class="search-container">
    <app-search-input
      [searchOptions]="searchOptions"
      [selectedSearchOption]="optionValue"
      label="Search Orders..."
      [(ngModel)]="searchValue"
      name="searchValue"
      (optionValue)="onOptionValueChange($event)"
      (searchTriggered)="onSubmit()"
    ></app-search-input>
  </div>
</div>
<div class="filter-boxes">
  <div class="filter-box" *ngFor="let filter of filters; let i = index">
    <span>{{ filter }}</span>
    <button class="remove-filter" (click)="removeBox(i)">
      <fa-icon [icon]="timesCircleIcon" class="small-icon"></fa-icon>
    </button>
  </div>
  <button *ngIf="filters.length" class="clear-all" (click)="clearAll(true)">
    Clear All
  </button>
</div>

<div class="table-overlay-container">
  <mat-table
    [dataSource]="orderHistoryDataSource"
    class="mat-elevation-z0 orders-table"
    matSort
    (matSortChange)="onSortChange($event)"
    [ngClass]="{ 'loading-table': isLoading }"
  >
    <ng-container matColumnDef="orderNumber">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header="orderNumber"
        class="header-align"
        >Order #</mat-header-cell
      >
      <mat-cell *matCellDef="let element" class="cell-align">{{
        element.OrderNumber
      }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header="status"
        >Status</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <div [ngSwitch]="element.Status">
          <div *ngSwitchCase="'Delivered'" class="flex-center">
            <mat-icon>check_circle</mat-icon> Delivered
          </div>
          <div *ngSwitchCase="'Available'" class="flex-center">
            <mat-icon>inbox</mat-icon> Available
          </div>
          <div *ngSwitchCase="'Planned'" class="flex-center">
            <mat-icon>event_available</mat-icon> Planned
          </div>
          <div *ngSwitchCase="'In Transit'" class="flex-center">
            <mat-icon>directions_bus</mat-icon> In Transit
          </div>
          <div *ngSwitchCase="'Cancelled'" class="flex-center">
            <mat-icon>cancel</mat-icon> Cancelled
          </div>
          <div *ngSwitchDefault class="flex-center">{{ element.Status }}</div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="referenceNumber">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header="referenceNumber"
        class="header-align"
        >Reference #</mat-header-cell
      >
      <mat-cell *matCellDef="let element" class="cell-align">
        {{ element.ReferenceNum }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="pickupName">
      <mat-header-cell *matHeaderCellDef mat-sort-header="pickupName"
        >Pickup Name</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        {{ element.Shipper.Name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="pickupCity">
      <mat-header-cell *matHeaderCellDef mat-sort-header="pickupCity"
        >Pickup City</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        {{ element.Shipper.City | titlecase }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="pickupState">
      <mat-header-cell *matHeaderCellDef mat-sort-header="pickupState"
        >Pickup State</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        {{ element.Shipper.State }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="consigneeName">
      <mat-header-cell *matHeaderCellDef mat-sort-header="consigneeName"
        >Consignee Name</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        {{ element.Consignee.Name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="consigneeCity">
      <mat-header-cell *matHeaderCellDef mat-sort-header="consigneeCity"
        >Consignee City</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        {{ element.Consignee.City | titlecase }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="consigneeState">
      <mat-header-cell *matHeaderCellDef mat-sort-header="consigneeState"
        >Consignee State</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        {{ element.Consignee.State }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="billTo">
      <mat-header-cell *matHeaderCellDef mat-sort-header="billTo"
        >Bill To</mat-header-cell
      >
      <mat-cell *matCellDef="let element"> {{ element.BillTo.Name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header="startDate">Ship Date</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.StartDate | date }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header="endDate">Delivery Date</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.CompletionDate | date }}
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="orderHistoryDisplayedColumns"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: orderHistoryDisplayedColumns"
      (click)="onRowClick(row)"
    ></mat-row>
  </mat-table>
  <!-- No records message -->
  <div
    *ngIf="!isLoading && orderHistoryDataSource.data.length === 0"
    class="no-records-found"
  >
    <p>No records found.</p>
  </div>
  <!-- Spinner -->
  <div *ngIf="isLoading" class="spinner-overlay">
    <mat-progress-spinner
      mode="indeterminate"
      [diameter]="30"
      class="spinner"
    ></mat-progress-spinner>
  </div>
  <!-- Paginator -->
  <mat-paginator
    showFirstLastButtons
    [pageSize]="pageSize"
    [length]="paginationParams.total"
    [pageIndex]="pageEvent.pageIndex"
    (page)="onPageChange($event)"
    [pageSizeOptions]="pageSizeOptions"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
