<div class="table-header">
  <div class="title-container">
    <span class="title">Shippers</span>
    <!-- New Shipper Button -->
    <app-button
      class="action-button"
      [label]="'New Shipper'"
      [icon]="'local_shipping'"
      (buttonClick)="onNewShipperClick()"
    >
    </app-button>
    <!-- New User Button -->
    <app-button
      class="action-button"
      [label]="'New User'"
      [icon]="'person'"
      (buttonClick)="addNewUserModal()"
    >
    </app-button>
  </div>
  <div class="search-container">
    <app-search-input
      label="Search Shippers and Users..."
      [(ngModel)]="searchValue"
      name="searchValue"
      (searchTriggered)="onSubmit()"
      (searchValue)="handleSearchValueChange($event)"
    ></app-search-input>
  </div>
</div>
<div class="table-overlay-container">
  <table
    mat-table
    [dataSource]="shippers"
    matSort
    (matSortChange)="announceSortChange($event)"
    multiTemplateDataRows
    class="shippers-table"
    [ngClass]="{ 'loading-table': isLoading }"
  >
    <ng-container matColumnDef="shipper">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Shipper</th>
      <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
    </ng-container>

    <ng-container matColumnDef="admins">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Admins</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.RoleCount['shipper admin']
            ? element.RoleCount['shipper admin']
            : 0
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Users</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.RoleCount['shipper user']
            ? element.RoleCount['shipper user']
            : 0
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalUsers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Users</th>
      <td mat-cell *matCellDef="let element">{{ element.TotalCount }}</td>
    </ng-container>

    <!-- Menu Column -->
    <ng-container matColumnDef="menu">
      <th
        mat-header-cell
        *matHeaderCellDef
        aria-label="row actions"
        class="menu-column"
      >
        &nbsp;
      </th>
      <td mat-cell *matCellDef="let element" class="menu-column">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="table-menu">
          <button
            mat-menu-item
            class="text-icon-align"
            (click)="openShipperModal(true, element)"
          >
            <span class="material-symbols-outlined menu-icon">
              edit_square
            </span>
            Edit
          </button>
          <button
            mat-menu-item
            class="text-icon-align"
            (click)="addNewUserModal(element)"
          >
            <span class="material-icons menu-icon"> person </span>
            Add New User
          </button>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            class="text-icon-align delete-button"
            (click)="confirmDeleteShipper(element.Id)"
          >
            <span class="material-symbols-outlined delete-button menu-icon">
              delete
            </span>
            Delete Shipper
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          aria-label="expand row"
          (click)="
            expandedElement = expandedElement === element ? null : element;
            $event.stopPropagation()
          "
        >
          <mat-icon *ngIf="expandedElement !== element">arrow_right</mat-icon>
          <mat-icon *ngIf="expandedElement === element"
            >arrow_drop_down
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplayWithExpand.length"
      >
        <div
          class="shipper-element-detail"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <ng-container *ngIf="expandedElement === element">
            <!-- USERS LIST -->
            <app-shipper-users-list
              [shipper]="element"
            ></app-shipper-users-list>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplayWithExpand"
      class="shipper-element-row"
      [class.shipper-expanded-row]="expandedElement === element"
      (click)="onRowClick(element.Id)"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="shipper-detail-row"
    ></tr>
  </table>
  <!-- No records message -->
  <div *ngIf="!isLoading && shippers.length === 0" class="no-records-found">
    <p>No records found.</p>
  </div>
  <!-- Spinner -->
  <div *ngIf="isLoading" class="spinner-overlay">
    <mat-progress-spinner
      mode="indeterminate"
      [diameter]="30"
      class="spinner"
    ></mat-progress-spinner>
  </div>
</div>
