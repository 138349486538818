export interface IOrderHistory {
  OrderNumber: Number;
  Status: string;
  ReferenceNum: Number;
  StartDate: string;
  CompletionDate: string;
  Shipper: {
    Name: string;
    City: string;
    State: string;
  };
  BillTo: {
    Name: string;
  };
  Consignee: {
    Name: string;
    City: string;
    State: string;
  };
  FreightDetails?: any;
  Stops?: any;
  Docs?: any;
  Checkcalls?: any;
}

export interface IOrderStatusCount {
  Delivered?: number;
  Planned?: number;
  Available?: number;
  'In Transit'?: number;
}

export enum SearchOrdersOptions {
  OrderNumber = 'Order #',
  ReferenceNumber = 'Reference #',
  PickupName = 'Pickup Name',
  PickupCity = 'Pickup City',
  ConsigneeName = 'Consignee Name',
  ConsigneeCity = 'Consignee City',
}
