import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { ToasterService } from '@app/services/toaster.service';
import { AppConfig } from '@app/app.config';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, this.emailValidator]],
    });
  }

  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const emailRegex = AppConfig.emailPattern;
    if (!control.value.match(emailRegex)) {
      return { invalidEmail: true };
    }
    return null;
  }

  onSubmit(): void {
    if (this.forgotPasswordForm.valid) {
      this.router.navigate(['/reset-password']);
      this.toasterService.showSuccess(
        'Success! You will receive an email to reset your password if an account exists for the email address provided',
        6000
      );
    }
  }
}
