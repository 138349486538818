import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import {
  IOrderHistory,
  IOrderStatusCount,
} from '@app/models/orderHistory.models';
import { OrderService } from '@app/services/order.service';
import { SharedService } from '@app/services/shared.service';
import { environment } from '@env/environment';
import { getDateSixMonthsAgo } from '../../shared/helpers/date.helper';
import { OrderHistoryDaterangeModalComponent } from '../order-history/order-history-daterange-modal/order-history-daterange-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderHistoryDateFilters } from '@app/models/orderHistoryDateFilters.model'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<IOrderHistory>;
  isLoading: boolean = false;
  isStatusCountLoading: boolean = false;
  columnsToDisplay: string[] = [
    'OrderNumber',
    'Status',
    'ReferenceNum',
    'Shipper.Name',
    'Shipper.City',
    'Shipper.State',
    'Consignee.Name',
    'Consignee.City',
    'Consignee.State',
    'BillTo.Name',
    'StartDate',
    'CompletionDate',
  ];

  statusCount: IOrderStatusCount = {
    Delivered: 0,
    Planned: 0,
    Available: 0,
    'In Transit': 0,
  };
  companyId: string = '';
  private destroy$ = new Subject<void>();
  isDateRangeModalOpen: boolean = false;
  isExpanded: boolean = true;
  dateRangeDialogRef: MatDialogRef<OrderHistoryDaterangeModalComponent> | null = null;
  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;
  selectedDropdownValue: string = 'Last 6 Months';
  defaultStartDate = getDateSixMonthsAgo();
  defaultEndDate = new Date();
  defaultDropDown = 'Last 6 Months';

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private orderService: OrderService,
    private sharedService: SharedService
  ) {
    this.dataSource = new MatTableDataSource<IOrderHistory>();
  }

  ngOnInit(): void {
    localStorage.removeItem('orderHistoryPage');
    this.sharedService
      .companyIdObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyId: string) => {
        const trimmedCompanyId = companyId.replace(/\s+/g, '');
        if (this.companyId !== trimmedCompanyId) {
          this.companyId = trimmedCompanyId;
          const dateFilters = this.retrieveOrderHistoryDateFilters();
          this.fetchOrderHistory(dateFilters?.startDate ?? null, dateFilters?.endDate ?? null);
          this.fetchOrderStatusCounts(this.getOrderStatusRequest(), dateFilters?.startDate ?? null, dateFilters?.endDate ?? null);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchOrderStatusCounts(request: any, startDate: Date | null, endDate: Date | null): void {
    this.isStatusCountLoading = true;
    
    if (this.selectedDropdownValue !== 'Custom') {
      endDate = null; 
    }

    this.orderService.getOrderStatusCounts(request, startDate, endDate).subscribe(
      (response: IOrderStatusCount) => {
        this.statusCount = response;
        this.isStatusCountLoading = false;
      },
      (error) => {
        this.isStatusCountLoading = false;
        console.log(error);
      }
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  navigateToOrder(status: string) {
    this.router.navigate(['order/history'], {
      queryParams: { status },
    });
  }

  onRowClick(row: IOrderHistory): void {
    const orderNumber: number = Number(row.OrderNumber);
    this.router.navigate(['/orders', orderNumber]);
  }

  getOrderStatusRequest(): any {
    const carrierId = environment.carrierId;
    const request = {
      companyIds: this.companyId,
      carrierId: carrierId,
    };

    return request;
  }

  openDateRangeModal(): void {
    if (!this.isDateRangeModalOpen) {
      this.isDateRangeModalOpen = true;

      const panelClass = this.isExpanded
        ? 'orders-dateRange-modal-menu-expanded'
        : 'orders-dateRange-modal-menu-no-expanded';

      this.dateRangeDialogRef = this.dialog.open(OrderHistoryDaterangeModalComponent, {
        width: '415px',
        autoFocus: '.pickup-state-input',
        data: {
          startDate: this.selectedStartDate ? new Date(this.selectedStartDate) : new Date(this.defaultStartDate),
          endDate: this.selectedEndDate ? new Date(this.selectedEndDate) : new Date(this.defaultEndDate),
          selectedDropdown: this.selectedDropdownValue ? this.selectedDropdownValue : this.defaultDropDown
        },
        id: 'order-history-daterange',
        hasBackdrop: false,
        panelClass,
      });

      this.dateRangeDialogRef.afterClosed().subscribe(result => {
        this.isDateRangeModalOpen = false;
        if (result) {
          this.selectedStartDate = result.startDate;
          this.selectedEndDate = result.endDate;
          this.selectedDropdownValue = result.selectedDropdown;
          this.fetchOrderHistory(result.startDate, result.endDate);
          this.fetchOrderStatusCounts(this.getOrderStatusRequest(), result.startDate, result.endDate);
          this.saveFilterState();
        }
      });
    }
  }

  fetchOrderHistory(startDate: Date | null, endDate: Date | null) {
    this.isLoading = true;
    const request = {
      companyIds: this.companyId,
      page: 1,
      pageSize: 5,
    };

    if (this.selectedDropdownValue !== 'Custom') {
      endDate = null; 
    }

    this.orderService.getOrderHistory(request, startDate, endDate).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        this.dataSource.data = data.Orders;
      },
      error: (error) => {
        this.isLoading = false;
        console.error('Error fetching sorted order history:', error);
      },
    });
  }

  saveFilterState() {
    const state = {
      selectedStartDate: this.selectedStartDate,
      selectedEndDate: this.selectedEndDate,
      dropDownValue: this.selectedDropdownValue,
    };
    sessionStorage.setItem('orderHistoryDateFilters', JSON.stringify(state));
  }

  retrieveOrderHistoryDateFilters(): OrderHistoryDateFilters | null {
    const filterStateString = sessionStorage.getItem('orderHistoryDateFilters');
    if (!filterStateString) {
      const defaultOrderHistoryDateFilters: OrderHistoryDateFilters = {
        startDate: this.defaultStartDate,
        endDate: this.defaultEndDate,
        dropDownValue: 'Last 6 Months',
      };
      return defaultOrderHistoryDateFilters;
    } else {
      const sessionFilters = JSON.parse(filterStateString);
      const startDate = new Date(sessionFilters.selectedStartDate);
      const endDate = new Date(sessionFilters.selectedEndDate);
      this.selectedDropdownValue = sessionFilters.dropDownValue;

      const orderHistoryDateFilters: OrderHistoryDateFilters = {
        startDate: startDate,
        endDate: endDate,
        dropDownValue: sessionFilters.dropDownValue,
      };
      return orderHistoryDateFilters;
    }
  }
}
