import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { NewShipperModalComponent } from '@app/components/shippers/new-shipper-modal/new-shipper-modal.component';
import { ShipperListElement } from '@app/models/shipper.model';
import { NewUserModalComponent } from '@app/components/shippers/new-user-modal/new-user-modal.component';
import { ToasterService } from '@app/services/toaster.service';
import { ShipperService } from '@app/services/shipper.service';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';
import { environment } from '@env/environment';

@Component({
  selector: 'app-shippers',
  templateUrl: './shippers.component.html',
  styleUrls: ['./shippers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ShippersComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  columnsToDisplay: string[] = ['shipper', 'admins', 'users', 'totalUsers'];
  columnsToDisplayWithExpand: string[] = [
    'expand',
    ...this.columnsToDisplay,
    'menu',
  ];
  expandedElement!: ShipperListElement | null;
  shippers: ShipperListElement[] = [];
  columnNames: { [key: string]: string } = {
    shipper: 'Shipper',
    admins: 'Admins',
    users: 'Users',
    totalUsers: 'Total Users',
  };
  searchValue: string = '';
  isLoading: boolean = false;
  carrierId: number = environment.carrierId;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private router: Router,
    private toasterService: ToasterService,
    private shipperService: ShipperService
  ) {}

  ngOnInit(): void {
    this.fetchShippers();
    this.shipperService.notifyFetchShippers$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.fetchShippers();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleSearchValueChange(value: string): void {
    this.searchValue = value;
  }

  fetchShippers(shipperDetails?: string): void {
    this.isLoading = true;
    this.shipperService.getShippers(this.carrierId, shipperDetails).subscribe({
      next: (response) => {
        this.shippers = response.sort((a, b) => a.Name.localeCompare(b.Name));
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      },
    });
  }

  announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  onNewShipperClick(): void {
    const dialogRef = this.dialog.open(NewShipperModalComponent, {
      width: '400px',
      data: { companyName: '', billToCodes: '' },
      autoFocus: '.company-name-input',
      id: 'new-shipper',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.createShipper(result);
      }
    });
  }
  createShipper(result: any): void {
    const request = {
      CarrierId: this.carrierId,
      Name: result.companyName,
      BillTos: result.billToCodes,
      LastModifiedBy: 'admin',
    };
    this.shipperService.createEditShipper(request).subscribe({
      next: () => {
        this.fetchShippers();
        this.toasterService.showSuccess('The Shipper was created successfully');
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  addNewUserModal(shipper: any = null): void {
    const dialogRef = this.dialog.open(NewUserModalComponent, {
      width: '660px',
      data: {
        editMode: false,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        shipper,
        role: null,
      },
      autoFocus: '.company-name-input',
      id: 'new-user',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchShippers();
    });
  }

  openShipperModal(
    editMode: boolean = false,
    shipper: ShipperListElement
  ): void {
    this.shipperService.getShipper(shipper.Id!).subscribe({
      next: (res) => {
        const shipper = res;
        const dialogRef = this.dialog.open(NewShipperModalComponent, {
          width: '400px',
          data: {
            editMode,
            companyName: shipper.Name,
            billToCodes: shipper.BillTos,
          },
          autoFocus: '.company-name-input',
          id: 'new-shipper',
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.editShipper(result, shipper);
          } else if (result === null) {
            this.confirmDeleteShipper(shipper.Id);
          }
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  confirmDeleteShipper(shipperId: string): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete the Shipper?',
      },
      id: 'confirm-dialog',
    });

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.shipperService.deleteCarrierShipper(shipperId).subscribe({
          next: () => {
            this.toasterService.showSuccess(
              'The Shipper was deleted successfully'
            );
            this.fetchShippers();
          },
          error: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  editShipper(modifiedShipper: any, originalShipper: ShipperListElement): void {
    const request = {
      CarrierId: this.carrierId,
      Id: originalShipper.Id,
      Name: modifiedShipper.companyName,
      BillTos: modifiedShipper.billToCodes,
      LastModifiedBy: 'admin',
    };
    this.shipperService.createEditShipper(request).subscribe({
      next: () => {
        this.fetchShippers();
        this.toasterService.showSuccess('The Shipper was edited successfully');
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  onSubmit(): void {
    this.fetchShippers(this.searchValue);
  }

  onRowClick(id: number): void {
    this.router.navigate([`/shippers/detail/${id}`]);
  }
}
