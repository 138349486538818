<div class="grid-container">
  <div
    class="summary-rectangle"
    [ngClass]="{ expanded: isExpanded, collapsed: !isExpanded }"
  >
    <div class="left-content">
      <div class="flex-container">
        <div class="flex-item available" [ngStyle]="getStatusStyles('Available')">
          Available
        </div>
        <div class="flex-item planned" [ngStyle]="getStatusStyles('Planned')">
          Planned
        </div>
        <div class="flex-item in-transit" [ngStyle]="getStatusStyles('In Transit')">
          In Transit
        </div>
        <div class="flex-item delivered" [ngStyle]="getStatusStyles('Delivered')">
          Delivered
        </div>
      </div>
      <div class="list-columns">
        <div class="list-column-one">
          <div class="custom-table">
            <table>
              <tr>
                <th>Order #</th>
                <td>{{ orderDetails?.OrderNumber }}</td>
              </tr>
              <tr>
                <th>Reference #</th>
                <td>{{ orderDetails?.ReferenceNum }}</td>
              </tr>
              <tr>
                <th>Weight</th>
                <td>
                  {{ orderDetails?.FreightDetails?.Weight }}
                  {{ orderDetails?.FreightDetails?.WeightUnit }}
                </td>
              </tr>
              <tr>
                <th>Scheduled Pickup</th>
                <td>{{ orderDetails?.ScheduledPickupDate }}</td>
              </tr>
              <tr>
                <th>Scheduled Delivery</th>
                <td>{{ orderDetails?.ScheduledDeliveryDate }}</td>
              </tr>
              <tr>
                <th>Miles</th>
                <td>{{ orderDetails?.TotalMiles }}</td>
              </tr>
              <tr>
                <th>Trailer</th>
                <td>{{ getTrailerIds() }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="list-column-two">
          <div class="custom-table">
            <table>
              <tr>
                <th>Master BOL</th>
                <td>{{ masterBOL }}</td>
              </tr>
              <tr>
                <th>SCAC Code</th>
                <td>{{ scacCode }}</td>
              </tr>
              <tr>
                <th>Bill of Lading</th>
                <td>{{ billofLanding }}</td>
              </tr>
              <tr>
                <th>Master PO#</th>
                <td>{{ masterPO }}</td>
              </tr>
              <tr>
                <th>Customer Order ID</th>
                <td>{{ customerOrderID }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="horizontal-line"></div>
      <div class="shipper-consignee-columns">
        <div class="shipper-table">
          <table>
            <tr>
              <td class="shipper-header">Shipper</td>
              <td>
                {{ orderDetails?.Shipper?.Name }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td>{{ orderDetails?.Shipper?.Address }}</td>
            </tr>
            <tr>
              <td></td>
              <td>
                {{
                  orderDetails?.Shipper?.City
                    ? orderDetails?.Shipper?.City + ','
                    : ''
                }}
                {{
                  orderDetails?.Shipper?.State
                    ? orderDetails?.Shipper?.State + ','
                    : ''
                }}
                {{ orderDetails?.Shipper?.Zip }}
              </td>
            </tr>
          </table>
        </div>
        <div class="consignee-table">
          <table>
            <tr>
              <td class="consignee-header">Consignee</td>
              <td>
                {{ orderDetails?.Consignee?.Name }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td>{{ orderDetails?.Consignee?.Address }}</td>
            </tr>
            <tr>
              <td></td>
              <td>
                {{
                  orderDetails?.Consignee?.City
                    ? orderDetails?.Consignee?.City + ','
                    : ''
                }}
                {{
                  orderDetails?.Consignee?.State
                    ? orderDetails?.Consignee?.State + ','
                    : ''
                }}
                {{ orderDetails?.Consignee?.Zip }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="right-content">
      <div class="map-container">
        <!-- Spinner -->
        <div *ngIf="isMapLoading" class="spinner-overlay">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="30"
            class="spinner"
          ></mat-progress-spinner>
        </div>
        <!-- Map -->
        <div *ngIf="showNoRouteMessage" class="no-route-message">
          Route Data is Not Yet Available
        </div>
        <div id="myMap" class="map"></div>
      </div>
    </div>
  </div>
</div>
