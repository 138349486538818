import { Component, Input, OnInit } from '@angular/core';
import { IDocument } from '@app/models/order.models';
import { OrderService } from '@app/services/order.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  pdfs = [
    { name: 'FileNameGoesHere.pdf', path: 'assets/dummy.pdf' },
    { name: 'AnotherFile.pdf', path: 'assets/test.pdf' },
    {
      name: 'VeryLongFileNameTruncatestLik...',
      path: 'assets/LongTestPDF.pdf',
    },
    { name: 'AnotherFile.pdf', path: 'assets/SecondTestPDF.pdf' },
    { name: 'AnotherFile.pdf', path: 'assets/LastDummyPDF.pdf' },
  ];
  @Input() orderNum: number = 0;

  documents: IDocument[] = [];
  isLoading: boolean = false;

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    this.fetchDocuments();
  }

  fetchDocuments(): void {
    this.isLoading = true;
    this.orderService.getDocuments(this.orderNum).subscribe({
      next: (res) => {
        this.documents = res;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      },
    });
  }

  openDocument(file: any) {
    const extension = file.Filepath.split('.').pop();
    const mimetype = this.getFileType(file.Filepath);
    this.orderService.getDocument(file.DocumentId).subscribe({
      next: (res) => {
        const blob = new Blob([res], { type: mimetype });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${ this.orderNum }-${ file.DocumentType }.${ extension }`;
        link.click();
        window.URL.revokeObjectURL(url);
      },
      error: (error) => console.log(error),
    });
  }

  getFileType(filepath: string): string {
    const extension = filepath.split('.').pop();

    let mimeType = '';
    switch (extension?.toLowerCase()) {
      case 'tif':
      case 'tiff':
        mimeType = 'image/tiff';
        break;
      case 'jpg':
      case 'jpeg':
        mimeType = 'image/jpeg';
        break;
      case 'png':
        mimeType = 'image/png';
        break;
      case 'gif':
        mimeType = 'image/gif';
        break;
      case 'bmp':
        mimeType = 'image/bmp';
        break;
      case 'pdf':
        mimeType = 'application/pdf';
        break;
      case 'txt':
        mimeType = 'text/plain';
        break;
      case 'doc':
      case 'docx':
        mimeType = 'application/msword';
        break;
      case 'xls':
      case 'xlsx':
        mimeType = 'application/vnd.ms-excel';
        break;
      default:
        mimeType = 'application/octet-stream';
    }
    return mimeType;
  }
}
