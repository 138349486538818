import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-navigation-dialog',
  templateUrl: './navigation-dialog.component.html',
  styleUrls: ['./navigation-dialog.component.scss']
})
export class NavigationDialogComponent {
  cancelButtonText = 'Cancel';
  confirmButtonText = 'Leave Page';
  message!: string;

  constructor(
    public dialogRef: MatDialogRef<NavigationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data.message;
    if (data.cancelButtonText) {
      this.cancelButtonText = data.cancelButtonText;
    }
    if (data.confirmButtonText) {
      this.confirmButtonText = data.confirmButtonText;
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
