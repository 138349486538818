import { createReducer, on } from '@ngrx/store';
import * as BrandingActions from './branding.actions';

export const initialState = {
  displayName: '',
  previewUrl: null,
  selectedColor: '#000000',
};

const _brandingReducer = createReducer(
  initialState,
  on(BrandingActions.saveBranding, (state, { brandingData }) => ({
    ...state,
    ...brandingData,
  })),
  on(BrandingActions.loadBranding, (state) => state)
);

export function brandingReducer(state: any, action: any) {
  return _brandingReducer(state, action);
}
