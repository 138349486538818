import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-new-shipper-modal',
  templateUrl: './new-shipper-modal.component.html',
  styleUrls: ['./new-shipper-modal.component.scss'],
})
export class NewShipperModalComponent implements OnInit {
  shipperForm!: FormGroup;
  editMode!: boolean;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  billToCodes: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<NewShipperModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.shipperForm = this.fb.group({
      companyName: [data.companyName, [Validators.required]],
      billToCodes: [null],
    });

    this.editMode = data.editMode;
    if (this.editMode) {
      this.billToCodes = this.convertStringToArray(data.billToCodes);
    }
  }

  ngOnInit(): void {}

  convertStringToArray(inputString: string): string[] {
    return inputString.split(',').map((item) => item.trim());
  }

  toUpper(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
    this.shipperForm.get('billToCodes')?.setValue(input.value.toUpperCase());
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const billToCodesString = this.billToCodes.join(',');
    const currentBillToCodes = this.shipperForm.get('billToCodes')?.value;
    const newCodesArray = billToCodesString
      .split(',')
      .map((str: string) => str.trim());
    const currentCodesArray = currentBillToCodes
      ? currentBillToCodes.split(',').map((str: string) => str.trim())
      : [];
    const newCodesSet = new Set(newCodesArray);
    const currentCodesSet = new Set(currentCodesArray);
    newCodesSet.forEach((code: string) => currentCodesSet.add(code));
    const combinedArray = Array.from(currentCodesSet);
    let combinedString = combinedArray.join(',');
    combinedString = combinedString.replace(/,$/, '');
    this.shipperForm.get('billToCodes')?.setValue(combinedString);
    this.dialogRef.close(this.shipperForm.value);
  }

  isOnSaveDisabled() {
    return !this.shipperForm.valid;
  }

  onDelete(): void {
    this.dialogRef.close(null);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    event.chipInput?.inputElement?.addEventListener(
      'keydown',
      (keydownEvent) => {
        if (keydownEvent.keyCode === ENTER) {
          keydownEvent.preventDefault();
        }
      }
    );

    if ((value || '').trim()) {
      this.billToCodes.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  remove(billToCode: string): void {
    const index = this.billToCodes.indexOf(billToCode);

    if (index >= 0) {
      this.billToCodes.splice(index, 1);
    }
  }
}
