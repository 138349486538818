<div class="reset-password-container">
  <mat-card class="reset-password-card">
    <mat-card-header>
      <mat-card-title>Reset your password</mat-card-title>
      <mat-card-subtitle>Create a new password below.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form
        [formGroup]="resetPasswordForm"
        (ngSubmit)="onSubmit()"
        class="reset-password-form"
      >
        <!-- Password -->
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Password</mat-label>
          <input
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            matInput
            type="password"
          />
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
            hidePassword ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          <mat-error
            *ngIf="
              resetPasswordForm.get('password')?.hasError('required') &&
              (resetPasswordForm.get('password')?.touched ||
                resetPasswordForm.invalid)
            "
          >
            Password is required
          </mat-error>
          <mat-error
            *ngIf="
              resetPasswordForm.get('password')?.hasError('pattern') &&
              resetPasswordForm.get('password')?.touched
            "
          >
            Password must have at least 8 characters, 1 upper case, 1 lower
            case, 1 number, and 1 special character
          </mat-error>
        </mat-form-field>
        <!-- Confirm Password -->
        <mat-form-field
          appearance="outline"
          class="input-field confirm-password-field"
        >
          <mat-label>Re-enter Password</mat-label>
          <input
            [type]="hideConfirmPassword ? 'password' : 'text'"
            formControlName="confirmPassword"
            matInput
            type="password"
          />
          <mat-icon
            matSuffix
            (click)="hideConfirmPassword = !hideConfirmPassword"
            >{{
              hideConfirmPassword ? 'visibility_off' : 'visibility'
            }}</mat-icon
          >
          <mat-error
            *ngIf="
              resetPasswordForm.get('confirmPassword')?.hasError('required') &&
              (resetPasswordForm.get('confirmPassword')?.touched ||
                resetPasswordForm.invalid)
            "
          >
            Password is required
          </mat-error>
          <mat-error
            *ngIf="
              resetPasswordForm.get('confirmPassword')?.hasError('pattern') &&
              resetPasswordForm.get('confirmPassword')?.touched
            "
          >
            Password must have at least 8 characters, 1 upper case, 1 lower
            case, 1 number, and 1 special character
          </mat-error>
          <mat-error
            *ngIf="
              resetPasswordForm
                .get('confirmPassword')
                ?.hasError('passwordMismatch') &&
              resetPasswordForm.get('confirmPassword')?.touched
            "
          >
            Passwords do not match
          </mat-error>
        </mat-form-field>
        <div class="button-container">
          <div class="center-item">
            <app-button
              class="action-button"
              [label]="'Reset Password'"
              [type]="'submit'"
            >
            </app-button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
