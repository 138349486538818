import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStops, ICheckCalls, IDocument } from '@app/models/order.models';
import {
  IOrderHistory,
  IOrderStatusCount,
} from '@app/models/orderHistory.models';
import { CarrierBrandSettings } from '@app/models/carrierBrandSettings.model';
import { Order } from '@app/models/order.models';
import { environment } from '@env/environment';
import { ITrimbleMap } from '@app/models/trimble-map.model';
import { formatDateToString } from '@app/shared/helpers/date.helper';
import { CarrierAPIKeys } from '../models/carrierAPIKeys.model';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private orderQueryParams = '&referenceCodes=SID,SCC,CO,AO,BL%23,BM,PO';

  private orderHistoryParamMappings: any = {
    companyIds: 'companyIds',
    pageSize: 'PageSize',
    page: 'PageNumber',
    startDate: 'StartDate',
    endDate: 'EndDate',
    status: 'IncludeStatuses',
    consigneeState: 'ConsigneeStates',
    pickupState: 'PickupStates',
    orderNumber: 'OrderNumber',
    referenceNumber: 'ReferenceNumber',
    consigneeCity: 'ConsigneeCity',
    consigneeName: 'ConsigneeName',
    pickupCity: 'PickupCity',
    pickupName: 'PickupName',
  };

  constructor(private http: HttpClient) {}

  getMapInfo(orderNumber: number): Observable<ITrimbleMap> {
    return this.http.get<ITrimbleMap>(
      `${environment.apiUrl}/GetMapInfo?orderNumber=${orderNumber}&carrierId=${environment.carrierId}`
    );
  }

  getStops(orderNumber: number): Observable<IStops[]> {
    return this.http.get<IStops[]>(
      `${environment.apiUrl}/GetStops?orderNumber=${orderNumber}&carrierId=${environment.carrierId}`
    );
  }

  getDocuments(orderNumber: number): Observable<IDocument[]> {
    return this.http.get<IDocument[]>(
      `${environment.docApiUrl}/GetDocuments?orderNumber=${orderNumber}&carrierId=${environment.carrierId}`
    );
  }

  getDocument(documentId: number): Observable<Blob> {
    return this.http.get<Blob>(
      `${environment.docApiUrl}/GetDocument?documentId=${documentId}`,
      {
        responseType: 'blob' as 'json',
      }
    );
  }

  getCheckcalls(orderNumber: number): Observable<ICheckCalls[]> {
    return this.http.get<ICheckCalls[]>(
      `${environment.apiUrl}/GetCheckcalls?orderNumber=${orderNumber}`
    );
  }

  getOrderDetailsSummary(orderNumber: number): Observable<Order> {
    return this.http.get<Order>(
      `${environment.apiUrl}/GetOrder?orderNumber=${orderNumber}${this.orderQueryParams}`
    );
  }

  getOrderHistory(
    request: any,
    startDate: Date | null = null,
    endDate: Date | null = null,
    column: string | null = null,
    sortOrder: string | null = null
  ): Observable<IOrderHistory[]> {
    let suffix = '';

    const formattedStartDate = startDate ? formatDateToString(new Date(startDate)) : '';
    const formattedEndDate = endDate ? formatDateToString(new Date(endDate)) : '';

    if (formattedStartDate) {
      suffix += `&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`;
    }

    if (column && sortOrder) {
      suffix += `&SortOrder=${sortOrder.toUpperCase()}&OrderBy=${column}`;
    }

    const url = `${environment.apiUrl}/GetOrderHistory?carrierId=${environment.carrierId}&${this.getQueryParams(this.orderHistoryParamMappings, request)}${suffix}`;
    return this.http.get<IOrderHistory[]>(url);
  }

  getQueryParams(paramMappings: any, request: any): any {
    return Object.keys(paramMappings)
      .filter((key) => request[key])
      .map((key) => `${paramMappings[key]}=${request[key]}`)
      .join('&');
  }

  getOrderStatusCounts(request: any, startDate: Date | null, endDate: Date | null): Observable<IOrderStatusCount> {
    const orderStatusParamMappings = {
      companyIds: 'companyIds',
      carrierId: 'carrierId'
    };
    let suffix = '';

    const formattedStartDate = startDate ? formatDateToString(new Date(startDate)) : '';
    const formattedEndDate = endDate ? formatDateToString(new Date(endDate)) : '';

    if (formattedStartDate) {
      suffix += `&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`;
    }

    return this.http.get<IOrderStatusCount>(
      `${environment.apiUrl}/GetOrderStatusCounts?${this.getQueryParams(orderStatusParamMappings, request)}${suffix}`
    );
  }

  getOrderHistoryCsv(
    request: any,
    startDate: Date | null = null,
    endDate: Date | null = null,
    column: string | null = null,
    sortOrder: string | null = null
  ): Observable<Blob> {
    let suffix = '';

    const formattedStartDate = startDate ? formatDateToString(new Date(startDate)) : '';
    const formattedEndDate = endDate ? formatDateToString(new Date(endDate)) : '';

    if (formattedStartDate) {
      suffix += `&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`;
    }

    if (column && sortOrder) {
      suffix += `&SortOrder=${sortOrder.toUpperCase()}&OrderBy=${column}`;
    }

    const url = `${environment.apiUrl}/GetOrderHistoryCSV?carrierId=${environment.carrierId}&${this.getQueryParams(this.orderHistoryParamMappings, request)}${suffix}`;

    return this.http.get<Blob>(url, { responseType: 'blob' as 'json' });
  }

  getBrandSettings(): Observable<CarrierBrandSettings> {
    const headers = new HttpHeaders({
      [environment.apiTokenHeaderKey]: environment.apiTokenHeaderValue,
    });

    return this.http.get<CarrierBrandSettings>(
      `${environment.appApiUrl}/GetBrandSettings?carrierId=${environment.carrierId}`,
      { headers: headers }
    );
  }

  getCarrierOrderStatuses(): Observable<any> {
    return this.http.get<any>(
      `${environment.appApiUrl}/GetCarrierOrderStatuses?carrierId=${environment.carrierId}`
    );
  }

  getCarrierLateReasonCodes(): Observable<any> {
    return this.http.get<any>(
      `${environment.appApiUrl}/GetCarrierLateReasonCodes?carrierId=${environment.carrierId}`
    );
  }

  upsertCarrierLateReasonCodes(request: any): Observable<string> {
    return this.http.post<string>(
      `${environment.appApiUrl}/UpsertCarrierLateReasonCodes`,
      request,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  upsertCarrierOrderStatuses(request: any): Observable<string> {
    return this.http.post<string>(
      `${environment.appApiUrl}/UpsertCarrierOrderStatuses`,
      request,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  upsertCarrier(
    companyName: string,
    brandColor: string,
    supportEmail: string | undefined
  ): Observable<CarrierBrandSettings> {
    const headers = new HttpHeaders({
      [environment.apiTokenHeaderKey]: environment.apiTokenHeaderValue,
    });

    const body = {
      Id: environment.carrierId,
      Name: companyName,
      BrandColor: brandColor,
      supportEmail: supportEmail,
      LastModifiedBy: 'adminUser',
    };

    return this.http.post<CarrierBrandSettings>(
      `${environment.appApiUrl}/UpsertCarrier`,
      body,
      { headers: headers }
    );
  }

  deleteOrderStatus(StatusCodeId: any): Observable<any> {
    return this.http.delete(`${environment.appApiUrl}/DeleteCarrierOrderStatus?StatusCodeId=${StatusCodeId}`);
  }

  deleteLateReasonCode(LateReasonCodeId: any): Observable<any> {
    return this.http.delete(`${environment.appApiUrl}/DeleteCarrierLateReasonCode?LateReasonCodeId=${LateReasonCodeId}`);
  }

  getCarrierAPIKeys(): Observable<CarrierAPIKeys> {
    return this.http.get<CarrierAPIKeys>(`${environment.appApiUrl}/GetCarrierAPIKeys?carrierId=${environment.carrierId}`);
  }
}
