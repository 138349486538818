<mat-form-field
  class="dropdown"
  [appearance]="appearance"
  *ngIf="searchOptions.length"
>
  <mat-select
    [(value)]="selectedOption"
    (valueChange)="optionValue.emit(selectedOption)"
  >
    <mat-option *ngFor="let option of searchOptions" [value]="option">{{
      option
    }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field class="search-button" [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <input
    #input
    type="text"
    matInput
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    (keydown.enter)="triggerSearch()"
  />
  <mat-icon
    matSuffix
    *ngIf="!value"
    class="search-icon"
    (click)="triggerSearch()"
    >search</mat-icon
  >
  <mat-icon matSuffix *ngIf="value" class="search-icon" (click)="clearSearch()"
    >close</mat-icon
  >
</mat-form-field>
