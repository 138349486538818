import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';

import * as BrandingActions from '@app/components/branding/branding-store/branding.actions';
import { ToasterService } from '@app/services/toaster.service';
import { OrderService } from '@app/services/order.service';
import { ImageService } from '@app/services/image.service';
import { FaviconService } from '@app/services/favicon.service';
import { environment } from '@env/environment';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss'],
})
export class BrandingComponent implements OnInit {
  brandingForm!: FormGroup;
  previewUrl: string | ArrayBuffer | SafeUrl | null = null;
  selectedFile!: File;
  selectedColor: string = '#000000';
  imageUpdated: boolean = false;

  brandColor: string | undefined;
  companyName: string | undefined;
  supportEmail: string | undefined;

  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    private store: Store<{ branding: any }>,
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private orderService: OrderService,
    private imageService: ImageService,
    private sanitizer: DomSanitizer,
    private faviconService: FaviconService,
    private dialog: MatDialog
  ) {
    this.brandingForm = this.fb.group({
      displayName: [
        '',
        [
          Validators.required,
          Validators.maxLength(25),
          Validators.pattern(/^[a-zA-Z0-9 ,':&+;.-]+$/),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.setBrandStoreSubs();
    this.fetchBrandSettings();
    this.fetchBrandImage();
  }

  fetchBrandImage(): void {
    this.imageService
      .downloadImage(environment.carrierId.toString(), 'carrier')
      .subscribe({
        next: (blob) => {
          const objectURL = URL.createObjectURL(blob);
          this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  fetchBrandSettings(): void {
    this.orderService.getBrandSettings().subscribe(
      (data) => {
        this.companyName = data.Name;
        this.brandColor = data.BrandColor;
        this.supportEmail = data.SupportEmail;

        this.brandingForm = new FormGroup({
          displayName: new FormControl(this.companyName || ''),
          brandColor: new FormControl(this.brandColor || ''),
        });
      },
      (error) => {
        console.log('Error fetching brand settings' + error);
      }
    );
  }

  setBrandStoreSubs(): void {
    this.store.select('branding').subscribe((brandingData) => {
      if (brandingData?.displayName) {
        this.brandingForm
          .get('displayName')
          ?.setValue(brandingData.displayName);
      }

      if (brandingData?.previewUrl) {
        this.previewUrl = brandingData.previewUrl;
      }

      if (brandingData?.selectedColor) {
        this.selectedColor = brandingData.selectedColor;
      }
    });
  }

  isOnSaveDisabled(): boolean {
    return !this.brandingForm.valid;
  }

  onSave(): void {
    if (this.brandingForm.valid) {
      const brandingData = {
        displayName: this.brandingForm.get('displayName')?.value,
        previewUrl: this.previewUrl,
        selectedColor: this.selectedColor,
      };
      this.orderService
        .upsertCarrier(
          brandingData.displayName,
          brandingData.selectedColor,
          this.supportEmail
        )
        .subscribe({
          next: (res) => {
            if (this.imageUpdated) {
              this.saveBrandingImageData();
            } else {
              this.toasterService.showSuccess('Changes saved successfully');
            }
          },
          error: (error) => {
            console.log('Error updating brand settings ' + error);
          },
        });

      this.store.dispatch(BrandingActions.saveBranding({ brandingData }));
    }
  }

  saveBrandingImageData(): void {
    //prettier-ignore
    this.imageService
      .uploadImage(
        this.selectedFile,
        this.previewUrl,
        environment.carrierId.toString(),
        'carrier'
      )
      .subscribe({
        next: () => {
          if (this.previewUrl) {
            this.faviconService.changeFavicon(this.previewUrl.toString());
          }
          this.toasterService.showSuccess('Changes saved successfully');
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  handleFile(file: File): void {
    this.selectedFile = file;
    // File type validation
    if (
      !['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'].includes(
        file.type
      )
    ) {
      this.toasterService.showError('File type not allowed');
      return;
    }

    // Image size validation
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width < 100 || img.height < 100 || img.width !== img.height) {
        this.toasterService.showError(
          'The dimensions of the image are not valid'
        );
        return;
      }

      // image read and preview
      const reader = new FileReader();
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.imageUpdated = true;
      };
      reader.readAsDataURL(file);
    };
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      this.handleFile(file);
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      const file = files[0];
      this.handleFile(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDisplayNameChanged(): void {
    this.brandingForm.get('displayName')?.updateValueAndValidity();
  }

  onDeleteImage(): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete the logo?',
      },
      id: 'confirm-dialog',
    });

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const imageType = 'carrier';
        const id = environment.carrierId.toString();

        this.previewUrl = '';
        this.fileInput.nativeElement.value = '';
        this.imageService.deleteImage(id, imageType).subscribe({
          next: () => {
            const brandingData = {
              displayName: this.brandingForm.get('displayName')?.value,
              previewUrl: this.previewUrl,
              selectedColor: this.selectedColor,
            };
            this.store.dispatch(BrandingActions.saveBranding({ brandingData }));
            this.toasterService.showSuccess(
              'The logo was deleted successfully'
            );
          },
          error: (error) => {
            console.log(error);
          },
        });
      }
    });
  }
}
