<div class="title-container">
  <span class="title">Dashboard</span>
  <div class="title-action date-range-action" (click)="openDateRangeModal()">
    <mat-icon>calendar_month</mat-icon>
    <span class="title-action-text">{{selectedDropdownValue}}</span>
    <span class="dropdown-arrow"><mat-icon>keyboard_arrow_down</mat-icon></span>
  </div>
</div>
<div class="cards-container">
  <!-- Available Card -->
  <div class="card" (click)="navigateToOrder('available')">
    <span class="card-title">Available</span>
    <span class="card-amount">
      <ng-container *ngIf="isStatusCountLoading; else statusCountBlockAvailable"
                    [ngTemplateOutlet]="spinnerTemplate"></ng-container>
      <ng-template #statusCountBlockAvailable>
        {{ statusCount.Available ? statusCount.Available : 0 }}
      </ng-template>
    </span>
  </div>
  <!-- Planned Card -->
  <div class="card" (click)="navigateToOrder('planned')">
    <span class="card-title">Planned</span>
    <span class="card-amount">
      <ng-container *ngIf="isStatusCountLoading; else statusCountBlockPlanned"
                    [ngTemplateOutlet]="spinnerTemplate"></ng-container>
      <ng-template #statusCountBlockPlanned>
        {{ statusCount.Planned ? statusCount.Planned : 0 }}
      </ng-template>
    </span>
  </div>
  <!-- In Transit Card -->
  <div class="card" (click)="navigateToOrder('in-transit')">
    <span class="card-title">In Transit</span>
    <span class="card-amount">
      <ng-container *ngIf="isStatusCountLoading; else statusCountBlockInTransit"
                    [ngTemplateOutlet]="spinnerTemplate"></ng-container>
      <ng-template #statusCountBlockInTransit>
        {{ statusCount['In Transit'] ? statusCount['In Transit'] : 0 }}
      </ng-template>
    </span>
  </div>
  <!-- Delivered Card -->
  <div class="card" (click)="navigateToOrder('delivered')">
    <span class="card-title">Delivered</span>
    <span class="card-amount">
      <ng-container *ngIf="isStatusCountLoading; else statusCountBlockDelivered"
                    [ngTemplateOutlet]="spinnerTemplate"></ng-container>
      <ng-template #statusCountBlockDelivered>
        {{ statusCount.Delivered ? statusCount.Delivered : 0 }}
      </ng-template>
    </span>
  </div>
</div>

<div class="title-container">
  <span class="subtitle">Recent Orders</span>
  <a routerLink="/order/history" class="view-all-link">View All</a>
</div>

  <div class="table-container">
    <table mat-table
           [dataSource]="dataSource"
           matSort
           multiTemplateDataRows
           class="orders-table"
           matSortActive="StartDate"
           matSortDirection="desc">
      <!-- Shippers Columns -->
      <ng-container matColumnDef="OrderNumber">
        <th mat-header-cell *matHeaderCellDef>Order #</th>
        <td mat-cell *matCellDef="let element">{{ element.OrderNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <div [ngSwitch]="element.Status">
            <div *ngSwitchCase="'Delivered'" class="flex-center">
              <mat-icon>check_circle</mat-icon> Delivered
            </div>
            <div *ngSwitchCase="'Available'" class="flex-center">
              <mat-icon>inbox</mat-icon> Available
            </div>
            <div *ngSwitchCase="'Planned'" class="flex-center">
              <mat-icon>event_available</mat-icon> Planned
            </div>
            <div *ngSwitchCase="'In Transit'" class="flex-center">
              <mat-icon>directions_bus</mat-icon> In Transit
            </div>
            <div *ngSwitchCase="'Cancelled'" class="flex-center">
              <mat-icon>cancel</mat-icon> Cancelled
            </div>
            <div *ngSwitchDefault class="flex-center">{{ element.Status }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="ReferenceNum">
        <th mat-header-cell *matHeaderCellDef>Reference #</th>
        <td mat-cell *matCellDef="let element">{{ element.ReferenceNum }}</td>
      </ng-container>

      <ng-container matColumnDef="Shipper.Name">
        <th mat-header-cell *matHeaderCellDef>Pickup Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.Shipper.Name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Shipper.City">
        <th mat-header-cell *matHeaderCellDef>Pickup City</th>
        <td mat-cell *matCellDef="let element">
          {{ element.Shipper.City | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Shipper.State">
        <th mat-header-cell *matHeaderCellDef>Pickup State</th>
        <td mat-cell *matCellDef="let element">{{ element.Shipper.State }}</td>
      </ng-container>

      <ng-container matColumnDef="Consignee.Name">
        <th mat-header-cell *matHeaderCellDef>Consignee Name</th>
        <td mat-cell *matCellDef="let element">{{ element.Consignee.Name }}</td>
      </ng-container>

      <ng-container matColumnDef="Consignee.City">
        <th mat-header-cell *matHeaderCellDef>Consignee City</th>
        <td mat-cell *matCellDef="let element">
          {{ element.Consignee.City | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Consignee.State">
        <th mat-header-cell *matHeaderCellDef>Consignee State</th>
        <td mat-cell *matCellDef="let element">{{ element.Consignee.State }}</td>
      </ng-container>

      <ng-container matColumnDef="BillTo.Name">
        <th mat-header-cell *matHeaderCellDef>Bill To</th>
        <td mat-cell *matCellDef="let element">{{ element.BillTo.Name }}</td>
      </ng-container>

      <ng-container matColumnDef="StartDate">
        <th mat-header-cell *matHeaderCellDef>Ship Date</th>
        <td mat-cell *matCellDef="let element">{{ element.StartDate | date }}</td>
      </ng-container>

      <ng-container matColumnDef="CompletionDate">
        <th mat-header-cell *matHeaderCellDef>Delivery Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.CompletionDate | date }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row
          *matRowDef="let row; columns: columnsToDisplay"
          (click)="onRowClick(row)"></tr>
    </table>
    <!-- No records message -->
    <div *ngIf="!isLoading && dataSource.data.length === 0"
         class="no-records-found">
      <p>No records found.</p>
    </div>
    <ng-container *ngIf="isLoading"
                  [ngTemplateOutlet]="spinnerTemplate"></ng-container>
  </div>

  <ng-template #spinnerTemplate>
    <div class="spinner-container">
      <mat-progress-spinner mode="indeterminate" [diameter]="30" class="spinner">
      </mat-progress-spinner>
    </div>
  </ng-template>
