import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

//import { AppConfig } from '../app.config';
import { environment } from '@env/environment';

import { ShipperListElement } from '../models/shipper.model';

@Injectable({
  providedIn: 'root',
})
export class ShipperService {
  private notifyFetchShippersSource = new Subject<void>();
  notifyFetchShippers$ = this.notifyFetchShippersSource.asObservable();

  constructor(private http: HttpClient) {}

  notifyFetchShippers(): void {
    this.notifyFetchShippersSource.next();
  }

  //prettier-ignore
  getShippers(carrierId: number, shipperDetails?: string): Observable<ShipperListElement[]> {
    let params = new HttpParams().set('carrierId', carrierId.toString());

    if (shipperDetails) {
      params = params.set('shipperDetails', shipperDetails);
    }
    return this.http.get<ShipperListElement[]>(
      `${environment.appApiUrl}/GetCarrierShippers`,
      {
        params,
      }
    );
  }

  //prettier-ignore
  getShipperUsers(shipperId: string | number, userDetails?: string): Observable<any> {
    let params = new HttpParams().set('shipperId', shipperId.toString());

    if (userDetails) {
      params = params.set('userDetails', userDetails);
    }
    return this.http.get<any>(`${environment.appApiUrl}/GetShipperUsers`, {
      params,
    });
  }

  upsertShipperUser(request: any): Observable<any> {
    return this.http.post(
      `${environment.appApiUrl}/UpsertShipperUser`,
      request
    );
  }

  deleteShipperUser(
    shipperId: string | number,
    objectId: string
  ): Observable<any> {
    const url = `${environment.appApiUrl}/DeleteShipperUser?shipperId=${shipperId}&objectId=${objectId}`;
    return this.http.delete(url);
  }

  getShipper(shipperId: string | number): Observable<any> {
    return this.http.get<any>(
      `${environment.appApiUrl}/GetShipper?shipperId=${shipperId}`
    );
  }

  createEditShipper(request: any): Observable<any> {
    return this.http.post(
      `${environment.appApiUrl}/UpsertCarrierShipper`,
      request
    );
  }

  deleteCarrierShipper(shipperId: string): Observable<any> {
    const url = `${environment.appApiUrl}/DeleteCarrierShipper?shipperId=${shipperId}`;
    return this.http.delete(url);
  }
}
