import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

import { ToasterService } from '@app/services/toaster.service';
import { AppConfig } from '@app/app.config';
import { OrderService } from '@app/services/order.service';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from '@env/environment';

import { OrderStatus } from '../../models/orderStatus.model';
import { LateReasonCode } from '../../models/lateReasonCode.model';
import { CanComponentDeactivate } from '../../services/guard.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationDialogComponent } from '../../core/components/navigation-dialog/navigation-dialog.component';
import { DeleteDialogComponent } from '../../core/components/delete-dialog/delete-dialog.component';


@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
})

export class AdminSettingsComponent implements OnInit, CanComponentDeactivate {
  adminForm!: FormGroup;
  orderStatusesDisplayedColumns: string[] = ['statusCode', 'active', 'mapping', 'delete'];
  lateReasonCodesDisplayedColumns: string[] = ['lateReasonCode', 'description', 'delete'];
  orderStatusesData: OrderStatus[] = [];
  lateReasonCodesData: LateReasonCode[] = [];
  searchValue: string = '';
  isFormChanged = false;
  brandingData!: any;
  isLoadingCarrierOrderStatuses: boolean = false;
  isLoadingCarrierLateReasonCodes: boolean = false;
  disableButton: boolean = false;
  private isSaved = false;
  private newOrderStatusIndices: number[] = [];
  private newLateReasonCodeIndices: number[] = [];
  private unsavedOrderStatuses: OrderStatus[] = [];
  private unsavedLateReasonCodes: LateReasonCode[] = [];

  constructor(
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private orderService: OrderService,
    private dialog: MatDialog,
  ) {
    this.adminForm = this.fb.group({
      email: ['', [this.emailValidator]],
    });
  }

  ngOnInit(): void {
    this.adminForm.valueChanges.subscribe(() => {
    });
    this.fetchBrandSettings();
    this.fetchCarrierOrderStatuses();
    this.fetchCarrierLateReasonCodes();
  }

  fetchCarrierLateReasonCodes(): void {
    this.isLoadingCarrierLateReasonCodes = true;
    this.orderService.getCarrierLateReasonCodes().subscribe({
      next: (data) => {
        this.isLoadingCarrierLateReasonCodes = false;
        this.lateReasonCodesData = [...data, ...this.unsavedLateReasonCodes];
      },
      error: (error) => {
        this.isLoadingCarrierLateReasonCodes = false;
        console.log(error);
      },
    });
  }

  fetchCarrierOrderStatuses(): void {
    this.isLoadingCarrierOrderStatuses = true;
    this.orderService.getCarrierOrderStatuses().subscribe({
      next: (data) => {
        this.isLoadingCarrierOrderStatuses = false;
        this.orderStatusesData = [...data, ...this.unsavedOrderStatuses];
      },
      error: (error) => {
        this.isLoadingCarrierOrderStatuses = false;
        console.log(error);
      },
    });
  }

  fetchBrandSettings(): void {
    this.orderService.getBrandSettings().subscribe({
      next: (data) => {
        this.brandingData = data;
        if (data.SupportEmail) {
          this.adminForm.get('email')?.setValue(data.SupportEmail);
        }
      },
      error: (error) => {
        console.log('Error fetching brand settings' + error);
      },
    });
  }

  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const emailRegex = AppConfig.emailPattern;
    if (!control.value.match(emailRegex)) {
      return { invalidEmail: true };
    }
    return null;
  }

  onEmailChanged(): void {
    this.adminForm.get('displayName')?.updateValueAndValidity();
  }

  onSearch(): void {
    console.log(this.searchValue);
  }

  supportEmailSave(): void {
    if (this.adminForm.get('email')?.invalid) {
      this.toasterService.showError('Please enter a valid email address.');
      return;
    }

    this.disableButton = true;
    const emailValue = this.adminForm.get('email')?.value;

    this.orderService.upsertCarrier(
      this.brandingData.Name,
      this.brandingData.BrandColor,
      emailValue
    ).subscribe({
      next: () => {
        this.disableButton = false;
        this.toasterService.showSuccess('Support email saved successfully.');
      },
      error: (error) => {
        this.disableButton = false;
        console.error(error);
        this.toasterService.showError('Failed to save the support email. Please try again.');
      }
    });

    this.isSaved = true;
  }

  orderStatusSave(): void {
    if (this.newOrderStatusIndices.some(index => {
      const orderStatus = this.orderStatusesData[index];
      return !orderStatus || !orderStatus.StatusCode.trim();
    })) {
      this.toasterService.showError('Please fill in all new order status rows before saving.');
      return;
    }

    this.disableButton = true;

    const orderStatusesRequest = this.orderService.upsertCarrierOrderStatuses(
      this.getRequestCarrierOrderStatuses()
    );

    orderStatusesRequest.subscribe({
      next: () => {
        this.disableButton = false;
        this.toasterService.showSuccess('Order statuses saved successfully.');
        this.isSaved = true;
        this.newOrderStatusIndices = [];
        this.fetchCarrierOrderStatuses();
      },
      error: (error) => {
        this.disableButton = false;
        console.error(error);
        if (error.status === 409 && error.error) {
          const errorMessage = this.parseErrorMessage(error.error);
          this.toasterService.showError(errorMessage);
        } else {
          console.error(error);
          this.toasterService.showError('Failed to save order statuses. Please try again.');
        }
      },
    });
  }

  lateReasonSave(): void {
    if (this.newLateReasonCodeIndices.some(index => {
      const lateReasonCode = this.lateReasonCodesData[index];
      return !lateReasonCode || !lateReasonCode.LateReasonCode.trim();
    })) {
      this.toasterService.showError('Please fill in all new late reason code rows before saving.');
      return;
    }

    this.disableButton = true;

    const lateReasonCodesRequest = this.orderService.upsertCarrierLateReasonCodes(
      this.getRequestCarrierLateReasonCodes()
    );

    lateReasonCodesRequest.subscribe({
      next: () => {
        this.disableButton = false;
        this.toasterService.showSuccess('Late reason codes saved successfully.');
        this.isSaved = true;
        this.newLateReasonCodeIndices = [];
        this.fetchCarrierLateReasonCodes();
      },
      error: (error) => {
        this.disableButton = false;
        console.error(error);
        if (error.status === 409 && error.error) {
          const errorMessage = this.parseErrorMessage(error.error);
          this.toasterService.showError(errorMessage);
        } else {
          console.error(error);
          this.toasterService.showError('Failed to save late reason codes. Please try again.');
        }
      },
    });
  }

  parseErrorMessage(error: any): string {
    let errorMessage = 'There was a conflict. Please review and try again.';

    try {
      if (typeof error.error === 'string') {
        const parsedError = JSON.parse(error.error);
        errorMessage = parsedError.error || errorMessage;
      } else if (error.error && error.error.error) {
        errorMessage = error.error.error;
      } else if (typeof error === 'string') {
        const parsedError = JSON.parse(error);
        errorMessage = parsedError.error || errorMessage;
      }
    } catch (e) {
      if (typeof error.error === 'string') {
        errorMessage = error.error;
      }
      console.error('Error parsing error message:', e);
    }

    return errorMessage;
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isFormChanged && !this.isSaved) {
      return this.confirmUnsavedChanges();
    }
    return true;
  }

  getRequestCarrierOrderStatuses(): any {
    return {
      Id: environment.carrierId,
      //LastModifiedBy: localStorage.getItem('objectId'),
      LastModifiedBy: environment.carrierId,
      StatusCodeMappings: this.orderStatusesData,
    };
  }

  getRequestCarrierLateReasonCodes(): any {
    return {
      Id: environment.carrierId,
      LastModifiedBy: localStorage.getItem('objectId'),
      LateReasonCodeMappings: this.lateReasonCodesData,
    };
  }

  onElementChanged(): void {
    this.isFormChanged = true;
  }

  addOrderStatus(): void {
    const newRow = { StatusCode: '', Active: false, Mapping: 'Available' };
    this.orderStatusesData = [...this.orderStatusesData, newRow];
    this.newOrderStatusIndices.push(this.orderStatusesData.length - 1);
    this.isFormChanged = true;
  }

  addLateReasonCodes(): void {
    const newRow = { LateReasonCode: '', Description: '' };
    this.lateReasonCodesData = [...this.lateReasonCodesData, newRow];
    this.newLateReasonCodeIndices.push(this.lateReasonCodesData.length - 1);
    this.isFormChanged = true;
  }

  confirmUnsavedChanges(): Observable<boolean> {
    if (this.isFormChanged && !this.isSaved) {
      const navigationDialogRef = this.dialog.open(NavigationDialogComponent, {
        width: '400px',
        data: {
          message: 'Are you sure you want to leave this page? The changes you made will not be saved.'
        }
      });

      return navigationDialogRef.afterClosed();
    } else {
      return of(true);
    }
  }

  onDeleteOrderStatus(orderStatus: any, index: number) {
    this.isFormChanged = false;
    if (!orderStatus.Id) {
      this.orderStatusesData = [
        ...this.orderStatusesData.slice(0, index),
        ...this.orderStatusesData.slice(index + 1)
      ];

      this.newOrderStatusIndices = this.newOrderStatusIndices
        .filter(i => i !== index)
        .map(i => i > index ? i - 1 : i);

      this.toasterService.showSuccess('Order status removed successfully.');
    } else {
      this.isFormChanged = false;
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: '400px',
        data: {
          message: `Are you sure you want to delete the Order Status?`,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Delete'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.orderService.deleteOrderStatus(orderStatus.Id).subscribe({
            next: () => {
              this.orderStatusesData = this.orderStatusesData.filter((item, idx) => idx !== index);
              this.toasterService.showSuccess('Order status deleted successfully.');
            },
            error: error => {
              console.error('There was an error deleting the order status', error);
              this.toasterService.showError('Failed to delete the order status.');
            }
          });
        }
      });
    }
  }

  onDeleteLateReasonCode(lateReasonCode: any, index: number) {
    this.isFormChanged = false;
    if (!lateReasonCode.Id) {
      this.lateReasonCodesData = [
        ...this.lateReasonCodesData.slice(0, index),
        ...this.lateReasonCodesData.slice(index + 1)
      ];

      this.newLateReasonCodeIndices = this.newLateReasonCodeIndices
        .filter(i => i !== index)
        .map(i => i > index ? i - 1 : i);
    } else {
      this.isFormChanged = false;
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
          width: '400px',
          data: {
            message: `Are you sure you want to delete the Late Reason Code?`,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.orderService.deleteLateReasonCode(lateReasonCode.Id).subscribe({
              next: () => {
                this.lateReasonCodesData = this.lateReasonCodesData.filter((item, idx) => idx !== index);
                this.toasterService.showSuccess('Late reason code deleted successfully.');
              },
              error: error => {
                console.error('There was an error deleting the late reason code', error);
                this.toasterService.showError('Failed to delete the late reason code.');
              }
            });
          }
        });
      }
    }
  }
