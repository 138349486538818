<div class="forgot-password-container">
  <mat-card class="forgot-password-card">
    <mat-card-header>
      <mat-card-title>Forgot your password?</mat-card-title>
      <mat-card-subtitle
        >Enter your email address to reset your password.</mat-card-subtitle
      >
    </mat-card-header>
    <mat-card-content>
      <form
        [formGroup]="forgotPasswordForm"
        (ngSubmit)="onSubmit()"
        class="forgot-password-form"
      >
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput type="text" />
          <mat-error
            *ngIf="
              forgotPasswordForm.get('email')?.hasError('required') &&
              (forgotPasswordForm.get('email')?.touched ||
                forgotPasswordForm.invalid)
            "
          >
            Email is required
          </mat-error>
          <mat-error
            *ngIf="
              forgotPasswordForm.get('email')?.hasError('invalidEmail') &&
              !forgotPasswordForm.get('email')?.hasError('required') &&
              forgotPasswordForm.get('email')?.touched
            "
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <div class="button-container">
          <a [routerLink]="'/login'" class="back">Back</a>
          <div class="center-item">
            <app-button
              class="action-button"
              [label]="'Reset Password'"
              [type]="'submit'"
            >
            </app-button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
