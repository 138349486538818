import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { AppConfig } from '../../app.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  hidePassword = true;

  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    const passwordPattern = AppConfig.passwordPattern;

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, this.emailValidator]],
      password: [
        '',
        [Validators.required, Validators.pattern(passwordPattern)],
      ],
    });
  }

  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const emailRegex = AppConfig.emailPattern;
    if (!control.value.match(emailRegex)) {
      return { invalidEmail: true };
    }
    return null;
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      console.log(this.loginForm.value);
      this.router.navigate(['/home']);
    }
  }
}
