import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';

import { SharedService } from '@app/services/shared.service';
import { DisplaySettingsDialogComponent } from '../../display-settings/display-settings-dialog/display-settings-dialog.component';
import { AccountProfileModalComponent } from './account-profile-modal/account-profile-modal.component';
import { Subject, take } from 'rxjs';
import { OrderService } from '@app/services/order.service';
import * as BrandingActions from '@app/components/branding/branding-store/branding.actions';
import * as AccountProfileActions from '@app/components/navigation/header/account-profile-store/account-profile.actions';
import { AuthService } from '@app/services/auth.service';
import { UserService } from '@app/services/user.service';
import { environment } from '@env/environment';
import { ShipperListElement } from '@app/models/shipper.model';
import { ShipperService } from '@app/services/shipper.service';
import { UserProfile } from '@app/models/user.model';
import { PermissionService } from '@app/services/permission.service';
import { ImageService } from '@app/services/image.service';
import { FaviconService } from '@app/services/favicon.service';
import { CarrierAPIKeys } from '../../../models/carrierAPIKeys.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() showHeaderElements: boolean = false;

  photoUrl: string | ArrayBuffer | SafeUrl | null = null;
  logoUrl: string | SafeUrl = '';
  name = 'John Doe';
  showInitials = false;
  initials!: string;
  circleColor!: string;
  headerColor: string = '';
  rgaHeaderColor!: string;
  isIframe = false;
  showDropdown = false;
  private colors = [
    '#EB7181', // red
    '#468547', // green
    '#FFD558', // yellow
    '#3670B2', // blue
  ];
  carrierName: string | undefined;
  shippers: ShipperListElement[] = [];
  companyId: string = '';
  carrierId: number = environment.carrierId;
  userProfile!: UserProfile;
  accountProfileData!: any;
  brandingData!: any;
  private hasFetchedShippers = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private store: Store<{ branding: any; accountProfile: any }>,
    private sharedService: SharedService,
    private msalService: MsalService,
    private orderService: OrderService,
    private authService: AuthService,
    private userService: UserService,
    private shipperService: ShipperService,
    private permissionService: PermissionService,
    private imageService: ImageService,
    private sanitizer: DomSanitizer,
    private faviconService: FaviconService
  ) {}

  ngOnInit(): void {
    this.setBrandingStoreData();
    this.getB2cObjectId();
    this.getBrandSettings();
    this.getAPIKeys();
  }

  fetchUserImage(objectId: string): void {
    this.imageService.downloadImage(objectId, 'user').subscribe({
      next: (blob) => {
        const objectURL = URL.createObjectURL(blob);
        this.photoUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.updateAccountProfilePreviewUrl();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  private setInitialShipperSelection(): void {
    const savedCompanyId = localStorage.getItem('selectedShipperBillTos');
    if (savedCompanyId) {
      this.companyId = savedCompanyId;
    } else {
      this.companyId = this.shippers.sort((a, b) =>
        a.Name.localeCompare(b.Name)
      )[0].BillTos;
    }
  }

  fetchShippers(): void {
    if (!this.hasFetchedShippers) {
      this.hasFetchedShippers = true;
      this.shipperService.getShippers(this.carrierId).subscribe({
        next: (response) => {
          this.shippers = response.sort((a, b) => a.Name.localeCompare(b.Name));
          let billTos = this.findBillTosByShipperId(
            this.accountProfileData?.shipperId
          );
          if (!billTos && this.userProfile.ShipperId) {
            billTos = this.findBillTosByShipperId(this.userProfile.ShipperId);
          }
          if (billTos) {
            this.companyId = billTos;
          } else {
            this.setInitialShipperSelection();
          }
          if (this.companyId !== '') {
            this.sharedService.initializeCompanyId(this.companyId);
          }
          this.onShipperChange();
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  private findBillTosByShipperId(shipperId: number): string | null {
    const shipper = this.shippers.find((s) => s.Id === shipperId);
    return shipper ? shipper.BillTos : null;
  }

  setInitialData(): void {
    this.isIframe = window !== window.parent && !window.opener;
    if (!this.photoUrl) {
      this.showInitials = true;
      this.createInitials();

      const randomIndex = Math.floor(
        Math.random() * Math.floor(this.colors.length)
      );
      this.circleColor = this.colors[randomIndex];
    }
    this.setBrandingStoreData();
    this.setAccountProfileStoreData();
  }

  getBrandSettings(): void {
    this.orderService.getBrandSettings().subscribe({
      next: (data) => {
        this.brandingData = {
          displayName: this.brandingData?.displayName || data.Name,
          selectedColor: this.brandingData?.selectedColor || data.BrandColor,
          previewUrl: this.brandingData?.previewUrl,
        };
        this.setBrandingInStore();
      },
      error: (error) => {
        console.log('Error fetching brand settings' + error);
      },
    });
  }

  getAPIKeys(): void {
    this.orderService.getCarrierAPIKeys().subscribe({
      next: (data: CarrierAPIKeys) => {
        this.addAPIKeysToLocalStorage(data);
      },
      error: (error) => {
        console.log('Error fetching API Keys' + error);
      }
    });
  }

  addAPIKeysToLocalStorage(keys: CarrierAPIKeys) {
    Object.keys(keys).forEach((key) => {
      const apiKey = key as keyof CarrierAPIKeys;
      localStorage.setItem(apiKey, keys[apiKey]);
    });
  }

  removeFromLocalStorage(keys: CarrierAPIKeys): void {
    Object.keys(keys).forEach((key) => {
      const apiKey = key as keyof CarrierAPIKeys;
      localStorage.removeItem(apiKey);
    });
  }

  removeAPIKeysFromLocalStorage(): void {
    this.orderService.getCarrierAPIKeys().subscribe({
      next: (data: CarrierAPIKeys) => {
        this.removeFromLocalStorage(data);
      },
      error: (error) => {
        console.log('Error fetching API Keys' + error);
      }
    });
  }

  fetchBrandImage(): void {
    this.imageService
      .downloadImage(environment.carrierId.toString(), 'carrier')
      .subscribe({
        next: (blob) => {
          const objectURL = URL.createObjectURL(blob);
          this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.faviconService.changeFavicon(objectURL);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  getB2cObjectId(): void {
    this.authService.getB2cObjectId().subscribe({
      next: (objectId) => {
        if (objectId) {
          this.loadUserPermissionsAndProfile(objectId);
          this.fetchUserImage(objectId);
        }
      },
      error: (error) => console.log(error),
    });
  }

  loadUserPermissionsAndProfile(objectId: string): void {
    this.permissionService.loadPermissions(objectId).subscribe({
      next: (permissions) => {
        console.log('Permissions loaded', permissions);
        this.getUserProfile(objectId);
      },
      error: (error) => console.log('Error loading permissions:', error),
    });
  }

  getUserProfile(objectId: string): void {
    this.userService.getUserProfile(objectId).subscribe({
      next: (res) => {
        this.userProfile = res;
        this.setUserProfileInStore();
        this.setInitialData();

        this.fetchBrandImage();
      },
      error: (error) => console.log(error),
    });
  }

  setBrandingInStore(): void {
    //prettier-ignore
    this.store.select((state) => state.branding).pipe(take(1)).subscribe((currentBranding) => {
        const previewUrl = currentBranding.previewUrl;

        this.brandingData = {
          displayName: this.brandingData.displayName || currentBranding.displayName,
          selectedColor: this.brandingData.selectedColor || currentBranding.selectedColor,
          previewUrl: this.brandingData.previewUrl || currentBranding.previewUrl,
        };

        this.store.dispatch(
          BrandingActions.saveBranding({ brandingData: this.brandingData })
        );
      });
  }

  updateAccountProfilePreviewUrl(): void {
    this.accountProfileData = {
      ...this.accountProfileData,
      previewUrl: this.photoUrl,
    };

    this.store.dispatch(
      AccountProfileActions.saveAccountProfile({
        accountProfileData: this.accountProfileData,
      })
    );
  }

  setUserProfileInStore(): void {
    //prettier-ignore
    this.store.select((state) => state.accountProfile).pipe(take(1)).subscribe((currentProfile) => {
        const previewUrl = currentProfile.previewUrl;

        this.accountProfileData = {
          firstName: this.userProfile.FirstName || currentProfile.firstName,
          lastName: this.userProfile.LastName || currentProfile.lastName,
          email: this.userProfile.Email || currentProfile.email,
          previewUrl: previewUrl,
          shipperId: this.userProfile.ShipperId,
        };
        this.updateAccountProfilePreviewUrl();
        this.fetchShippers();
      });
  }

  logout() {
    this.msalService.logout();
    localStorage.removeItem('objectId');
    localStorage.removeItem('permissions');
    localStorage.removeItem('selectedShipperBillTos');
    sessionStorage.removeItem('orderHistoryDateFilters');
    sessionStorage.removeItem('orderHistoryColumnSort');
    this.removeAPIKeysFromLocalStorage();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setBrandingStoreData(): void {
    this.store.select('branding').subscribe((brandingData) => {
      if (brandingData?.displayName) {
        this.carrierName = brandingData.displayName;
      }
      if (brandingData.previewUrl !== null) {
        this.logoUrl = brandingData.previewUrl;
      }
      if (brandingData?.selectedColor) {
        this.headerColor = brandingData.selectedColor;
        this.rgaHeaderColor = this.lightenHexColor(this.headerColor, 10);
      }
    });
  }

  setAccountProfileStoreData(): void {
    this.store.select('accountProfile').subscribe((accountProfileData) => {
      if (accountProfileData?.firstName && accountProfileData?.lastName) {
        this.name = `${accountProfileData.firstName} ${accountProfileData.lastName}`;
      }

      if (accountProfileData?.previewUrl) {
        this.photoUrl = accountProfileData.previewUrl;
      } else {
        this.photoUrl = '';
      }
      this.createInitials();
    });
  }

  lightenHexColor(hex: string, percent: number): string {
    hex = hex.replace('#', '');
    const rgb = [
      parseInt(hex.slice(0, 2), 16),
      parseInt(hex.slice(2, 4), 16),
      parseInt(hex.slice(4, 6), 16),
    ];
    const newRgb = rgb.map((color) => {
      const lighterColor = Math.round(color + ((255 - color) * percent) / 100);
      const validColor = Math.min(255, Math.max(0, lighterColor));
      const hexValue = validColor.toString(16);
      return hexValue.length === 1 ? '0' + hexValue : hexValue;
    });
    return '#' + newRgb.join('');
  }

  private createInitials(): void {
    this.initials = this.name
      .split(' ')
      .map((word) =>
        word.charAt(0).toUpperCase() === word.charAt(0) ? word.charAt(0) : ''
      )
      .join('')
      .slice(0, 2);
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(DisplaySettingsDialogComponent, dialogConfig);
  }

  openAccountProfileModal(): void {
    this.dialog.open(AccountProfileModalComponent, {
      width: '660px',
      data: this.userProfile,
      autoFocus: '.first-name-input',
      id: 'account-profile',
    });
  }

  onShipperChange(): void {
    const shipperFound = this.shippers.find(
      (shipper) => shipper.BillTos === this.companyId
    );
    if (shipperFound) {
      this.sharedService.setShipperId(shipperFound);
      this.setCompanyIds();
    }
  }

  setCompanyIds(): void {
    this.sharedService.setCompanyId(this.companyId.replace(/\s+/g, ''));
    localStorage.setItem('selectedShipperBillTos', this.companyId);
  }

  canWriteShipperOrganization(): boolean {
    return this.permissionService.hasPermission(
      'WRITE',
      'Shipper Organization'
    );
  }
}
