import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { UserPermission } from '../models/userPermission';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private permissions: UserPermission[] = [];

  constructor(private http: HttpClient) {}

  // Load permissions from the backend
  loadPermissions(objectId: string): Observable<UserPermission[]> {
    const storedPermissions = localStorage.getItem('permissions');
    if (storedPermissions) {
      this.permissions = JSON.parse(storedPermissions) as UserPermission[];
      return of(this.permissions);
    }
    return this.http
      .get<UserPermission[]>(
        `${environment.appApiUrl}/GetUserPermissions?objectId=${objectId}`
      )
      .pipe(
        tap((permissions) => {
          this.permissions = permissions;
          localStorage.setItem('permissions', JSON.stringify(this.permissions));
        })
      );
  }

  // Check if the user has a specific permission
  hasPermission(permissionType: string, resource: string): boolean {
    return this.permissions.some(
      (p) => p.PermissionType === permissionType && p.Resource === resource
    );
  }
}
