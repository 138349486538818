<div class="table-header">
  <div class="title-container">
    <span class="title">Admin Settings</span>
  </div>  
</div>
<div class="email-container">
  <div class="info-container">
    <div class ="container-title-block"> 
      <h5 class="container-title">Support Email</h5>
      <div>
        <ng-container *ngTemplateOutlet="supportSaveButton"></ng-container>
      </div>
    </div>
    <p class="info-text">
      Enter the support email address that should be displayed on the portal.
    </p>
    <form class="admin-form" [formGroup]="adminForm">
      <mat-dialog-content class="form-container">
        <!-- Email -->
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Email</mat-label>
          <input class="email-input"
                 matInput
                 type="text"
                 formControlName="email"
                 (ngModelChange)="onEmailChanged()" />
        </mat-form-field>
        <mat-error *ngIf="
            adminForm.get('email')?.hasError('invalidEmail') &&
            adminForm.get('email')?.touched
          ">
          Please enter a valid email
        </mat-error>
      </mat-dialog-content>
    </form>
  </div>
</div>

<div class="order-statuses-container">
  <div class="info-container">
    <div class="container-title-block-sticky">
      <h5 class="container-title">Order Statuses</h5>
      <div>
        <ng-container *ngTemplateOutlet="orderStatusSaveButton"></ng-container>
      </div>
    </div>
    <p class="info-text">
      Select the order statuses which should be displayed in the portal.
    </p>
    <table mat-table [dataSource]="orderStatusesData" class="status-table">

      <!-- Status Code Column -->
      <ng-container matColumnDef="statusCode">
        <th mat-header-cell *matHeaderCellDef>Status Code</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field class="input-field" appearance="outline">
            <input matInput
                   placeholder="Enter status code"
                   [(ngModel)]="element.StatusCode"
                   (ngModelChange)="onElementChanged()" />
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle (change)="onElementChanged()"
                            [(ngModel)]="element.Active"></mat-slide-toggle>
        </td>
      </ng-container>

      <!-- Mapping Column -->
      <ng-container matColumnDef="mapping">
        <th mat-header-cell *matHeaderCellDef>Mapping</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field class="select-field" appearance="outline">
            <mat-select [(value)]="element.Mapping"
                        (selectionChange)="onElementChanged()"
                        [disabled]="!element.Active">
              <mat-option value="Available">Available</mat-option>
              <mat-option value="Planned">Planned</mat-option>
              <mat-option value="In Transit">In Transit</mat-option>
              <mat-option value="Delivered">Delivered</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!--Delete Column-->
      <ng-container matColumnDef="delete" class="delete-column">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element; let i = index" class="order-status-delete-column">
          <div class="delete-action" (click)="onDeleteOrderStatus(element, i)">
            <span class="material-symbols-outlined delete-button menu-icon">
              delete
            </span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="orderStatusesDisplayedColumns"></tr>
      <tr mat-row
          *matRowDef="let row; columns: orderStatusesDisplayedColumns"></tr>
    </table>
    <div class="status-action-buttons">
      <app-button [label]="'New Status Code'"
                  [icon]="'add_circle'"
                  (buttonClick)="addOrderStatus()"
                  [isDisabled]="false">
      </app-button>
    </div>

    <!-- Spinner -->
    <div *ngIf="isLoadingCarrierOrderStatuses" class="spinner-container">
      <mat-progress-spinner mode="indeterminate"
                            [diameter]="30"
                            class="spinner"></mat-progress-spinner>
    </div>
  </div>
</div>

<div class="late-reason-codes-container">
  <div class="info-container">
    <div class="container-title-block-sticky">
      <h5 class="container-title">Late Reason Codes</h5>
      <div>
        <ng-container *ngTemplateOutlet="lateReasonSaveButton"></ng-container>
      </div>
    </div>
      <p class="info-text">
        For each of the late reason codes, provide a customer friendly description.
      </p>
      <!--<div class="search-container">
      <form (submit)="onSearch()">
        <app-search-input
          label="Search Late Reason Codes..."
          [(ngModel)]="searchValue"
          appearance="outline"
          name="searchValue"
        ></app-search-input>
      </form>
    </div>-->
      <table mat-table [dataSource]="lateReasonCodesData" class="status-table">
        <!-- Late Reason Code Column -->
        <ng-container matColumnDef="lateReasonCode">
          <th mat-header-cell *matHeaderCellDef>Late Reason Code</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="input-field" appearance="outline">
              <input matInput
                     placeholder="Enter late reason code"
                     [(ngModel)]="element.LateReasonCode"
                     (ngModelChange)="onElementChanged()" />
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="input-field" appearance="outline">
              <input matInput
                     (input)="onElementChanged()"
                     type="text"
                     [(ngModel)]="element.Description" />
            </mat-form-field>
          </td>
        </ng-container>

        <!--Delete Row-->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="delete-action" (click)="onDeleteLateReasonCode(element, i)">
              <span class="material-symbols-outlined delete-button menu-icon">
                delete
              </span>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="lateReasonCodesDisplayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: lateReasonCodesDisplayedColumns"></tr>
      </table>
      <div class="reason-codes-action-buttons">
        <app-button [label]="'New Late Reason Code'"
                    [icon]="'add_circle'"
                    (buttonClick)="addLateReasonCodes()"
                    [isDisabled]="false">
        </app-button>
      </div>

      <!-- Spinner -->
      <div *ngIf="isLoadingCarrierLateReasonCodes" class="spinner-container">
        <mat-progress-spinner mode="indeterminate"
                              [diameter]="30"
                              class="spinner"></mat-progress-spinner>
      </div>
    </div>
  </div>

<ng-template #supportSaveButton>
  <app-button mat-button
              (click)="supportEmailSave()"
              [label]="'Save Changes'"
              [icon]="'check_circle_outline'"
              [isDisabled]="!adminForm.get('email')?.valid || disableButton"></app-button>
</ng-template>

<ng-template #orderStatusSaveButton>
  <app-button mat-button
              (click)="orderStatusSave()"
              [label]="'Save Changes'"
              [icon]="'check_circle_outline'"
              [isDisabled]="!adminForm.get('email')?.valid || disableButton"></app-button>
</ng-template>

<ng-template #lateReasonSaveButton>
  <app-button mat-button
              (click)="lateReasonSave()"
              [label]="'Save Changes'"
              [icon]="'check_circle_outline'"
              [isDisabled]="!adminForm.get('email')?.valid || disableButton"></app-button>
</ng-template>
