import { createReducer, on } from '@ngrx/store';
import * as SidenavConfigActions from './sidenav.actions';

export const initialState = {
  isExpanded: true,
};

const _sidenavConfigReducer = createReducer(
  initialState,
  on(
    SidenavConfigActions.saveSidenavConfig,
    (state, { sidenavConfigData }) => ({
      ...state,
      ...sidenavConfigData,
    })
  ),
  on(SidenavConfigActions.loadSidenavConfig, (state) => state)
);

export function sidenavConfigReducer(state: any, action: any) {
  return _sidenavConfigReducer(state, action);
}
