import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { UserElement } from '@app/models/user.model';
import { NewUserModalComponent } from '@app/components/shippers/new-user-modal/new-user-modal.component';
import { ToasterService } from '@app/services/toaster.service';
import {
  ShipperListElement,
  UsersShipperListElement,
} from '@app/models/shipper.model';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '@app/core/components/confirm-dialog/confirm-dialog.component';
import { ShipperService } from '@app/services/shipper.service';

@Component({
  selector: 'app-shipper-users-list',
  templateUrl: './shipper-users-list.component.html',
  styleUrls: ['./shipper-users-list.component.scss'],
})
export class ShipperUsersListComponent implements OnInit, AfterViewInit {
  @Input() shipper!: ShipperListElement;
  @ViewChild(MatSort) sort!: MatSort;

  columnsToDisplaySubTable: string[] = ['FirstName', 'Email', 'UserType'];
  columnsToDisplaySubTableWithMenu: string[] = [
    ...this.columnsToDisplaySubTable,
    'menuSubTable',
  ];
  users: UsersShipperListElement[] = [];
  dataSourceSubTable = new MatTableDataSource(this.users);
  isLoading: boolean = false;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private dialog: MatDialog,
    private toasterService: ToasterService,
    private shipperService: ShipperService
  ) {}

  ngOnInit(): void {
    this.fetchShipperUsers();
  }

  ngAfterViewInit() {
    this.dataSourceSubTable.sort = this.sort;
  }

  fetchShipperUsers(): void {
    if (this.shipper.Id) {
      this.dataSourceSubTable.data = this.shipper.Users!;
    }
  }

  announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  onRowClick(id?: number): void {
    if (id) {
      this.router.navigate([`/shippers/detail/${id}`]);
    }
  }

  getRoleUserToChange(role: string): string {
    if (role === 'shipper user') {
      return 'User';
    }
    return 'Admin';
  }

  openUserModal(editMode: boolean = false, user?: UserElement): void {
    const dialogRef = this.dialog.open(NewUserModalComponent, {
      width: '660px',
      data: {
        editMode,
        ...user,
        shipper: this.shipper ? this.shipper : null,
      },
      autoFocus: '.company-name-input',
      id: 'new-user',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toasterService.showSuccess(`The User was edited successfully`);
      } else if (result === null) {
        this.onDeleteUser(user);
      }
    });
  }

  onDeleteUser(user?: any): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete the User?',
      },
      id: 'confirm-dialog',
    });

    confirmDialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed && this.shipper.Id) {
        this.shipperService
          .deleteShipperUser(this.shipper.Id, user.ObjectId)
          .subscribe({
            next: () => {
              this.toasterService.showSuccess(
                'The User was deleted successfully'
              );
              this.shipperService.notifyFetchShippers();
            },
            error: (error) => {
              console.log(error);
            },
          });
      }
    });
  }

  changeRole(user: any): void {
    const request: any = {
      FirstName: user.FirstName,
      LastName: user.LastName,
      Email: user.Email,
      UserType:
        user.UserType === 'shipper user' ? 'shipper admin' : 'shipper user',
      ShipperId: this.shipper.Id,
      ObjectId: user.ObjectId,
      PrincipalName: user.PrincipalName,
    };
    this.shipperService.upsertShipperUser(request).subscribe({
      next: () => {
        this.toasterService.showSuccess('The Role was changed successfully');
        this.shipperService.notifyFetchShippers();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}
